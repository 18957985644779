import { Injectable } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  /**
   *
   *
   * @type {Subject<string>}
   * @memberof UtilsService
   */
  activeRoute: Subject<string> = new Subject();

  /**
   *
   *
   * @type {Subject<boolean>}
   * @memberof UtilsService
   */
  closeTopBanner$: Subject<boolean> = new Subject();

  constructor() { }

  /**
   *
   *
   * @param {FormGroup} form
   * @return {*}  {{ key: string, msg: string }[]}
   * @memberof AppUtilsService
   */
  validateForm(form: FormGroup): { key: string, msg: string }[] {
    const errors: { key: string; msg: any; }[] = [];
    Object.keys(form.controls).forEach(formField => {
      const formControlErrors: ValidationErrors = form?.get(formField)?.errors as ValidationErrors;
      if (formControlErrors != null) {
        errors.push({
          key: formField,
          msg: formControlErrors
        });
        const control = form.get(formField);
        control?.markAsTouched({ onlySelf: true });
      }
    });
    return errors;
  }


  /**
   *
   *
   * @param {File} file
   * @return {*}
   * @memberof AppUtilsService
   */
  fileToBase64(file: File) {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  }

  /**
   *
   *
   * @param {*} base64Str
   * @return {*}
   * @memberof AppUtilsService
   */
  async base64ToFile(base64Str: RequestInfo | URL, uuid: string) {
    const res: Response = await fetch(base64Str);
    const blob: Blob = await res.blob();
    return new File([blob], uuid, { type: blob.type });
  }


  /**
   *
   *
   * @param {*} element
   * @return {*} 
   * @memberof UtilsService
   */
  isObject(element: any) {
    let isObject = false;
    if (typeof element === 'string') {
      isObject = false;
    } else if (typeof element === 'object' && element !== null) {
      isObject = true;
    } else {
      isObject = false;
    }
    return { isObject, type: typeof element }
  }

  /**
   * @private
   * @param {{ [x: string]: any; }} obj1
   * @param {{ [x: string]: any; }} obj2
   * @return {*}
   * @memberof DashboardComponent
   */
  areObjectsIdentical(
    obj1: { [x: string]: any },
    obj2: { [x: string]: any }
  ) {
    // Check if they are the same type
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') return false;

    // Get the keys
    var keys1 = Object.keys(obj1);
    var keys2 = Object.keys(obj2);

    // If number of properties is different,
    // objects are not equivalent
    if (keys1.length !== keys2.length) return false;

    for (var key of keys1) {
      // If values of same property are not equal,
      // objects are not equivalent
      if (obj1[key] !== obj2[key]) return false;
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
  }


}
