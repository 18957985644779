import { Component } from '@angular/core';
import { Router } from '@angular/router';
import mixpanel from 'mixpanel-browser';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent {

  isYearly = true;

  constructor(
    private router: Router
  ) { }


  async buyFimaPro() {
    if (!this.isYearly) {
      window.location.href = 'https://buy.stripe.com/fZe14LfhR2lG6NWfZ1'
      mixpanel.track('Click purchase pro', {
        // TODO: add other fields as metadata
      })
      return
    }
    window.location.href = 'https://buy.stripe.com/eVa9Bhb1B3pKdck002'
    mixpanel.track('Click purchase pro (yearly)', {
      // TODO: add other fields as metadata
    })
  }

  async buyFimaBusiness() {
    window.location.href = 'https://calendar.app.google/71jCjXihbLhnwYB29'
    mixpanel.track('Click purchase business', {
      // TODO: add other fields as metadata
    })
  }

}



