import { Injectable } from '@angular/core';
import firebase from 'firebase/compat';
import { BehaviorSubject } from 'rxjs';
import { User } from 'src/app/@core/models/@fima/user.model';
import { Comment } from '../../../models/@fima/comment.model';
import { Company } from '../../../models/@fima/company.model';
import { Project } from '../../../models/@fima/project.model';
import { ArtifactPropTree } from '../../../models/@fima/prop.model';
import { TaigaProject } from 'src/app/@core/models/@taiga/project.model';
import { TaigaUserStory } from 'src/app/@core/models/@taiga/user-story.model';

@Injectable({
  providedIn: 'root'
})
export class DataTransferService {

  $user = new BehaviorSubject<User>(null)

  $firebaseUser = new BehaviorSubject<firebase.User>(null)

  $artifactWindowSidePaneState = new BehaviorSubject<string>('open')

  $jsonData = new BehaviorSubject<ArtifactPropTree | null>(null)

  $company = new BehaviorSubject<Company | null>(null)

  $projects = new BehaviorSubject<Project[]>([])

  $comments = new BehaviorSubject<Comment[]>([])

  $onfetchAppData = new BehaviorSubject<boolean>(true)

  $toolBarInViewPort = new BehaviorSubject<boolean>(true)

  $closeArtefactWindow = new BehaviorSubject<boolean>(false)

  $presetAiPrompt = new BehaviorSubject<string>(null)

  // Taiga
  $taigaProjects = new BehaviorSubject< TaigaProject[]>([])
  
  $taigaUserStories = new BehaviorSubject< TaigaUserStory[]>([])


  constructor() { }
}
