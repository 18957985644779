import { Component, OnInit } from '@angular/core';
import { NbTrigger } from '@nebular/theme';
import { Artifact } from 'src/app/@core/models/@fima/artifact.model';
import { Project } from 'src/app/@core/models/@fima/project.model';
import { CacheService } from 'src/app/@core/services/@fima/cache/cache.service';
import { DataTransferService } from 'src/app/@core/services/@fima/data-transfer/data-transfer.service';
import { projectArtifactOptions } from 'src/app/@core/services/@fima/project/projectItemOptions';

@Component({
  selector: 'app-side-menu-content',
  templateUrl: './side-menu-content.component.html',
  styleUrls: ['./side-menu-content.component.scss'],
})
export class SideMenuContentComponent implements OnInit {

  projectArtifacts: Artifact[] = projectArtifactOptions

  nbTrigger = NbTrigger;

  projects: Project[] = [];

  constructor(
    private cacheService: CacheService,
    public  dataTransferService: DataTransferService
  ) {
  }

  ngOnInit(): void { }

}
