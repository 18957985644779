import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Comment } from 'src/app/@core/models/@fima/comment.model';
import { CommentService } from 'src/app/@core/services/@fima/comment/comment.service';

@Component({
  selector: 'dp-comment-view',
  templateUrl: './comment-view.component.html',
  styleUrls: ['./comment-view.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class CommentViewComponent {
  @Input() comment!: Comment;

  @Input() commentIndex!: number;

  @Input() comments: Comment[] = [];

  @Output() onUpdateComment = new EventEmitter<{
    comment: Comment;
    comments: Comment[];
  }>();

  @Output() onDeleteComment = new EventEmitter<{
    deletedCommentId: string;
    comments: Comment[];
  }>();

  @Output() onAddEmoji = new EventEmitter<{ comments: Comment[] }>();

  openEmojiPickerIndex: number | null = null; // add this line to keep track of the comment index

  emojiPickerOpen = false;

  constructor(private commentService: CommentService) {}

  /**
   *
   *
   * @memberof CommentViewComponent
   */
  ngOnChanges() {
    console.log('CommentViewComponent >> comments >> changes', this.comments);
  }

  /**
   *
   *
   * @param {string} commentId
   * @param {string} updatedText
   * @memberof SidePanelComponent
   */
  async updateComment(commentId: string, updatedText: string) {
    const updatedComment: Partial<Comment> = {
      commentText: updatedText,
      updatedAt: new Date(),
    };

    let updatedCommentResult;

    try {
      updatedCommentResult = await this.commentService.updateComment(
        updatedComment,
        commentId
      );
      const commentIndex = this.comments.findIndex(
        (comment) => comment.id === commentId
      );
      if (commentIndex !== -1) {
        this.comments[commentIndex] = {
          ...this.comments[commentIndex],
          ...updatedComment,
        };
      }
    } catch (error) {
      console.error('Error updating comment:', error);
    }

    // Emit the updated comments and comment

    this.onUpdateComment.emit({
      comment: updatedCommentResult as Comment,
      comments: this.comments,
    });
  }

  /**
   *
   *
   * @param {string} commentId
   * @memberof SidePanelComponent
   */
  async deleteComment(commentId: string) {
    try {
      await this.commentService.deleteComment(commentId);
      this.comments = this.comments.filter(
        (comment) => comment.id !== commentId
      );
    } catch (error) {
      console.error('Error deleting comment:', error);
    }

    this.onDeleteComment.emit({
      deletedCommentId: commentId as string,
      comments: this.comments,
    });
  }

  /**
   *
   *
   * @param {*} event
   * @param {(string | any)} commentId
   * @memberof SidePanelComponent
   */
  async addEmoji(
    event: any,
    commentId: string | any,
    emoJiWindowIndex: number
  ) {
    const emoji = event.emoji.native;
    try {
      const commentIndex = this.comments.findIndex(
        (comment) => comment.id === commentId
      );
      if (commentIndex !== -1) {
        this.comments[commentIndex].insertedEmoji.push({
          emoji,
          reactions: 1,
          reactors: [],
        });
        // Optionally, you might want to update the comment in the database
        await this.commentService.updateComment(
          this.comments[commentIndex],
          commentId
        );
      }
    } catch (error) {
      console.error('Error adding emoji:', error);
    }

    console.log(this.comments);

    this.onAddEmoji.emit({
      comments: this.comments,
    });

    this.toggleEmojiPicker(emoJiWindowIndex);
  }

  /**
   *
   *
   * @param {number} index
   * @memberof SidePanelComponent
   */
  toggleEmojiPicker(index: number) {
    if (this.openEmojiPickerIndex === index) {
      // if the clicked comment's emoji picker is already open, close it
      this.openEmojiPickerIndex = null;
    } else {
      // otherwise, open the clicked comment's emoji picker and close others
      this.openEmojiPickerIndex = index;
    }
  }
}
