import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { CreateUserStory, TaigaUserStory, UserStoryStatuses } from 'src/app/@core/models/@taiga/user-story.model';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class TaigaUserStoryService {

    constructor(private http: HttpClient) { }


    /**
     *
     *
     * @param {number} projectId
     * @return {*}  {Promise<UserStoryStatuses[]>}
     * @memberof TaigaUserStoryService
     */
    getUserStoryStatuses(projectId: number): Promise<UserStoryStatuses[]> {
        return new Promise((resolve, reject) => {
            this.http.get<UserStoryStatuses[]>(`${environment.taigaApiUrl}/userstory-statuses?project=${projectId}`)
                .pipe(map(async (userStoryStatuses) => {
                    return resolve(userStoryStatuses)
                })).subscribe({
                    next: (v) => { },
                    error: (e) => {
                        return reject(e)
                    },
                    complete: () => { }
                })
        });
    }

    /**
     *
     *
     * @param {number} projectId
     * @param {*} userStoryId
     * @return {*}  {Promise<UserStoryStatuses[]>}
     * @memberof TaigaUserStoryService
     */
    getUserStoryTasks(projectId: number, userStoryId): Promise<UserStoryStatuses[]> {
        return new Promise((resolve, reject) => {
            this.http.get<UserStoryStatuses[]>(`${environment.taigaApiUrl}/tasks?project=${projectId}&user_story=${userStoryId}`)
                .pipe(map(async (userStoryTasks) => {
                    return resolve(userStoryTasks)
                })).subscribe({
                    next: (v) => { },
                    error: (e) => {
                        return reject(e)
                    },
                    complete: () => { }
                })
        });
    }


    createUserStory(taigaUserStory: CreateUserStory): Promise<TaigaUserStory> {
        return new Promise(async (resolve, reject) => {
            this.http.post<TaigaUserStory>(
                `${environment.taigaApiUrl}/userstories`,
                taigaUserStory
            ).pipe(map(async (userstory) => {
                return resolve(userstory)
            })).subscribe({
                next: (v) => { },
                error: (e) => {
                    return reject(e)
                },
                complete: () => { }
            })

        })
    }

    getUserStoriesByProject(projectId: number, maxUserStories?: number): Promise<TaigaUserStory[]> {
        return new Promise((resolve, reject) => {
            this.http.get<TaigaUserStory[]>(`${environment.taigaApiUrl}/userstories`, {
                params: {
                    ref: projectId,
                    project: projectId
                }
            }).pipe(map(async (userstories) => {
                return resolve(userstories)
            })).subscribe({
                next: (v) => { },
                error: (e) => {
                    return reject(e)
                },
                complete: () => { }
            })
        });
    }

    getUserStoryById(userStoryId: number): Promise<TaigaUserStory> {
        return new Promise(async (resolve, reject) => {
            this.http.get<TaigaUserStory>(
                `${environment.taigaApiUrl}/userstories/${userStoryId}`
            ).pipe(map(async (userstory) => {
                return resolve(userstory)
            })).subscribe({
                next: (v) => { },
                error: (e) => {
                    return reject(e)
                },
                complete: () => { }
            })
        })
    }

    updateUserStory(userStoryId: number, taigaUserStory: TaigaUserStory): Promise<TaigaUserStory> {
        return new Promise<TaigaUserStory>(async (resolve, reject) => {
            this.http.put<TaigaUserStory>(
                `${environment.taigaApiUrl}/userstories/${userStoryId}`,
                taigaUserStory
            ).pipe(map(async (userstory) => {
                return resolve(userstory)
            })).subscribe({
                next: (v) => { },
                error: (e) => {
                    return reject(e)
                },
                complete: () => { }
            })

        })
    }

    updateUserStoryStatus(userStoryId: number, taigaUserStory: TaigaUserStory): Promise<TaigaUserStory> {
        return new Promise<TaigaUserStory>(async (resolve, reject) => {
            this.http.patch<TaigaUserStory>(
                `${environment.taigaApiUrl}/userstories/${userStoryId}`,
                {
                    status: taigaUserStory.status,
                    version: taigaUserStory.version
                }
            ).pipe(map(async (userstory) => {
                return resolve(userstory)
            })).subscribe({
                next: (v) => { },
                error: (e) => {
                    return reject(e)
                },
                complete: () => { }
            })

        })
    }


    deleteUserStory(userStoryId) {
        return new Promise<TaigaUserStory>(async (resolve, reject) => {
            this.http.delete<TaigaUserStory>(
                `${environment.taigaApiUrl}/userstories/${userStoryId}`
            ).pipe(map(async (userstory) => {
                return resolve(userstory)
            })).subscribe({
                next: (v) => { },
                error: (e) => {
                    return reject(e)
                },
                complete: () => { }
            })

        })
    }




}
