import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  Component,
  NgModule,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  NbDialogService,
  NbMenuService,
  NbPosition,
  NbSearchService,
} from '@nebular/theme';
import * as _ from 'lodash';
import { map } from 'rxjs';
import { Artifact } from 'src/app/@core/models/@fima/artifact.model';
import { Project, STATUS } from 'src/app/@core/models/@fima/project.model';
import { ArtifactPropTree } from 'src/app/@core/models/@fima/prop.model';
import { CacheService } from 'src/app/@core/services/@fima/cache/cache.service';
import { DataTransferService } from 'src/app/@core/services/@fima/data-transfer/data-transfer.service';
import { ProjectService } from 'src/app/@core/services/@fima/project/project.service';
import { ToastService } from 'src/app/@core/services/@fima/toast/toast.servie';
import { ArtifactWindowComponent } from 'src/app/@theme/components/artifact-window/artifact-window.component';
import { ModalComponent } from 'src/app/@theme/components/modal/modal.component';
import mixpanel from 'mixpanel-browser';
import { UtilsService } from 'src/app/@core/services/@fima/utils/utils.service';
import { TaigaRoleService } from 'src/app/@core/services/@taiga/role/role.service';
import { CompanyService } from 'src/app/@core/services/@fima/company/company.service';
import { User } from 'src/app/@core/models/@fima/user.model';
import { ProfileService } from 'src/app/@core/services/@fima/profile/profile.service';

const DEFAULT_VIEW_INDEX = 0;

export enum VIEW_OPTIONS {
  LIST_VIEW = 0,
  BENTO_VIEW = 1,
  BOARD_VIEW = 2
}

declare var introJs: any;

interface Tile {
  x: number; // Width of the tile
  y: number; // Height of the tile
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',

  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit {

  @ViewChild('dialogWindow', { static: true })
  dialogWindow!: ArtifactWindowComponent;


  @ViewChild('genericModal', { static: true })
  genericModal: ModalComponent;

  isArtifactWindow = false;

  viewOptions = VIEW_OPTIONS;

  active_view = DEFAULT_VIEW_INDEX

  invitedTeamMembers: any[] = []

  existingUsersAddedToProject: any[] = [];

  genericModalInstance: import("@nebular/theme").NbDialogRef<any>;

  addCollaboratorsState = false;

  displayedInvitedTeamMembers: any[] = [];

  /**
   *
   *
   * @memberof DashboardComponent
   */
  statusEnum = STATUS;

  /**
   *
   *
   * @memberof DashboardComponent
   */
  nbPosition = NbPosition;

  /**
   *
   *
   * @memberof DashboardComponent
   */
  items = [
    { title: 'Not started', code: STATUS.NOT_STARTED },
    { title: 'In progress', code: STATUS.IN_PROGRESS },
    { title: 'Done', code: STATUS.DONE },
  ];

  /**
   *
   *
   * @type {Project[]}
   * @memberof DashboardComponent
   */
  projects: Project[] = [];


  hasProjects: boolean = false;

  /**
   *
   *
   * @type {any[]}
   * @memberof DashboardComponent
   */
  projectArtifactsTickets!: any;

  /**
   *
   *
   * @type {Artifact}
   * @memberof DashboardComponent
   */
  currentArtifact!: Artifact;

  /**
   *
   *
   * @memberof DashboardComponent
   */
  validationMessages = {
    aiInputField: [
      {
        type: 'required',
        message: 'Please enter a prompt',
      },
    ],
    email: [
      {
        type: 'required',
        message: 'Please enter a valid email address',
      },
      {
        type: 'atLeastOneTeamMemberValidator',
        message: 'Invite at least on team member to continue',
      },
    ],
    projectName: [
      {
        type: 'required',
        message: 'Project name is required',
      },
    ],
    projectGoal: [
      {
        type: 'required',
        message: 'Project goal is required',
      },
    ],
    projectObjective: [
      {
        type: 'required',
        message: 'Project objective is required',
      },
    ],
    artifactName: [
      {
        type: 'required',
        message: 'Artifact name is required',
      },
    ],
    artifactDescription: [
      {
        type: 'required',
        message: 'Artifact description is required',
      },
    ],
    projectDescription: [
      {
        type: 'required',
        message: 'Project description is required',
      },
    ],
  };

  /**
   *
   *
   * @type {FormGroup}
   * @memberof CreateComponent
   */
  artifactForm!: FormGroup;


  projectCreateForm: FormGroup;

  /**
   *
   *
   * @type {Artifact}
   * @memberof DashboardComponent
   */
  template!: Artifact;

  /**
   *
   *
   * @memberof DashboardComponent
   */
  loadingProjects = true;

  /**
   *
   *
   * @memberof DashboardComponent
   */
  /**
   *
   *
   * @type {any[]}
   * @memberof DashboardComponent
   */
  todo: any[] = [
    // your todo items here
  ];

  /**
   *
   *
   * @type {any[]}
   * @memberof DashboardComponent
   */
  inProgress: any[] = [
    // your in-progress items here
  ];

  /**
   *
   *
   * @type {any[]}
   * @memberof DashboardComponent
   */
  completed: any[] = [
    // your completed items here
  ];
  bento_list: any[] = [
    // items for the bento view here
  ];
  bento_size_list: any[] = [
    // sizes for bentos here
  ];

  /**
   *
   *
   * @type {*}
   * @memberof DashboardComponent
   */
  currentTodo: any;

  artefactPropTree!: ArtifactPropTree;

  saveBtnText: string = 'Save';

  loading = false;

  selectedStatus!: number | undefined;

  selectedDate!: number | undefined;

  projectArtifactsTicketsGroup!: _.Dictionary<any[]>;

  messages: any[];

  called: boolean = false;

  imageUrl = 'assets/bento-images/41.jpg'

  // This array should contain the strings of all the available image sizes based on the actual images.
  imageSizes = ['12', '13', '21', '22', '23', '31', '32', '33', '41', '42', '43'];

  createWithAIFrom: FormGroup;

  constructor(
    public router: Router,
    public dataTransferService: DataTransferService,
    public cacheService: CacheService,
    public nbMenuService: NbMenuService,
    public dialogService: NbDialogService,
    public formBuilder: FormBuilder,
    public projectService: ProjectService,
    public utils: UtilsService,
    public roleService: TaigaRoleService,
    public companyService: CompanyService,
    public toastService: ToastService,
    public profileService: ProfileService,

  ) {
    this.nbMenuService
      .onItemClick()
      .pipe(map(({ item: { title } }) => title))
      .subscribe(async (title) => {
        // Handle todo status change here
      });

    this.createWithAIFrom = this.formBuilder.group({
      aiInputField: ['', [Validators.required]],
    });
    this.artifactForm = this.formBuilder.group({
      artifactName: ['', [Validators.required]],
      artifactDescription: ['', [Validators.required]],
    });

    this.projectCreateForm = this.formBuilder.group({
      projectName: ['', [Validators.required]],
      projectDescription: ['', [Validators.required]],
    });

    // subscribe to fetch projects
    this.initDashData();
  }

  public initDashData() {
    this.dataTransferService.$projects.subscribe({
      next: async (projects) => {

        if (!projects || projects.length == 0) {
          this.hasProjects = false;
          return;
        }
        this.hasProjects = true;
        this.loadingProjects = true;

        this.projects = projects;

        console.log('PROJECTS', this.projects);

        const projectArtifactsTickets = this.getProjectsArtifactsAsTickets(projects);

        this.projectArtifactsTickets = projectArtifactsTickets;

        // Convert the sorted entries back into an object
        this.projectArtifactsTicketsGroup = _.groupBy(projectArtifactsTickets, 'whenFlag');

        this.todo = projectArtifactsTickets.filter((todo) => {
          return (
            todo.status == this.statusEnum.NOT_STARTED || todo.status == null
          );
        });

        this.inProgress = projectArtifactsTickets.filter((todo) => {
          return todo.status == this.statusEnum.IN_PROGRESS;
        });

        this.completed = projectArtifactsTickets.filter((todo) => {
          return todo.status == this.statusEnum.DONE;
        });

        this.loadingProjects = false;
      },
      error: () => {
        this.loadingProjects = false;
      },
      complete: () => { },
    });
  }

  ngOnInit() { }

  /**
   * @memberof DashboardComponent
   */
  ngAfterViewInit(): void {
    // const DELAY_TIME_BEFORE_HINTS_RENDER = 100; // milliseconds
    // setTimeout(() => {
    //   var intro = introJs();
    //   intro.setOptions({
    //     dontShowAgain: true,
    //     scrollToElement: false,
    //     steps: [
    //       {
    //         title: 'Hey ',
    //         intro: 'Lets show you around your workspace 🧭.',
    //       },
    //       {
    //         title: 'Stay on track',
    //         element: document.querySelector('.dash-step1'),
    //         intro:
    //           'Your project artifacts are now available as to-dos in the home section. Keep an eye on them!',
    //       },
    //       {
    //         title: 'Choose a view',
    //         element: document.querySelector('.dash-step2'),
    //         intro:
    //           'You can switch between board or list view of your working artifacts.',
    //       },
    //       {
    //         title: 'Jump into a project',
    //         element: document.querySelector('.dash-step3'),
    //         intro: 'Your can find and focus into a specific project here.',
    //       },
    //       // {
    //       //   title: 'Need inspiration?',
    //       //   element: document.querySelector('.dash-step4'),
    //       //   intro:
    //       //     'Explore our example project to see Fima.ai in action and gather ideas for your own.',
    //       // },
    //     ],
    //   });

    //   intro.start();
    // }, DELAY_TIME_BEFORE_HINTS_RENDER);
  }

  /**
   * @param {*} obj
   * @return {*}  {(_.Dictionary<any[]> | any)}
   * @memberof DashboardComponent
   */
  sortObjectByGroupKey(obj: any): _.Dictionary<any[]> | any {
    const sortedEntries = Object.entries(obj).sort((a, b) => {
      return b[0].localeCompare(a[0]);
    });
    return Object.fromEntries(sortedEntries);
  }

  /**
   * @param {*} event
   * @param {(Artifact | any)} [todo]
   * @param {(number | any)} [index]
   * @memberof DashboardComponent
   */
  onClickArtifact(event: any, todo?: Artifact | any, index?: number | any) {
    this.currentTodo = todo;
    this.currentArtifact = todo;

    this.template = todo;

    // unselect prev selected index
    try {
      const prevIndex = this.cacheService.retrieveItem('prev:item');
      this.projectArtifactsTickets[parseInt(prevIndex)].active = false;
    } catch (error) { }

    // now select new index
    this.projectArtifactsTickets[index].active = true;
    // cache prev selected index
    this.cacheService.storeItem('prev:item', `${index}`);
  }

  onDragStart(event: any) {
    event.stopPropagation();
  }

  stopPropagation(event: any) {
    event.stopPropagation();
  }

  /**
   * @memberof DashboardComponent
   */
  async onOpenArtifact(artifact?: Artifact, index?: number) {
    if (artifact && index !== undefined) {
      this.onClickArtifact({}, artifact, index);
    }

    let item = this.template;

    this.artifactForm.get('artifactName')?.setValue(item.label);
    this.artifactForm.get('artifactDescription')?.setValue(item.aside);

    try {
      this.dataTransferService.$jsonData.next(item.artifactPropTree);
    } catch (error) {
      console.log('DashboardComponent >> fetchTemplate >> error >> ', error);
      this.dataTransferService.$jsonData.next(null);
    }

    this.currentArtifact = item;
    const dialogRef = this.dialogService.open(
      this.dialogWindow.showDialogComponent,
      {
        context: {
          item,
        },
        hasScroll: false,
        hasBackdrop: true,
        closeOnEsc: true,
        closeOnBackdropClick: true,
      }
    );
  }

  // Board view controller

  /**
   *
   *
   * @param {(CdkDragDrop<string[]> | any)} event
   * @memberof DashboardComponentx
   */
  drop(event: CdkDragDrop<string[]> | any) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      // Get the dropped data
      let droppedData = event.container.data[event.currentIndex];

      // Determine the new status based on the container it was dropped into
      let newStatus;
      if (event.container.id === 'todo') {
        newStatus = this.statusEnum.NOT_STARTED;
      } else if (event.container.id === 'inProgress') {
        newStatus = this.statusEnum.IN_PROGRESS;
      } else if (event.container.id === 'completed') {
        newStatus = this.statusEnum.DONE;
      }

      // Update the status of the dropped item
      if (newStatus) {
        this.updateArtefact(droppedData, droppedData.projectId, newStatus);
      }
    }
  }

  /**
   * @param {STATUS} status
   * @memberof DashboardComponent
  */
  async updateArtefact(
    artifact: Artifact,
    projectId: number,
    status?: string,
    newData?: ArtifactPropTree
  ) {
    console.log(artifact, 1)
    delete (artifact as any).whenFlag;
    try {
      // Update projects
      const project = this.projects
        .filter((project) => {
          return project?.id == projectId;
        })
        .shift();

      if (!project) {
        return;
      }

      const artifactIndex = project.projectArtifacts.findIndex((a) => {
        return a.id == artifact.id;
      });

      if (status) {
        artifact.status = status;
      }

      if (newData) {
        artifact.artifactPropTree = newData;
      }
      console.log(artifact, 2)
      artifact.updatedAt = new Date().getTime();

      const artifactName = this.artifactForm.get('artifactName')?.value;

      const artifactDesc = this.artifactForm.get('artifactDescription')?.value;

      artifact.label =
        artifactName !== null && artifactName.length > 0
          ? artifactName
          : artifact.label;

      artifact.aside =
        artifactDesc != null && artifactDesc.length > 0
          ? artifactDesc
          : artifact.aside;

      project.projectArtifacts[artifactIndex] = artifact;

      project.updatedAt = new Date().getTime();

      const updatedProject = await this.projectService.updateProjectArtifact(
        project,
        artifact
      );

      // Fetch projects
      await this.pollAppData();
    } catch (error) {
      console.log(
        'DashboardComponent >>getProjects >> updateArtefactStatus >>  ',
        error
      );
    }
  }

  /**
   *
   *
   * @param {ArtifactPropTree} data
   * @memberof DashboardComponent
   */
  watchEditorDataStream(data: ArtifactPropTree) {
    this.artefactPropTree = data;
  }

  /**
   *
   *
   * @memberof DashboardComponent
   */
  async onSaveArtifact() {
    this.loading = true;

    await this.updateArtefact(
      this.currentArtifact,
      this.currentTodo?.projectId,
      undefined,
      this.artefactPropTree
    );

    this.loading = false;

    this.dataTransferService.$closeArtefactWindow.next(true);
    // Fetch projects
    await this.pollAppData();

    this.toastService.showToast('Saved your artefact', 'success');
  }

  /**
   * Deletes an artifact from a selected project
   *
   *
   * @param {Artifact} artifact
   * @memberof DashboardComponent
   */
  async onDeleteArtifact(artifact: Artifact) {
    this.loading = true;
    // find the artifact that was clicked on's position in the project

    // Update projects
    const project = this.projects
      .filter((project) => {
        return project?.id == this.currentTodo?.projectId;
      })
      .shift() as Project;

    if (!project) {
      return;
    }

    const artifactIndex = project.projectArtifacts.findIndex((a) => {
      return a.id == artifact.id;
    });

    if (artifactIndex == -1) {
      return;
    }

    project.projectArtifacts.splice(artifactIndex, 1);

    await this.projectService.deleteProjectArtifact(
      project,
      this.currentTodo.id,
    );

    this.dataTransferService.$closeArtefactWindow.next(true);

    // Fetch projects
    await this.pollAppData();

    this.loading = false;

    this.toastService.showToast('Artefact deleted', 'danger');
  }

  /**
   *
   *
   * @memberof DashboardComponent
   */
  clearFilters() { }

  async onRefresh() {
    await this.pollAppData();
    this.toastService.showToast('Fetched projects', 'success');
  }

  /**
   * @public
   * @memberof DashboardComponent
   */
  public async pollAppData() {
    this.dataTransferService.$onfetchAppData.next(true);
  }

  /**
   *
   *
   * @public
   * @memberof DashboardComponent
   */
  public getProjectsArtifactsAsTickets(projects: Project[]) {


    if (!projects || projects.length == 0) {
      return
    }

    let result: any[] = [];

    try {

      for (let i = 0; i < projects.length; i++) {

        const project = projects[i]

        const res = project.projectArtifacts.map((artifact) => {
          const whenFlag = this.getWhenFlag(artifact.updatedAt);
          return {
            ...artifact,
            projectId: project?.id,
            projectName: project.projectName,
            active: false,
            tags: ['', '', ''],
            whenFlag,
          };
        });

        result = result.concat(res);
      }

    } catch (error) {
      console.log('DashboardComponent >> getProjects >> error >> ', error);
    }

    return result.sort((a, b) => {
      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(); // Corrected the sorting
    });
  }

  public getWhenFlag(updatedAt: number) {
    const today = new Date();
    const updatedDate = new Date(updatedAt);
    const diffDays = Math.floor(
      (today.getTime() - updatedDate.getTime()) / (1000 * 60 * 60 * 24)
    );

    if (diffDays === 0) return 'Today';
    if (diffDays <= 7) return 'Last 7 days';
    if (diffDays <= 30) return 'Last 30 days';
    if (updatedDate.getFullYear() === today.getFullYear()) return 'This year';
    if (updatedDate.getFullYear() === today.getFullYear() - 1)
      return 'Last year';

    return ''; // Default case
  }

  openModal(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }


  openGenericModal() {
    this.invitedTeamMembers = []
    this.displayedInvitedTeamMembers = []
    this.genericModalInstance = this.dialogService.open(this.genericModal.modalComponent, { context: 'this is some additional data passed to dialog' });
  }



  async onCreateProject() {
    // validate form

    const formErrors = this.utils.validateForm(this.projectCreateForm);

    if (formErrors.length > 0) {
      return;
    }

    this.genericModalInstance.close()

    this.loading = true;

    // Fetch the company created by initial user | if parent user then just use owner companyUser
    const userProfile = this.dataTransferService.$user.value;

    // Create project object
    const project: Project = {
      createdBy: userProfile?.uid as string,
      ownerCompanyId: userProfile.companyId as string,
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime(),
      endDate: null,
      status: STATUS.NOT_STARTED,
      projectThumbnailUrl: '',
      projectName: this.projectCreateForm.get('projectName')?.value,
      projectGoal: this.projectCreateForm.get('projectDescription')?.value,
      projectIntegrations: [],
      projectArtifacts: [],
      users: [],
      invitedUsers: [],
      inviteAllCompany: false,
    } as Project;

    try {
      const createdProject = await this.projectService.createProject(project); //<-- project

      const roles = await this.roleService.getRolesForProject(createdProject.id)

      const role = roles.filter(
        (role) => role.name.toLowerCase() === 'stakeholder'
      ).shift();


      for (let i = 0; i < this.invitedTeamMembers.length; i++) {
        this.invitedTeamMembers[i]['invitationProjectId'] = createdProject.id;
        this.invitedTeamMembers[i]['role'] = role
      }

      // update company with project and number of projects
      const company = await this.companyService.getCompany(
        userProfile.companyId as string
      );
      (company.projects as string[])?.push(createdProject?.id as string);
      company.projectsCount = company.projects?.length;
      company.invitedUsers = this.invitedTeamMembers;


      // for existing members , add project by calling the invitation api 
      // first call the role API to get proles for project
      // add users to project in bulk as stakeholders role


      const updatedCompany = await this.companyService.updateCompany(company);

      const currentUser = this.dataTransferService.$user.value;

      if (!currentUser.hasCreatedProject) {
        currentUser.hasCreatedProject = true;
        const updatedUser = await this.profileService.updateUserProfile(
          currentUser
        );
      }

      let projects!: Project[];
      const MAX_PROJECTS_TO_FETCH = 100;

      try {
        projects = await this.projectService.getProjects(
          currentUser?.companyId as string,
          MAX_PROJECTS_TO_FETCH
        );
      } catch (error) {
        console.log('DashboardComponent >>getProjects >> error >>  ', error);
      }

      this.dataTransferService.$projects.next(projects);

      // navigate to portal
      this.router.navigate(['/portal/project'], {
        queryParams: {
          id: createdProject.id
        }
      });

      this.toastService.showToast('Created your project', 'success');
    } catch (error) {
      console.log('CreateComponent >> onCreateProject >> error ', error);
    }

    this.loading = false;

    mixpanel.track('Create Project', {
      // TODO: add other fields as metadata
    })
  }

  copyInviteLink() {
  }

  watchInvitedTeamMembersData(event) {
    this.invitedTeamMembers = event
    this.displayedInvitedTeamMembers = event.map(e => e.email)
  }

  watchExistingMembersData(event) {
    this.existingUsersAddedToProject = event
  }

  watchRemoveInvitedCoworker(event) {
    const invitedTeamMemberIndex = this.invitedTeamMembers.findIndex((user) => {
      return user == event.email
    })
    const displayedTeamMemberIndex = this.displayedInvitedTeamMembers.findIndex((user) => {
      return user == event.email
    })

    this.invitedTeamMembers.splice(invitedTeamMemberIndex, 1)
    this.displayedInvitedTeamMembers.splice(displayedTeamMemberIndex, 1)
  }

  NewTab(url: string) {
    // window.open( 
    //   url, "_blank");
    console.log(url)
  }
  bento_view_func() {
    this.bento_list = [...this.todo, ...this.inProgress, ...this.completed];
    this.bento_list.sort((a, b) => b.updatedAt - a.updatedAt);
    this.bento_size_list = this.tile_map_gen(this.bento_list.length);

    // Assign images to bento cards based on their size, use default if specific size not available
    this.bento_list = this.bento_list.map((card, index) => {
      const size = this.bento_size_list[index];
      // Construct the potential image path
      const potentialImagePath = `${size.x}${size.y}`;
      // Determine the image path, defaulting to 'default.jpg' if the specific size is not available
      const imagePath = this.imageSizes.includes(potentialImagePath)
        ? `../../../../../assets/bento-images/${potentialImagePath}.jpg`
        : `../../../../../assets/bento-images/default.jpg`; // TODO: add the default image to assets
      // Assign the determined image path to the card object
      card.size = size
      card.image = imagePath;
      return card;
    });
  }
  // v2
  tile_map_gen(arr_length: number): any[] {
    // Generate tile sizes based on the imageSizes array
    let tileSizes = this.imageSizes.map((imageSize) => {
      // Extract x and y from the imageSize string (e.g., '12' becomes {x: 1, y: 2})
      let x = parseInt(imageSize.charAt(0));
      let y = parseInt(imageSize.charAt(1));
      return {
        x: x,
        y: y,
        weight: this.calculateWeight(imageSize) // Calculate the weight dynamically
      };
    });

    let selectionWeights = tileSizes.flatMap(({ weight }, index) =>
      Array(weight).fill(index)
    );

    let selectRandomTileSize = () => {
      let randomIndex = selectionWeights[Math.floor(Math.random() * selectionWeights.length)];
      return tileSizes[randomIndex];
    };

    let bentoSizes = [];
    for (let i = 0; i < arr_length; i++) {
      bentoSizes.push(selectRandomTileSize());
    }

    return bentoSizes;
  }

  calculateWeight(imageSizeId) {
    // Define your logic to calculate the weight based on the aspect ratio or other criteria
    // For instance, give more weight to aspect ratios that are more common or preferred
    // This is a simple example where '12' and '21' have higher weights than '13' and '42'

    const preferredAspectRatios = ['12', '21']; // Add any other preferred aspect ratios
    const standardWeight = 10;
    const preferredWeight = 25;

    return preferredAspectRatios.includes(imageSizeId) ? preferredWeight : standardWeight;
  }

  setView(viewOpt: number) {

    if (viewOpt == this.viewOptions.BENTO_VIEW) {
      this.bento_view_func()
    }
    this.active_view = viewOpt
  }

  change_cover() {
    let img = document.querySelectorAll('.acc-img')
    let card = img[Math.round(Math.random() * img.length)]
    card.classList.add('active')

  }

  askAIToBuiild() {
    if (this.createWithAIFrom.valid) {
      console.log('Form submitted:', this.createWithAIFrom.value);
    } else {
      console.log('Form has validation errors.', this.createWithAIFrom.value);
    }
    // clear field
    this.createWithAIFrom.get('aiInputField')?.setValue('');
  }



}
