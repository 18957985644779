import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { FirebaseAuthService } from '../../@fima/auth/auth.service';
import { ProfileService } from '../../@fima/profile/profile.service';
import { TokenService } from '../token/token.service';
import { TaigaAuthService } from './auth.service';
import { User } from 'src/app/@core/models/@fima/user.model';
import { DataTransferService } from '../../@fima/data-transfer/data-transfer.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    user: User;

    constructor(
        private tokenService: TokenService,
        private taigaAuthService: TaigaAuthService,
        private firebaseAuthService: FirebaseAuthService,
        private profileService: ProfileService,
        private dataTransferService: DataTransferService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (req.url.includes('auth/refresh')) {
            return next.handle(req);
        }

        return from(this.firebaseAuthService.auth.currentUser).pipe(
            switchMap(currentUser => {

                if (currentUser) {
                    return from(this.profileService.getUserProfile(currentUser.uid)).pipe(
                        switchMap(user => {
                            this.user = user;
                            const token = (user as any).taigaAuthToken;
                            const refreshToken = (user as any).taigaRefreshToken;

                            this.tokenService.saveRefreshToken(refreshToken)

                            if ((token && token.length > 0) && (refreshToken && refreshToken.length > 0)) {
                                req = req.clone({
                                    setHeaders: {
                                        'Content-Type': 'application/json',
                                        Authorization: `Bearer ${token}`,
                                    }
                                });
                                return next.handle(req);
                            } else {
                                // tokens do not exist we must logout user 
                                this.firebaseAuthService.signOut()
                                return
                            }

                        })
                    );
                } else {
                    return next.handle(req);
                }
            }),
            catchError((error) => {

                if (error.status === 401) {
                    console.log('Token needs to be refreshed', error);

                    const refreshToken = this.tokenService.getRefreshToken();

                    return from(this.tokenService.refreshTaigaToken(refreshToken)).pipe(
                        switchMap((result) => {
                            debugger
                            console.log('Token refreshed ', result)

                            const token = (result as any).auth_token;
                            const refreshToken = (result as any).refresh;

                            // Update firebase user with the new token information

                            if (this.user) {
                                this.user.taigaAuthToken = token;
                                this.user.taigaRefreshToken = refreshToken;
                                this.profileService.updateUserProfile(this.user)
                                    .then((user) => {
                                        // Makes it it so that the local user gets updated with the refreshed user 
                                        const FORCE_FETCH_USER_FROM_DB = true;
                                        this.profileService.getUserProfile(this.user.uid, FORCE_FETCH_USER_FROM_DB);
                                    })
                            }

                            // Save the new token
                            this.tokenService.saveRefreshToken(refreshToken);

                            // Clone the original request with the new token
                            const authReq = req.clone({
                                setHeaders: {
                                    'Content-Type': 'application/json',
                                    Authorization: `Bearer ${token}`
                                },
                            });

                            // Retry the request with the new token

                            // Trigger a data fetch 

                            // This should happen after return executes 

                            const WAIT_TIME_BEFORE_FETCH_TRIGGER = 2000;

                            setTimeout(() => {
                                this.dataTransferService.$onfetchAppData.next(true)
                            }, WAIT_TIME_BEFORE_FETCH_TRIGGER);

                            return next.handle(authReq);
                        }),
                        catchError((refreshError) => {
                            // Handle refresh token failure, e.g., logout the user or redirect to login
                            console.error("Failed to refresh token", refreshError);
                            return throwError(refreshError);
                        })
                    );
                }

                // Forward the error if it's not a 401
                return throwError(error);
            })
        );
    }
}
