import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder, FormControl, FormGroup, ValidationErrors, Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import firebase from 'firebase/compat';
import { Company } from 'src/app/@core/models/@fima/company.model';
import { User } from 'src/app/@core/models/@fima/user.model';
import { FirebaseAuthService } from 'src/app/@core/services/@fima/auth/auth.service';
import { CompanyService } from 'src/app/@core/services/@fima/company/company.service';
import { ProfileService } from 'src/app/@core/services/@fima/profile/profile.service';
import { UtilsService } from 'src/app/@core/services/@fima/utils/utils.service';
import mixpanel from 'mixpanel-browser';

declare var introJs: any;

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent implements OnInit, AfterViewInit {

  /**
   *
   *
   * @memberof OnboardingComponent
   */
  validationMessages = {
    email: [
      {
        type: 'email',
        message: 'Please enter a valid email address',
      },
      {
        type: 'canNotInviteYourselfValidator',
        message: 'You are are already member of this team',
      },
    ],
    password: [
      {
        type: 'required',
        message: 'This field is required.',
      },
      {
        type: 'minlength',
        message: 'Minimum length is 8 characters.',
      },
    ],
    name: [
      {
        type: 'required',
        message: 'This field is required.',
      },
    ],
    surname: [
      {
        type: 'required',
        message: 'This field is required.',
      },
    ],
    companyName: [
      {
        type: 'required',
        message: 'Please enter your company name.',
      },
    ],
    companyNameReadOnly: [
      {
        type: '',
        message: '',
      },
    ],
  };

  /**
   *
   *
   * @type {FormGroup}
   * @memberof OnboardingComponent
   */
  onboardingForm: FormGroup;

  /**
   *
   *
   * @type {(User | undefined)}
   * @memberof OnboardingComponent
   */
  firebaseUser!: firebase.User;

  /**
   *
   *
   * @type {string[]}
   * @memberof OnboardingComponent
   */
  invitedTeamMembers: User[] = [];


  /**
   *
   *
   * @type {Company}
   * @memberof OnboardingComponent
   */
  company!: Company;

  /**
   *
   *
   * @memberof OnboardingComponent
   */
  loading = false;

  mailSendIcon = 'assets/remix-icons/mail-line.svg'

  constructor(
    private formBuilder: FormBuilder,
    public utilitiesService: UtilsService,
    private authService: FirebaseAuthService,
    private companyService: CompanyService,
    private profileService: ProfileService,
    private router: Router
  ) {
    const email = new FormControl('', {
      validators: [Validators.email],
      asyncValidators: [this.canNotInviteYourselfValidator.bind(this)]
    });

    this.onboardingForm = this.formBuilder.group({
      email,
      companyName: ['', [Validators.required]],
      companyNameReadOnly: [''],
      subscribeToProducts: [true],
    });
  }

  /**
   *
   *
   * @memberof OnboardingComponent
   */
  ngAfterViewInit() {
    const DELAY_TIME_BEFORE_HINTS_RENDER = 100; // milliseconds
    setTimeout(() => {
      introJs().start()
    }, DELAY_TIME_BEFORE_HINTS_RENDER);
  }

  /**
   *
   *
   * @return {*}  {Promise<void>}
   * @memberof OnboardingComponent
   */
  async ngOnInit(): Promise<void> { }

  /**
   *
   *
   * @memberof OnboardingComponent
   */
  async onCreateCompany() {
    this.onboardingForm.get('companyName')?.markAsTouched({ onlySelf: true });

    if (this.onboardingForm.get('companyName')?.hasError('required')) {
      return
    }

    try {

      this.firebaseUser = await this.authService.auth.currentUser as firebase.User;

      // Create company
      this.company = {
        createdBy: this.firebaseUser.uid,
        createdAt: new Date().getTime(),
        updatedAt: new Date().getTime(),
        companyName: this.onboardingForm.get('companyName')?.value as string,
        usersCount: 0,
        projectsCount: 0,
        invitedUsers: [],
        users: [],
        projects: [],
        paymentMethods: null,
        settings: null
      }

      this.onCompleteOnboarding()

    } catch (error) {
      console.log('OnboardingComponent >> onCreateCompany >> ', error)
    }
  }

  getInvitationEmailAddress() {
    return this.onboardingForm.get("email")?.value
  }

  /**
   *
   *
   * @memberof OnboardingComponent
   */
  onAddEmailAddress() {

    this.onboardingForm.get('email')?.markAsTouched({ onlySelf: true });

    if (this.onboardingForm.get('email')?.hasError('email') || this.onboardingForm.get('email')?.value == '') {
      return
    }

    //create  partial user for invited coworker

    const invitedUser: User | any = {
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime(),
      email: this.onboardingForm.get('email')?.value,
      hasAcceptedInvitation: false,
      uid: null,
    }


    invitedUser['appEnvironmentUrl'] = window.location.origin;

    this.invitedTeamMembers.push(invitedUser)
    this.onboardingForm.get('email')?.setValue('')
  }

  /**
   *
   *
   * @param {number} index
   * @memberof OnboardingComponent
   */
  onRemoveInvitedCoworker(index: number) {
    this.invitedTeamMembers.splice(index, 1);
    this.onboardingForm.get('email')?.markAsTouched({ onlySelf: true });;
    this.onboardingForm
      .get('email')
      ?.updateValueAndValidity({ onlySelf: true });
  }


  /**
   *
   *
   * @return {*} 
   * @memberof OnboardingComponent
   */
  async onCompleteOnboarding() {
    const res = this.utilitiesService.validateForm(this.onboardingForm);

    if (res.length > 0) {
      return;
    }

    this.loading = true;

    try {
      // add logged in user as the first user of the company 

      (this.company['users'] as User[]).push(
        await this.profileService.getUserProfile(this.firebaseUser.uid as string)        
      )

      // add invited users if any

      if (this.invitedTeamMembers.length > 0) {
        (this.company['invitedUsers'] as any[]) = (this.company['invitedUsers'] as any[]).concat(this.invitedTeamMembers)
      }

      this.company.usersCount = this.company.users?.length; //< update users that belong to company

      // Create company
      const createdCompany = await this.companyService.createCompany(this.company)

      // Update user 
      let user = await this.profileService.getUserProfile(this.firebaseUser.uid)
      user.companyId = createdCompany.id;
      user.hasSeenOnboarding = true
      user.subscribedToProductUpdates = this.onboardingForm.get('subscribeToProducts')?.value;

      const updatedUser = await this.profileService.updateUserProfile(user);

      // Nav to dashboard
      this.router.navigate(['/portal'], { replaceUrl: true })

      // console.log(createdCompany)


    } catch (error) {
      // display error to user
      console.log('OnboardingComponent >> onCompleteOnboarding >. error >> ', error)
    }

    this.loading = false;

    mixpanel.track('Create Company', {
      // TODO: add other fields as metadata
    })

  }


  /**
   *
   *
   * @param {AbstractControl} control
   * @return {*}  {(Promise<ValidationErrors | null>)}
   * @memberof OnboardingComponent
   */
  canNotInviteYourselfValidator(control: AbstractControl): Promise<ValidationErrors | null> {
    if (!control.parent) {
      return Promise.resolve(null);
    } else if (
      this.firebaseUser && this.firebaseUser.email == control?.parent.get('email')?.value
    ) {
      control?.parent.get('email')?.markAsTouched({ onlySelf: true });
      return Promise.resolve({
        canNotInviteYourselfValidator: true,
      });
    } else {
      return Promise.resolve(null);
    }
  }
}
