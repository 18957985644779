import { Component, OnInit } from '@angular/core';
import firebase from 'firebase/compat';
import { User } from 'src/app/@core/models/@fima/user.model';
import { ProfileService } from 'src/app/@core/services/@fima/profile/profile.service';
import { DataTransferService } from 'src/app/@core/services/@fima/data-transfer/data-transfer.service';
@Component({
  selector: 'app-launchpad',
  templateUrl: './launchpad.component.html',
  styleUrls: ['./launchpad.component.scss']
})
export class LaunchpadComponent implements OnInit{
    /**
   *
   *
   * @type {User}
   * @memberof DashboardLayoutComponent
   */
    user!: firebase.User;
    usr!: User
    constructor(public profileService: ProfileService,
      public datatransferservice:DataTransferService
    ){}
    ngOnInit(): void {
       let data_user= this.datatransferservice.$user
    this.usr= data_user.value
        
    }

}
