import { TaigaUserStory } from "../@taiga/user-story.model";
import { ArtifactPropTree } from "./prop.model";

export interface Artifact extends TaigaUserStory{
    // Default props
    id: any,
    label: string;
    parent?: ParentHeaderEnum;
    status: any;
    updatedAt: number;
    createdAt: number;
    artifactPropTree: ArtifactPropTree | any; // TODO: Rename this to description
    showOpts: boolean;
    assignees?: number[];
    duration?: number;

    // Used when fetching from prebuild templates
    templateId?: string;
    aside?: string;


    // Future implantation,, for when artifacts are sharable ? 
    link?: string;
    icon?: string;
}


export enum ParentHeaderEnum {
    DISCOVER  = 'Discover',
    DEFINE = 'Define',
    DESIGN_AND_PROTOTYPE = 'Design and prototype',
    EVALUATE_AND_MEASURE = 'Evaluate and measure',
    CUSTOM = 'Custom artifact'
}

  