import { Injectable } from '@angular/core';
import { NbGlobalPhysicalPosition, NbGlobalLogicalPosition, NbToastrService, NbComponentOrCustomStatus } from '@nebular/theme';



@Injectable({
    providedIn: 'root',
})
export class ToastService {

    physicalPositions = NbGlobalPhysicalPosition;
    logicalPositions = NbGlobalLogicalPosition;
    constructor(
        private toastrService: NbToastrService
    ) {
    }

    /**
     *
     *
     * @memberof ToastService
     */
    showToast(message: string, status: NbComponentOrCustomStatus,  opts?: any) {
        this.toastrService
        .show(`${message}`, '', { position: this.logicalPositions.BOTTOM_END,  duration: 4000, status});
    }
}
