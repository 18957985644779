import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-sticky-note',
  templateUrl: './sticky-note.component.html',
  styleUrls: ['./sticky-note.component.scss'],
})
export class StickyNoteComponent {
  @Input() note: any;
  @Output() delete = new EventEmitter<void>();
  noteForm!: FormGroup;

  ngOnInit() {
    this.noteForm = new FormGroup({
      title: new FormControl(this.note.title),
      content: new FormControl(this.note.content)
    });
  }

  deleteNote(event?: MouseEvent) {
    if (event) {
      event.stopPropagation();
    }
    this.delete.emit();
  }
}
