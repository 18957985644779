<app-loader *ngIf="loading"></app-loader>

<div class="container">
    <div class="dp-start-screen-padding">
        <div class="card shadow dp-invitatiob-card" *ngIf="true">
            <div class="card-body py-4">
                <div class="row">
                    <div class="col">
                        <app-error-message *ngIf="loginErrors" [loginErrors]="loginErrors"></app-error-message>
                    </div>
                </div>


                <div class="row">
                    <div class="col-12 my-auto mx-auto text-center">
                        <div class="row">
                            <div class="col dp-welcome-container text-center">
                                <h1 class="dp-welcome-header m-0">You're ready to go </h1>
                                <p class="dp-welcome-subheader text-muted m-0">Here's a preview of your work</p>
                                <hr>

                                <div class="row  mb-2 dp-clickable dp-project-container"
                                    style="padding: 2px 0px 2px 0px"
                                    *ngFor="let project of [{projectName: 'One project', id: 1, users: ['4',  '1', '3', '4']}, {projectName: 'Two project', id: 1, users: ['3',  '2']}]">
                                    <div class="col-auto m-0  my-auto">
                                        <img src="assets/create-modal-icons/folder.svg" alt="Project image" />
                                    </div>
                                    <div class="col text-left m-0 pl-0 my-auto">
                                        <p class="m-0">{{ project.projectName | truncate : 12 : "..." }}</p>
                                    </div>

                                    <div class="col-auto  m-0 pl-0 my-auto">
                                        <div class="ml-3 d-flex">
                                            <ng-container *ngFor="let member of project.users; index as i">
                                              <!-- Next two users stacked -->
                                              <nb-user
                                                *ngIf="i >= 0 && i < 2"
                                                style="margin-left: -16px !important"
                                                [size]="'small'"
                                                [picture]="''"
                                                [name]="member"
                                                [nbTooltip]="member"
                                                [color]="'#f2cece'"
                                                [showName]="false"
                                              >
                                              </nb-user>
                                              <!-- Additional users -->
                                              <nb-user
                                                *ngIf="i >= 2 && i < 3"
                                                style="margin-left: -16px !important"
                                                [size]="'small'"
                                                [picture]="''"
                                                [showName]="false"
                                                [name]="'+ ' + ( project.users.length - 2 )"
                                                [color]="'#e0cef2'"
                                                [showInitials]="true"
                                              >
                                              </nb-user>
                                            </ng-container>
                                          </div>
                                    </div>
                                </div>

                                <hr>
                                <button type="button" class="btn btn-primary btn-block dp-login-btn mt-3 rounded-pill">
                                    Start Collaborating
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>