<form [formGroup]="usersSearchForm">
  <div class="row">
    <div class="col-12">
      <label *ngIf="showLabel" for="teamMemberEmailInput" class="dp-input-label">Add collaborators</label>
    </div>
    <div class="col text-left mt-1" *ngIf="!checked">
      <input [ngClass]="{
            'dp-invite-members-search-custom': inputCustomCssClass,
            'dp-text-input dp-invite-members-search': true,
            'input-sm': true,
            'form-control': true,
            'is-invalid ':
              usersSearchForm.get('searchInput')?.errors &&
              usersSearchForm.get('searchInput')?.touched
          }" placeholder="Type in a name or email address" formControlName="searchInput" type="text"
        aria-describedby="teamMemberEmailInput" />

      <ng-container *ngFor="let validation of validationMessages.searchInput">
        <div class="invalid-feedback" *ngIf="
              usersSearchForm.get('searchInput')?.hasError(validation.type) &&
              (usersSearchForm.get('searchInput')?.dirty ||
                usersSearchForm.get('searchInput')?.touched)
            ">
          {{ validation.message }}
        </div>
      </ng-container>


      <!-- This handled search and add of new email addresses -->
      <div class="row"
        *ngIf="usersSearchForm.get('searchInput')?.value?.length > 2 || invitedTeamMembers?.length > 0">
        <div class="col dp-invite-or-search-results">

          <!-- ## Search results ## -->
          <ng-container>
            <!-- No results -->
            <ng-container
              *ngIf="matchingUsers?.length ==  0 && matchingEmailAddress?.length == 0 && usersSearchForm.get('searchInput').value?.length > 2 ">
              <div class="row">
                <div class="col my-auto text-center">
                  <p class="m-0 p-4">No results</p>
                </div>
              </div>
              <hr class="" *ngIf="invitedTeamMembers?.length > 0">
            </ng-container>

            <!-- email addresses that can be invited -->
            <ng-container *ngIf="matchingEmailAddress">
              <hr *ngIf="matchingUsers?.length > 0">
              <div class="row">
                <div class="col-auto pr-0 my-auto">
                  <i class="ri-mail-line"></i>
                </div>
                <div class="col my-auto">
                  <p class="m-0">{{matchingEmailAddress}}</p>
                  <p class="m-0"><small class="text-muted">Not a member of your company</small></p>
                </div>
                <div class="col my-auto text-right">
                  <button class="btn btn-outline-primary rounded-pill px-4"
                    (click)="onAddEmailAddress(matchingEmailAddress)">
                    Invite
                  </button>
                </div>
              </div>
            </ng-container>
          </ng-container>


          <!-- ## email addresses already invited ##  -->
          <ng-container>
            <!-- Invited users -->
            <ng-container *ngIf="invitedTeamMembers?.length > 0">
              <ng-container *ngFor="let user of invitedTeamMembers; index as i">
                <hr
                  *ngIf="matchingEmailAddress?.length > 0 || matchingUsers?.length > 0">
                <div class="row">
                  <div class="col-auto pr-0 my-auto">
                    <i class="ri-mail-line"></i>
                  </div>
                  <div class="col my-auto">
                    <p class="m-0">{{user.email}}</p>
                    <p class="m-0"><small class="text-muted">Invited to join your company</small></p>
                  </div>
                  <div class="col-auto my-auto text-right">
                    <div class="d-flex">
                      <p class="m-0">Pending Invite</p>
                    </div>
                  </div>
                  <div class="col-auto my-auto">
                    <button class="btn btn-outline-danger rounded-pill" (click)="onRemoveInvitedCoworker(i, user.email)">
                      Cancel Invite
                    </button>
                  </div>
                </div>
                <hr *ngIf="i < invitedTeamMembers?.length - 1">
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>


      <!-- Users that already belong to company that can be added  -->
      <div class="row" *ngIf="false">
        <div class="col dp-invite-or-search-results">
          <!-- User that already exists -->
          <ng-container *ngIf="dataTransferService?.$company?.value?.users?.length > 0">
            <ng-container *ngFor="let user of dataTransferService?.$company?.value?.users; index as i">
              <div class="row">
                <div class="col-auto pr-0 my-auto">
                  <i class="ri-user-4-line"></i>
                </div>
                <div class="col my-auto">
                  <p class="m-0">{{user.name + ' ' + user.surname}}</p>
                </div>

                <div class="col my-auto text-right">
                  <button class="btn btn-outline-primary rounded-pill px-4" (click)="onAddExistingUser(user)">
                    + Add to project
                  </button>
                </div>

                <!-- <ng-container >
                  <div class="col-auto my-auto text-right">
                    <div class="d-flex">
                      <p class="m-0 text-muted">Added to project</p>
                    </div>
                  </div>
                  <div class="col-auto my-auto">
                    <button class="btn btn-outline-danger rounded-pill">
                      Remove from project
                    </button>
                  </div>
                </ng-container> -->
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="col-12 mt-2" *ngIf="showInviteEveryOne">
      <nb-checkbox formControlName="inviteAllCompany" (checkedChange)="toggleAddAllCompanyUsers($event)">Invite
        everyone</nb-checkbox><br />
      <small class="text-muted">Adds everyone who's a member of your company to this project</small>
    </div>
  </div>
</form>