<form [formGroup]="commentForm">
  <div class="row">
    <div class="col">
      <textarea
        [ngClass]="{
          'dp-text-input dp-comment-input': true,
          'input-sm': true,
          'form-control': true
        }"
        formControlName="comment"
        type="text"
        fullWidth
        fieldSize="giant"
        placeholder="Leave a comment"
      ></textarea>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col"></div>
    <div class="col-auto">
      <button
        class="btn btn-primary rounded-pill"
        (click)="onSubmitComment()"
        [disabled]="addingComment"
      >
        <span
          *ngIf="addingComment"
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>

        {{ addingComment ? " One sec" : "+ Add Comment" }}
      </button>
    </div>
  </div>
</form>
