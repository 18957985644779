import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  DocumentChangeAction,
  DocumentData,
} from '@angular/fire/compat/firestore';
import { WhereFilterOp } from '@angular/fire/firestore/firebase';
import { catchError, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AfsService {
  constructor(private afs: AngularFirestore) {}



  /**
   * deletes a specified document from specified collection 
   *
   * @template T
   * @param {string} documentId
   * @param {string} collection
   * @return {*} 
   * @memberof AfsService
   */
  removeDocument<T>(documentId: string, collection: string): Promise<void> {
    return this.afs.doc<T>(`${collection}/${documentId}`).delete();
  }

  /**
   * Adds single document to firestore collection
   *
   * @template T
   * @param {T} payload
   * @param {string} collection
   * @return {*}
   * @memberof AfsService
   */
  async addDocument<T>(payload: T, collection: string): Promise<T> {
    const documentReference = await this.afs
      .collection<T>(collection)
      .add(payload);
    const documentSnapshot = await documentReference.get();
    return {
      id: documentSnapshot.id,
      ...documentSnapshot.data(),
    } as T;
  }

  /**
   *
   *
   * @template T
   * @param {string} id
   * @param {string} collectionName
   * @param {T} document
   * @return {*}  {Promise<T>}
   * @memberof AfsService
   */
  updateDocument<T>(id: string, collection: string, document: T): Promise<T> {
    return new Promise<T>(async (resolve, reject) => {
      try {
        await this.afs.doc<T>(`${collection}/${id}`).update(document);
        return resolve(document);
      } catch (error) {
        return reject(error);
      }
    });
  }

  /**
   * Find single document in firestore collection
   *
   * @template T
   * @param {string} field
   * @param {WhereFilterOp} filterOp
   * @param {string} queryValue
   * @param {string} collection
   * @return {*}  {Promise<T[]>}
   * @memberof AfsService
   */
  async findDocuments<T>(
    field: string,
    filterOp: WhereFilterOp,
    queryValue: string,
    collection: string,
    limit: number
  ): Promise<T[]> {
    return new Promise(async (resolve, reject) => {
      const collectionReference = this.afs.collection<DocumentData>(
        collection,
        (ref) => ref.where(field, filterOp, queryValue).limit(limit)
      );

      const subscription = this.appendFieldsToQueryResults<T>(collectionReference)
        .pipe(
          map((docs) => {
            subscription.unsubscribe();
            return resolve(docs);
          }),
          catchError((error) => {
            throw error;
          })
        )
        .subscribe({
          next: (v) => console.log(v),
          error: (error) => {
            return reject(error);
          },
          complete: () => console.info('complete'),
        });
    });
  }

  /**
   *
   *
   * @template T
   * @param {string} id
   * @param {string} collection
   * @return {*}  {Promise<T>}
   * @memberof AfsService
   */
  findDocumentById<T>(id: string, collection: string): Promise<T> {
    return new Promise<T>(async (resolve, reject) => {
      try {
        const document = await this.afs.doc<T>(`${collection}/${id}`).ref.get();
        return resolve({
          id,
          ...(document.data() as T),
        });
      } catch (error) {
        return reject(error);
      }
    });
  }


  /**
   *
   *
   * @private
   * @template T
   * @param {AngularFirestoreCollection} collectionReference
   * @return {*} 
   * @memberof AfsService
   */
  private appendFieldsToQueryResults<T>(collectionReference: AngularFirestoreCollection) {
    return collectionReference.snapshotChanges().pipe(
      map((actions: DocumentChangeAction<DocumentData>[]) => {
        return actions.map((a) => {
          const data = a.payload.doc.data() as T;
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }
}
