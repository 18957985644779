import {
  trigger,
  transition,
  style,
  animate,
  state,
} from '@angular/animations';
import {
  Component,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { DataTransferService } from 'src/app/@core/services/@fima/data-transfer/data-transfer.service';
import * as $ from 'jquery'
import {
  NbDialogService,
  NbMenuService,
  NbPosition,
  NbSearchService,
} from '@nebular/theme'
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ModalComponent {
  @ViewChild('modalComponent', { static: true })
  modalComponent!: TemplateRef<any>;

  @Input() showLabel = false;
  @Input() showDeleteBtn = false;
  @Input() showSidePane = false;
  @Input() showSideBtn = false;
  @Input() showSaveBtn = false;
  @Input() saveBtnText!: string;
  @Input() showFooter = false;
  @Input() showPopOverMenu = false;
  @Input() showBackBtn = false;

  @Output() onClickSaveBtn: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClickDeleteBtn: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCloseBtn: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClickBackBtn: EventEmitter<any> = new EventEmitter<any>();

  nbPosition = NbPosition;
  NewTab(url: string) {
    // console.log($.data)
    window.open(
      url, "_blank");
  }
  constructor(public dataTransferService: DataTransferService) {
    this.dataTransferService.$closeArtefactWindow.subscribe({
      next: (data) => {
        if (data && data == true) {
          $('.dp-artifact-window-close-btn').click()
        }
      },
      complete: () => {

      },
      error: () => {

      }
    })
  }

  back(event?: any) {
    this.onClickBackBtn.emit(event)
  }


  ngOnChanges(changes: SimpleChanges): void {
  }
}
