import { Component } from '@angular/core';
import { ChatShowcaseService } from './chat-showcase.service';
import { DataTransferService } from 'src/app/@core/services/@fima/data-transfer/data-transfer.service';

const botAvatar: string = 'https://s3.amazonaws.com/pix.iemoji.com/images/emoji/apple/ios-12/256/robot-face.png';


@Component({
  selector: 'app-sema-ai',
  templateUrl: './sema-ai.component.html',
  styleUrls: ['./sema-ai.component.scss'],
  providers: [ ChatShowcaseService ],
})
export class SemaAIComponent {

  messages: any[];

  constructor(
    
    protected chatShowcaseService: ChatShowcaseService,
    private dataTransferService: DataTransferService
    ) {
    this.messages = this.chatShowcaseService.loadMessages();


    this.dataTransferService.$presetAiPrompt.subscribe((prompt) => {
      if (prompt) {
        const event = {
          message: prompt
        }
        this.sendMessage(event)
      }
    })
  }

  async sendMessage(event: any) {
    const files = !event.files ? [] : event.files.map((file) => {
      return {
        url: file.src,
        type: file.type,
        icon: 'file-text-outline',
      };
    });

    this.messages.push({
      text: event.message,
      date: new Date(),
      reply: true,
      type: files.length ? 'file' : 'text',
      files: files,
      user: {
        name: this.dataTransferService.$user.value.name,
        avatar: 'https://i.gifer.com/no.gif',
      },
    });
    this.messages.push({
      text:'Thinking...',
      reply: false,
      date: new Date(),
      type: 'text',
      files: [
        {
          url: '',
          type: 'image/gif',
        },
      ],
      user: {
        name: 'Sema',
        avatar: botAvatar,
      },
    })
    const botReply = await this.chatShowcaseService.reply(event.message);
    if (botReply) {
      setTimeout(() => { this.messages.push(botReply) }, 500);
    }
  }
}

