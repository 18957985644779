import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../models/@fima/user.model';
import { DataTransferService } from '../services/@fima/data-transfer/data-transfer.service';
import { UtilsService } from '../services/@fima/utils/utils.service';

@Pipe({
  name: 'resolveUserId',
})
export class ResolveUserIdPipe implements PipeTransform {
  constructor(
    private dataTransFerService: DataTransferService,
    private utils: UtilsService
  ) { }

  transform(userId: string, ...args: any[]): User | undefined {

    if (!this.dataTransFerService.$company.value) {
      return
    }
    
    const users = this.dataTransFerService.$company.value.users as any []



    const filterResult = users
      .filter((user: User | string) => {
        const { isObject, type } = this.utils.isObject(user);
        if (isObject) {
          return (user as User).uid === userId;
        }
        return false; // Ensure all code paths return a value
      })
      .shift();

    return filterResult as User;
  }
}
