import {
  Component,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NbPopoverDirective, NbPosition } from '@nebular/theme';
import { Artifact } from 'src/app/@core/models/@fima/artifact.model';
import { Comment } from 'src/app/@core/models/@fima/comment.model';
import { Project, STATUS } from 'src/app/@core/models/@fima/project.model';
import { User } from 'src/app/@core/models/@fima/user.model';
import { FirebaseAuthService } from 'src/app/@core/services/@fima/auth/auth.service';
import { CommentService } from 'src/app/@core/services/@fima/comment/comment.service';
import { DataTransferService } from 'src/app/@core/services/@fima/data-transfer/data-transfer.service';
import { ProfileService } from 'src/app/@core/services/@fima/profile/profile.service';
import { TaigaProjectService } from 'src/app/@core/services/@taiga/project/project.service';
import { TaigaUserStoryService } from 'src/app/@core/services/@taiga/user-story/user-story.service';


export enum ACTIONS {
    ADD_USERS = 'add_users',
    CHANGE_DUE_DATE = 'change_due_date',
    CHANGE_STATUS = 'change_status',
    CHANGE_PRIORITY = 'change_priority',
    ADD_SUB_TASK = 'add_sub_task'
}

@Component({
  selector: 'app-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class SidePanelComponent implements OnInit, OnChanges {
  @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;

  @Input() user!: User | any;

  @Input() showCommentSection = true;

  @Input() project!: Project | undefined;

  @Input() artifact!: Artifact | undefined;

  @Input() comments: Comment[] = [];

  showAssign = false;

  statusEnum = STATUS;

  date = new Date();

  nbPosition = NbPosition;

  dueDate = null;

  timeRemaining = 'Open Scope';

  actions = ACTIONS;

  activatedAction = '';


  // This is used to render
  statusOptions = [];

  statusOption;

  priorityOptions = [];

  priorityOption;

  tasks: any[] = []

  selectedStatus

  selectedAssignee

  constructor(
    public dataTransferService: DataTransferService,
    public commentService: CommentService,
    private taigaUserStoryService: TaigaUserStoryService,
    private taigaProjectService: TaigaProjectService
  ) {}

  async ngOnChanges() {
      try {
        // NOTE: use this method for doing network calls with incoming input data
        this.statusOptions = await this.taigaUserStoryService.getUserStoryStatuses(this.artifact.project)
        console.log('STATUSES', this.statusOptions);

        this.priorityOptions = await this.taigaProjectService.getProjectPriorities(this.artifact.project)
        console.log('PRIORITIES', this.priorityOptions)

        this.tasks = await this.taigaUserStoryService.getUserStoryTasks(this.artifact.project, this.artifact.id)
        console.log('TASKS', this.tasks)
        
      }catch (error) {

      }
  }

  async ngOnInit(): Promise<void> {

  }

  /**
   *
   *
   * @param {STATUS} value
   * @return {*}  {string}
   * @memberof SidePanelComponent
   */
  translateStatusValue(value: STATUS): string {
    switch (value) {
      case STATUS.NOT_STARTED:
        return 'Not Started';
      case STATUS.IN_PROGRESS:
        return 'In Progress';
      case STATUS.REVIEW:
        return 'Review';
      case STATUS.DONE:
        return 'Done';
      case STATUS.ARCHIVED:
        return 'Archived';
      default:
        return 'Not Started';
    }
  }

  /**
   *
   *
   * @param {*} event
   * @memberof SidePanelComponent
   */
  onDateChange(event) {
    this.popover.hide(); // close the context menu
    console.log('SidePanelComponent >> onDateChange >> ', event);

    const selectedDate = new Date(event);
    this.dueDate = selectedDate.toDateString();

    const diffMillis =
      selectedDate.getTime() - (this.project.createdAt as number);
    if (diffMillis < 0) {
      this.timeRemaining = 'Past due';
    } else {
      this.timeRemaining = this.formatTimeRemaining(diffMillis);
    }
  }

  /**
   *
   *
   * @param {*} diffMillis
   * @return {*} 
   * @memberof SidePanelComponent
   */
  formatTimeRemaining(diffMillis) {
    const minutes = Math.floor((diffMillis / (1000 * 60)) % 60);
    const hours = Math.floor((diffMillis / (1000 * 60 * 60)) % 24);
    const days = Math.floor((diffMillis / (1000 * 60 * 60 * 24)) % 30);
    const months = Math.floor((diffMillis / (1000 * 60 * 60 * 24 * 30)) % 12);
    const years = Math.floor(diffMillis / (1000 * 60 * 60 * 24 * 365));

    let timeRemainingStr = '';

    if (years > 0) {
      timeRemainingStr += `${years}yr `;
    }
    if (months > 0) {
      timeRemainingStr += `${months}mo `;
    }
    if (days > 0) {
      timeRemainingStr += `${days}d `;
    }
    if (hours > 0) {
      timeRemainingStr += `${hours}h `;
    }
    if (minutes > 0) {
      timeRemainingStr += `${minutes}mins`;
    }

    return timeRemainingStr.trim();
  }

  showActionsOverlay = false;


  toggleActionsOverlay(action : string) {
    this.activatedAction = action;     // Pass the correct actions template
    this.showActionsOverlay = true;
  }

  closeActionsOverlay (event) {
    this.showActionsOverlay = false
  }

}
