import { TaigaUserService } from './../services/@taiga/user/user.service';
import { Pipe, PipeTransform } from '@angular/core';
import { TaigaUser } from '../models/@taiga/user.model';

@Pipe({
  name: 'resolveTaigaUserId',
})
export class ResolveTaigaUserIdPipe implements PipeTransform {

  constructor(
    private taigaUserService: TaigaUserService
  ) { }

  transform(userId: number, ...args: any[]): Promise<TaigaUser>  {

    if (!userId) {
      return
    }

    return this.taigaUserService.resolveUser(userId);
  
  }
}
