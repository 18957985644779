import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NbAutocompleteModule, NbCalendarModule, NbCardModule, NbCheckboxModule, NbPopoverModule, NbSpinnerModule, NbTabsetModule, NbTooltipModule, NbTrigger, NbUserModule, NbChatModule, NbDialogModule, NbRadioModule, NbInputModule, NbSelectModule } from '@nebular/theme';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { LoaderComponent } from './loader/loader.component';
import { SideMenuContentComponent } from './side-menu-content/side-menu-content.component';
import { RouterModule } from '@angular/router';
import { ArtifactWindowComponent } from './artifact-window/artifact-window.component';
import { TruncateModule } from 'ng2-truncate';
import { StickyNoteComponent } from './sticky-note/sticky-note.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditorComponent } from './editor/editor.component';
import { SidePanelComponent } from './side-panel/side-panel.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { CommentBoxComponent } from './comment-box/comment-box.component';
import { CommentViewComponent } from './comment-view/comment-view.component';
import { PipeModule } from 'src/app/@core/pipes/pipe.module';
import { ContextMenuComponent } from './context-menu/context-menu.component';
import { InviteUsersComponent } from './invite-users/invite-users.component';
import { SemaAIComponent } from './sema-ai/sema-ai.component';
import { ModalComponent } from './modal/modal.component';
import { ActionsOverlayComponent } from './actions-overlay/actions-overlay.component';


export const components = [
  LoaderComponent,
  ErrorMessageComponent,
  SideMenuContentComponent,
  ArtifactWindowComponent,
  StickyNoteComponent,
  EditorComponent,
  SidePanelComponent,
  CommentBoxComponent,
  CommentViewComponent,
  ContextMenuComponent,
  InviteUsersComponent,
  SemaAIComponent,
  ModalComponent,
  ActionsOverlayComponent
]

@NgModule({
  declarations: components,
  imports: [
    NbChatModule,
    NbAutocompleteModule,
    NbPopoverModule,
    NbCalendarModule,
    PipeModule,
    PickerModule,
    NbSpinnerModule,
    NbUserModule,
    NbCheckboxModule,
    NbTabsetModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    RouterModule,
    NbCardModule ,
    NbTooltipModule,
    TruncateModule,
    DragDropModule,
    NbDialogModule,
    NbRadioModule,
    NbInputModule,
    NbSelectModule
  ],
  exports: components
})
export class ComponentModule { }
