import { Injectable } from '@angular/core';
import { Artifact } from '../../../models/@fima/artifact.model';
import { Comment } from '../../../models/@fima/comment.model'; // Assuming the comment model is stored here
import { Project } from '../../../models/@fima/project.model';
import { AfsService } from '../afs/afs.service';

const COMMENT_COLLECTION_NAME = 'Comments';

@Injectable({
  providedIn: 'root',
})
export class CommentService {
  constructor(private afsService: AfsService) {}

  /**
   *
   *
   * @param {Comment} comment
   * @return {*}  {Promise<Comment>}
   * @memberof CommentService
   */
  createComment(comment: Comment): Promise<Comment> {
    return new Promise<Comment>(async (resolve, reject) => {
      try {
        const createdComment = await this.afsService.addDocument<Comment>(
          comment,
          COMMENT_COLLECTION_NAME
        );
        return resolve(createdComment);
      } catch (error) {
        return reject(error);
      }
    });
  }

  /**
   *
   *
   * @param {string} projectId
   * @param {number} limit
   * @return {*}  {Promise<Comment[]>}
   * @memberof CommentService
   */
  getCommentsByProjectId(projectId: string, limit: number): Promise<Comment[]> {
    return new Promise<Comment[]>(async (resolve, reject) => {
      try {
        const comments = await this.afsService.findDocuments<Comment>(
          'projectId',
          '==',
          projectId,
          COMMENT_COLLECTION_NAME,
          limit
        );
        return resolve(comments);
      } catch (error) {
        return reject(error);
      }
    });
  }

  /**
   *
   *
   * @param {string} companyId
   * @param {number} limit
   * @return {*}  {Promise<Comment[]>}
   * @memberof CommentService
   */
  getCommentsByCompanyId(companyId: string, limit: number): Promise<Comment[]> {
    return new Promise<Comment[]>(async (resolve, reject) => {
      try {
        const comments = await this.afsService.findDocuments<Comment>(
          'commenterCompanyId',
          '==',
          companyId,
          COMMENT_COLLECTION_NAME,
          limit
        );
        return resolve(comments);
      } catch (error) {
        return reject(error);
      }
    });
  }

  /**
   *
   *
   * @param {string} commentId
   * @return {*}  {Promise<Comment>}
   * @memberof CommentService
   */
  getComment(commentId: string): Promise<Comment> {
    return new Promise<Comment>(async (resolve, reject) => {
      try {
        const comment = await this.afsService.findDocumentById<Comment>(
          commentId,
          COMMENT_COLLECTION_NAME
        );
        return resolve(comment);
      } catch (error) {
        return reject(error);
      }
    });
  }

  /**
   *
   *
   * @param {Comment} comment
   * @param {string} commentId
   * @return {*}  {Promise<Comment>}
   * @memberof CommentService
   */
  updateComment(
    comment: Comment | Partial<Comment> | any,
    commentId: string
  ): Promise<Comment> {
    return new Promise<Comment>(async (resolve, reject) => {
      try {
        const updatedComment = await this.afsService.updateDocument<Comment>(
          commentId,
          COMMENT_COLLECTION_NAME,
          comment
        );
        return resolve(updatedComment);
      } catch (error) {
        return reject(error);
      }
    });
  }

  /**
   *
   *
   * @param {string} commentId
   * @return {*}  {Promise<void>}
   * @memberof CommentService
   */
  deleteComment(commentId: string): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await this.afsService.removeDocument<Comment>(
          commentId,
          COMMENT_COLLECTION_NAME
        );
        return resolve();
      } catch (error) {
        return reject(error);
      }
    });
  }

  /**
   * @private
   * @memberof SidePanelComponent
   */
  filterComments(comments: Comment[], project: Project, artifact: Artifact) {
    return (comments = comments
      .filter((comment) => {
        return (
          comment.projectId == project.id && comment.artifactId == artifact.id
        );
      })
      .sort((a, b) => {
        return (b.updatedAt as number) - (a.updatedAt as number);
      }));
  }
}
