<ng-template #modalComponent let-data let-ref="dialogRef">
    <div class="container">
        <div class="row">
            <div [ngClass]="{
                'dp-generic-modal-side-pane-defaults': true,
                'dp-generic-modal-side-pane': showSidePane,
                'dp-generic-modal-side-pane-hidden': !showSidePane
            }" *ngIf="showSidePane">
                <nb-card [size]="'giant'" class="dp-generic-modal-card m-0 p-0">
                    <nb-card-body class="dp-side-panel-body">
                        <ng-content select="[dialog-side-panel]"></ng-content>
                    </nb-card-body>
                </nb-card>
            </div>
            <div [ngClass]="{
                'col dp-generic-modal-defaults': true,
                'dp-generic-modal pr-0': showSidePane,
                'dp-generic-modal-full': !showSidePane,
            }">
                <nb-card [size]="''" class="dp-generic-modal-card m-0" >
                    <nb-card-header [ngClass]="{
                        'dp-generic-modal-card-header-adjust dp-generic-modal-card-header': true
                    }">
                        <div class="row">
                            <div class="col-auto my-auto pr-1" *ngIf="showBackBtn">
                                <button class="btn btn-clear glow  border-0" (click)="back($event)">
                                    <img src="assets/remix-icons/arrow-left-line.svg" alt="" width="18px" style="margin-bottom: 3px;">
                                </button>
                            </div>
                            <div class="col-auto my-auto">
                                <ng-content select="[dialog-date]"></ng-content>
                            </div>
                            <div class="col text-right my-auto" >
                                <button class="btn btn-clear  glow  border-0" [nbPopover]="templateRef" [nbPopoverPlacement]="nbPosition.START" *ngIf="showPopOverMenu">
                                    <i class="bi bi-three-dots"></i> 
                                </button>
                                <button class="btn btn-outline-primary m-3 border-0" (click)="onClickSaveBtn.emit()" *ngIf="showSaveBtn">
                                    <div class=" d-flex align-items-center justify-content-center">
                                        <!-- <i class="ri-save-2-line  mr-2"></i> -->
                                        {{!saveBtnText? 'Save': saveBtnText}}
                                    </div>
                                </button>
                              
                                <button class="btn btn-clear  glow dp-generic-modal-close-btn border-0" (click)="ref.close(); onCloseBtn.emit()">
                                    <img src="assets/remix-icons/close-line.svg" alt="" width="18px" width="18px" style="margin-bottom: 3px;">
                                </button>
                            </div>
                        </div>
                    </nb-card-header>
                    <nb-card-body>
                        <div class="row mt-4" *ngIf="showLabel">
                            <div class="col my-auto">
                                <h3>{{data?.item?.label}}</h3>
                            </div>
                            <div class="col-auto my-auto">
                            </div>
                        </div>
                        <ng-content select="[dialog-main-content-area]"></ng-content>
                    </nb-card-body>
                    <nb-card-footer *ngIf="showFooter">
                        <ng-content select="[dialog-footer-content-area]"></ng-content>
                    </nb-card-footer>
                </nb-card>
            </div>
        </div>
    </div>
</ng-template>

<!-- Popover: TODO: move to component -->
<ng-template #templateRef let-data="data">
    <div class="dp-custom-context-menu-container">
      <div class="dp-c-context-menu-option">
        <div class="d-flex" (click)="NewTab('portal/dashboard')">
            <div class="mr-2 my-auto">
                <img  src="assets/artifact-window-icons/open-link.svg" alt="open in new tab button" />
            </div>
            <p class=" my-auto text-white">open in new tab</p>
        </div>
      </div>
      <div class="dp-c-context-menu-option" >
        <div class="d-flex">
          <div class="mr-2 my-auto">
            <img  src="assets/artifact-window-icons/share.svg" alt="share button" />
        </div>
            <p class=" my-auto text-white">share</p>
        </div>
      </div>
      <div class="dp-c-context-menu-option" >
        <div class="d-flex">
            <div class="mr-2 my-auto">
              <img  src="assets/artifact-window-icons/folder-upload.svg" alt="move artifact button" />
        </div>
          <div class=" my-auto">move</div>
        </div>
      </div>
      <div class="dp-c-context-menu-option dp-role-delete" (click)="onClickDeleteBtn.emit()"    >
        <div class="d-flex ">
            <div class="mr-2 my-auto">
                <img  src="assets/artifact-window-icons/trash-alt.svg" alt="delete artifact button" />
            </div>
            <div class=" my-auto">Delete </div>
        </div>
      </div>
    </div>
  </ng-template>
  