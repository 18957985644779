<div class="w100 h100  col">
    <div class="row d-flex justify-content-center mb-4">
        <h1 >Welcome to Fima, {{usr.name}}</h1>
    </div>
    <div class="row d-flex justify-content-center mb-4">
        <div class="col-auto my-auto pr-1">
            <div
            >
              <i
                *ngIf="!user || (user && !user.photoURL)"
                class="ri-user-4-line dp-user"
              ></i>
              <img
                *ngIf="user && user.photoURL"
                [src]="user.photoURL"
                alt=""
                class="dp-profile-photo"
              />
            </div>
          </div>    </div>
    <div class="row d-flex justify-content-center mb-4">
        <p >
            free apps for you 
        </p>
    </div>
<div class="row d-flex justify-content-center mb-4 p-2">
        <div  class="m-2 d-flex flex-column align-items-center"  >
            <img
            class="bg-white p-2 rounded-lg shadow "
            src="assets/launchpad/sema.svg"
            alt=""
            width="54px"
            />  
            <p class="text-center" >Sema AI</p>
        </div>
        <div class="m-2 d-flex flex-column align-items-center"  >
            <img
            class="bg-white p-2 rounded-lg shadow "
            src="assets/launchpad/mail.svg"
            alt=""
            width="54px"
            />  
            <p class="text-center" >Mail</p>
        </div>
        <div  class="m-2 d-flex flex-column align-items-center" >
            <img
            class="bg-white p-2 rounded-lg shadow "
            src="assets/launchpad/folder-open.svg"
            alt=""
            width="54px"
            />  
            <p class="text-center" >Projects</p>
        </div>
        <div  class="m-2 d-flex flex-column align-items-center"  >
            <img
            class="bg-white p-2 rounded-lg shadow "
            src="assets/launchpad/video.svg"
            alt=""
            width="54px"
            />  
            <p class="text-center" >Call</p>
        </div>
        <div class="m-2 d-flex flex-column align-items-center" >
            <img
            class="bg-white p-2 rounded-lg shadow "
            src="assets/launchpad/message-alt-2.svg"
            alt=""
            width="54px"
            />  
            <p class="text-center" >Chat</p>
        </div>
        <div class="m-2 d-flex flex-column align-items-center" >
            <img
            class="bg-white p-2 rounded-lg shadow "
            src="assets/launchpad/document.svg"
            alt=""
            width="54px"
            />  
            <p class="text-center" >Docs</p>
        </div>
        <div class="m-2 d-flex flex-column align-items-center" >
            <img
            class="bg-white p-2 rounded-lg shadow "
            src="assets/launchpad/pallete.svg"
            alt=""
            width="54px"
            />  
            <p class="text-center" >Whiteboard</p>
        </div>
        
      
</div>
<div class="row d-flex justify-content-center ">
    <img src="assets/Landing-graphic.svg" class="landing"/>
</div>
</div>