import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { debounceTime, switchMap } from 'rxjs';
import { User } from 'src/app/@core/models/@fima/user.model';
import { CompanyService } from 'src/app/@core/services/@fima/company/company.service';
import { DataTransferService } from 'src/app/@core/services/@fima/data-transfer/data-transfer.service';
import { UtilsService } from 'src/app/@core/services/@fima/utils/utils.service';

@Component({
  selector: 'dp-invite-users',
  templateUrl: './invite-users.component.html',
  styleUrls: ['./invite-users.component.scss'],
})
export class InviteUsersComponent implements OnChanges {

  mailSendIcon = 'assets/remix-icons/user-4-line-white.svg';

  validationMessages = {
    searchInput: [
      {
        type: 'validateAsEmail',
        message: 'Please enter a valid email address',
      },
      {
        type: 'canNotInviteYourselfValidator',
        message: 'You are are already member of this team',
      },
    ],
  };

  @Input() inputCustomCssClass: boolean = false;

  @Input() showInviteEveryOne = false;

  @Input() showLabel = false;

  @Input() invitedTeamMembers: any = [];

  @Input() existingUsersAddedToProject = []

  @Output() invitedTeamMembersData: EventEmitter<any> = new EventEmitter<any[]>();

  @Output() onRemoveInvitedCoworkerEvent: EventEmitter<any> = new EventEmitter<any>();

  @Output() existingUsersAddedToProjectData: EventEmitter<any> = new EventEmitter<any[]>();

  matchingUsers: User[] = []

  usersSearchForm: FormGroup;

  matchingEmailAddress: string;

  checked: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    public dataTransferService: DataTransferService,
    private utilsService: UtilsService
  ) {
    const searchInput = new FormControl('', {
      validators: [],
      // asyncValidators: [this.canNotInviteYourselfValidator.bind(this)],
    });

    this.usersSearchForm = this.formBuilder.group({
      searchInput,
      inviteAllCompany: [false],
    });

    // Watch form input to trigger search results

    const DELAY_TIME_BEFORE_SEARCH = 100; // measured in milliseconds

    this.usersSearchForm
      .get('searchInput')
      ?.valueChanges.pipe(
        debounceTime(DELAY_TIME_BEFORE_SEARCH),
        switchMap((searchQuery) => {
          if (searchQuery.length > 2) {
            this.matchingUsers = []
            this.matchingEmailAddress = ''

            // Create a dummy form control with the email validator
            const emailControl = new FormControl(searchQuery, Validators.email);

            // Check if the emailControl is valid
            if (!emailControl.errors) {
              this.matchingUsers = []
              this.matchingEmailAddress = ''

              // check if any user in company has got this email address
              const matchingUsers = dataTransferService.$company.value.users.filter(user =>
                user.email == this.matchingEmailAddress
              );

              if (matchingUsers.length == 0) {
                this.matchingEmailAddress = searchQuery
              }


            } else {
              this.matchingUsers = []
              this.matchingEmailAddress = ''
            }
          } else {
            this.matchingUsers = []
            this.matchingEmailAddress = ''
          }
          return [];
        })
      )
      .subscribe()
  }

  ngOnChanges() {

  }

  onAddExistingUser(user: User) {

  }

  toggleAddAllCompanyUsers(checked: boolean) {
    this.checked = checked;
    this.usersSearchForm.get('inviteAllCompany')?.setValue(this.checked);
  }

  onAddEmailAddress(emailAddress: string) {

    this.usersSearchForm.get('searchInput')?.markAsTouched({ onlySelf: true });

    if (this.utilsService.validateForm(this.usersSearchForm).length > 0) {
      return;
    }

    const invitedUser: User | any = {
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime(),
      email: emailAddress,
      hasAcceptedInvitation: false,
      uid: null,
    }

    invitedUser['appEnvironmentUrl'] = window.location.origin;
    this.invitedTeamMembers.push(invitedUser)

    this.usersSearchForm.get('searchInput')?.setValue('')
    this.matchingUsers = []
    this.matchingEmailAddress = ''


    // finally output list of Invited email addresses

    this.invitedTeamMembersData.emit(this.invitedTeamMembers)
  }


  onRemoveInvitedCoworker(index: number, email: string) {
    this.invitedTeamMembers.splice(index, 1);
    this.usersSearchForm.get('searchInput')?.markAsTouched({ onlySelf: true });;
    this.usersSearchForm
      .get('searchInput')
      ?.updateValueAndValidity({ onlySelf: true });

    this.onRemoveInvitedCoworkerEvent.emit({
      parentArrIndex: index,
      email: email
    })
  }
}
