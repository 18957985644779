export interface ArtifactPropTree {
  type: Type | string;
  attributes: Attributes;
  children: Child[];
}

export interface Attributes {}

export interface Child {
  type: string;
  attributes: Attributes;
  children: Child[];
  text?: string;
}

export enum Type {
  // Basic HTML elements
  Td = 'TD',
  Text = '#text',
  Div = 'DIV',
  Span = 'SPAN',
  P = 'P',
  H1 = 'H1',
  H2 = 'H2',
  H3 = 'H3',
  H4 = 'H4',
  H5 = 'H5',
  H6 = 'H6',
  Ul = 'UL',
  Ol = 'OL',
  Li = 'LI',
  A = 'A',
  Img = 'IMG',
  Table = 'TABLE',
  Tr = 'TR',
  Th = 'TH',
  Blockquote = 'BLOCKQUOTE',
  Pre = 'PRE',
  Code = 'CODE',
  Figure = 'FIGURE',
  Figcaption = 'FIGCAPTION',
  Strong = 'STRONG',
  Em = 'EM',
  Br = 'BR',
  Hr = 'HR',
  // CKEditor 5 specific
  Mention = 'MENTION', // for the mention feature
  CodeBlock = 'CODEBLOCK', // for the code block feature
  MediaEmbed = 'MEDIAEMBED', // for the media embed feature
  // CKEditor 5 plugins
  Image = 'IMAGE', // for the image feature
  ImageCaption = 'IMAGECAPTION', // for the image caption feature
  ImageStyle = 'IMAGESTYLE', // for the image style feature
  ImageToolbar = 'IMAGETOOLBAR', // for the image toolbar feature
  ImageUpload = 'IMAGEUPLOAD', // for the image upload feature
  Link = 'LINK', // for the link feature
  List = 'LIST', // for the list feature
  Paragraph = 'PARAGRAPH', // for the paragraph feature
  PasteFromOffice = 'PASTEFROMOFFICE', // for the paste from office feature
  TableToolbar = 'TABLETOOLBAR', // for the table toolbar feature
  TextTransformation = 'TEXTTRANSFORMATION', // for the text transformation feature
  Title = 'TITLE', // for the title feature
  TodoList = 'TODOLIST', // for the todo list feature
  WordCount = 'WORDCOUNT', // for the word count feature
  // Additional plugins from CKEditor 5 v38.0.1
  Autoformat = 'AUTOFORMAT',
  Autosave = 'AUTOSAVE',
  BlockQuote = 'BLOCKQUOTE',
  Bold = 'BOLD',
  CKFinder = 'CKFINDER',
  CKFinderUploadAdapter = 'CKFINDERUPLOADADAPTER',
  ClassicEditor = 'CLASSICEDITOR',
  Clipboard = 'CLIPBOARD',
  CloudServices = 'CLOUDSERVICES',
  CloudServicesUploadAdapter = 'CLOUDSERVICESUPLOADADAPTER',
  EasyImage = 'EASYIMAGE',
  Essentials = 'ESSENTIALS',
  Font = 'FONT',
  Heading = 'HEADING',
  Highlight = 'HIGHLIGHT',
  HorizontalLine = 'HORIZONTALLINE',
  ImageResize = 'IMAGERESIZE',
  Indent = 'INDENT',
  Italic = 'ITALIC',
  LinkImage = 'LINKIMAGE',
  ListStyle = 'LISTSTYLE',
  MediaEmbedToolbar = 'MEDIAEMBEDTOOLBAR',
  ParagraphButtonUI = 'PARAGRAPHBUTTONUI',
  RealTimeCollaborativeEditing = 'REALTIMECOLLABORATIVEEDITING',
  RealTimeCollaborativeTrackChanges = 'REALTIMECOLLABORATIVETRACKCHANGES',
  RemoveFormat = 'REMOVEFORMAT',
  SpecialCharacters = 'SPECIALCHARACTERS',
  SpecialCharactersCurrency = 'SPECIALCHARACTERSCURRENCY',
  SpecialCharactersMathematical = 'SPECIALCHARACTERSMATHEMATICAL',
  Strikethrough = 'STRIKETHROUGH',
  Subscript = 'SUBSCRIPT',
  Superscript = 'SUPERSCRIPT',
  TableProperties = 'TABLEPROPERTIES',
  TableCellProperties = 'TABLECELLPROPERTIES',
  TextPartLanguage = 'TEXTPARTLANGUAGE',
  Underline = 'UNDERLINE',
}
