import {
  trigger,
  transition,
  style,
  animate,
  state,
} from '@angular/animations';
import {
  Component,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { DataTransferService } from 'src/app/@core/services/@fima/data-transfer/data-transfer.service';
import * as $ from 'jquery'
import {
  NbDialogService,
  NbMenuService,
  NbPosition,
  NbSearchService,
} from '@nebular/theme'
@Component({
  selector: 'app-artifact-window',
  templateUrl: './artifact-window.component.html',
  styleUrls: ['./artifact-window.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(200, style({ opacity: 1 })),
      ]),
      transition(':leave', [animate(200, style({ opacity: 0 }))]),
    ]),
    trigger('slideInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('100ms ease-in', style({ transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        animate('100ms ease-out', style({ transform: 'translateX(-100%)' })),
      ]),
    ]),
  ],
})
export class ArtifactWindowComponent implements OnChanges {
  @ViewChild('showDialogComponent', { static: true })
  showDialogComponent!: TemplateRef<any>;

  @Input() showLabel = false;
  @Input() showOptsPopOver = false;
  @Input() showSidePane = false;
  @Input() showSideBtn = true;
  @Input() showSaveBtn = true;
  @Input() showFooter = false;
  @Input() saveBtnText!: string;

  @Output() onClickSaveBtn: EventEmitter<any> = new EventEmitter<any>();
  @Output() onClickDeleteBtn: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCloseBtn: EventEmitter<any> = new EventEmitter<any>();
 
  nbPosition = NbPosition;
   NewTab(url:string) {
      // console.log($.data)
      window.open( 
        url, "_blank");
    } 
  constructor(public dataTransferService: DataTransferService) {
    this.dataTransferService.$closeArtefactWindow.subscribe({
      next: (data) => {
        if (data && data == true) {
          $('.dp-artifact-window-close-btn').click()
        }
      },
      complete: () => {

      },
      error: () => {

      }
    })
  }

  toggleSidePage(event?: any) {
    this.showSidePane = !this.showSidePane;

    !this.showSidePane
      ? this.dataTransferService.$artifactWindowSidePaneState.next('closed')
      : this.dataTransferService.$artifactWindowSidePaneState.next('open');
  }


  ngOnChanges(changes: SimpleChanges): void {
  }
}
