import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {NbCardModule, NbContextMenuModule, NbIconModule, NbLayoutModule, NbMenuModule, NbPopoverModule, NbSearchModule, NbSidebarModule, NbSidebarService } from '@nebular/theme';
import { ComponentModule } from '../components/component.module';
import { DashboardLayoutComponent } from './dashboard-layout/dashboard-layout.component';
import { DefaultLayoutComponent } from './default/default.component';
import { RouterModule } from '@angular/router';


export const layoutComponents = [
  DefaultLayoutComponent,
  DashboardLayoutComponent
]

@NgModule({
  declarations: layoutComponents,
  imports: [
    NbCardModule,
    NbIconModule,
    NbPopoverModule,
    NbSearchModule,
    NbContextMenuModule,
    NbMenuModule.forRoot(),
    ComponentModule,
    CommonModule,
    RouterModule,
    NbLayoutModule,
    NbSidebarModule
  ],
  providers: [
    NbSidebarService
  ],
  exports: layoutComponents
})
export class LayoutModule { }
