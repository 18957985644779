export const environment = {
    production: true,
    firebase: {
        apiKey: "AIzaSyDi7-MHkEE0k5yy7qOURgmWakcblR9CpBw",
        authDomain: "deprostack-staging.firebaseapp.com",
        projectId: "deprostack-staging",
        storageBucket: "deprostack-staging.appspot.com",
        messagingSenderId: "859561902613",
        appId: "1:859561902613:web:abe14cdf025cfd331a841c",
        measurementId: "G-G2VWRVSGYB",
    },
    cacheKeys: {
        userProfile: 'user:profile',
        inviteCompanyId: 'invite:company:id',
        authToken: '',
        refreshToken: ''
    },
    mixpanelToken: '69dc949c7f940839926fa2ee506d8bff',
    semaApiUrl: 'https://dev-api.fima.ai/sema/api/promptOpenAI',
    taigaApiUrl: 'https://dev-api.fima.ai/api/v1',
    artifactsProjectId: 8
}