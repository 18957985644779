<app-dashboard-layout
  [showMenuToggle]="canShowMenuToggle"
  [showSearchBar]="canShowSearchBar"
  [showSideMenu]="canShowSideMenu"
  [showRightSidePanel]="canShowRightSidePanel"
  [showLogo]="canShowLogo"
  (searchQuery)="handleSearchQuery($event)"
>
  <router-outlet></router-outlet>


  <!-- Temporarily cementing  out search feature until fully implemented -->
  
  <!-- <ng-template #SearchResults>
    <div class="container">
      <div class="row">
        <div class="col">
          <h4>Search Results</h4>
          <hr />
          <ng-container *ngIf="data.length > 0; else NoResults">
            <table class="table">
              <tbody>
                <tr
                  *ngFor="
                    let row of data
                      | paginate
                        : { itemsPerPage: pageSize, currentPage: currentPage }
                  "
                >
                  <td>
                    <h5>{{ row.projectName }}</h5>
                    <br />
                    {{ row.projectGoal }}
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-container>
          <ng-template #NoResults>
            <div class="alert alert-info">
              No results found.
            </div>
          </ng-template>
          <br />
          <div class="row">
            <div class="col-md-12 d-flex justify-content-center">
              <button
                class="btn btn-transparent text-primary"
                (click)="clearActionClicked()"
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template> -->
</app-dashboard-layout>
