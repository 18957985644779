import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Artifact } from 'src/app/@core/models/@fima/artifact.model';
import { Comment } from 'src/app/@core/models/@fima/comment.model';
import { Project } from 'src/app/@core/models/@fima/project.model';
import { User } from 'src/app/@core/models/@fima/user.model';
import { CommentService } from 'src/app/@core/services/@fima/comment/comment.service';
import { DataTransferService } from 'src/app/@core/services/@fima/data-transfer/data-transfer.service';
import { UtilsService } from 'src/app/@core/services/@fima/utils/utils.service';

@Component({
  selector: 'dp-comment-box',
  templateUrl: './comment-box.component.html',
  styleUrls: ['./comment-box.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class CommentBoxComponent implements OnInit {

  commentForm!: FormGroup;

  @Input() user!: User;

  @Input() project!: Project | undefined;

  @Input() artifact!: Artifact | undefined;

  @Output() onComment = new EventEmitter()

  addingComment = false

  constructor(
    private formBuilder: FormBuilder,
    private utils: UtilsService,
    private commentService: CommentService,
    private dataTransferService:DataTransferService
  ) {
    this.commentForm = this.formBuilder.group({
      comment: ['', Validators.required]
    })
  }

  ngOnInit(): void {}

  /**
   *
   *
   * @memberof CommentBoxComponent
   */
  async onSubmitComment() {

    if (this.utils.validateForm(this.commentForm).length > 0) {
      return
    }
    
    this.addingComment = true;

    const comment: Comment = {
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime(),
      commenterUserId: this.user.uid as string,
      commenterFullName: this.user.name as string,
      commenterCompanyId: this.user.companyId as string,
      projectId: this.project?.id as string,
      artifactId: this.artifact?.id as string,
      commentText: this.commentForm.get('comment')?.value,
      mentionedUsers: [], // TODO: implement mentions feature
      insertedEmoji: [],
      parentCommentId: null,
      replyComments: []
    };
    try {
      const createdComment = await this.commentService.createComment(comment);
      this.dataTransferService.$onfetchAppData.next(true)
      this.onComment.emit(createdComment)
      this.commentForm.reset()
      this.addingComment = false;
      return createdComment
    } catch (error) {
      this.addingComment = false;
      return error;
    }
  }

}
