<div class="backdrop">
    <div class="container-fluid">
        <div class="row dp-ao-close-container ">
            <div class="col-auto my-auto"></div>
            <div class="col my-auto text-right">
                <i class="ri-close-fill btn btn-clear dp-close-action" (click)="onCloseActionsOverLay()"></i>
            </div>
        </div>
    </div>

    <div class="container">
        <ng-content select="[actions-main-area]"></ng-content>
    </div>
</div>
