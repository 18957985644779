import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from 'firebase/auth';
import { map } from 'rxjs';
import { TaigaMemberShip } from 'src/app/@core/models/@taiga/membership.model';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class TaigaMembershipService {

    constructor(private http: HttpClient) { }

    createMembership(projectId: number, roleId: number, user: User): Promise<TaigaMemberShip> {
        return new Promise(async (resolve, reject) => {

            const membership = {
                "project": projectId,
                "role": roleId,
                "username": user.email
            }

            this.http.post<TaigaMemberShip>(
                `${environment.taigaApiUrl}/memberships`,
                membership
            ).pipe(map(async (membership) => {
                return resolve(membership)
            })).subscribe({
                next: (v) => { },
                error: (e) => {
                    return reject(e)
                },
                complete: () => { }
            })

        })
    }


    bulkCreateMemberships(projectId: number, roleId: number, users: User[]): Promise<TaigaMemberShip[]> {
        return new Promise(async (resolve, reject) => {

            const memberships = []

            for (let i = 0; i< users.length; i++) {
                const user = users[i]

                const membership = {
                    "project": projectId,
                    "role": roleId,
                    "username": user.email
                }
                memberships.push(membership)
            }

            this.http.post<TaigaMemberShip[]>(
                `${environment.taigaApiUrl}/memberships/bulk_create`,
                memberships
            ).pipe(map(async (membership) => {
                return resolve(membership)
            })).subscribe({
                next: (v) => { },
                error: (e) => {
                    return reject(e)
                },
                complete: () => { }
            })

        })
    }

}
