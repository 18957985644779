<app-loader *ngIf="loading"></app-loader>
<nb-layout [ngClass]="{ 'ai-window-open': isAIWindowOpen }">
  <nb-layout-header fixed class="dp-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-auto my-auto p-0">
          <div class="d-flex">
            <div class="">
              <img
                *ngIf="showLogo"
                src="assets/logo-hires.png"
                alt=""
                width="36px"
              />
              <span>(beta)</span>
              <img
                (click)="onLogoClicked()"
                *ngIf="showMenuToggle"
                [ngClass]="{
                  'dp-side-nav-toggle dp-apps-icon dp-clickable': true,
                  'shift-half-l': sideBarState == 'collapsed',
                  'shift-half-r': sideBarState != 'collapsed'
                }"
                src="assets/create-modal-icons/sidebar-right.svg"
                alt=""
              />
            </div>
          </div>
        </div>

        <div class="col my-auto">
          <!-- <div class="d-flex">
              <nb-search class="ml-3 " [tag]="'dp-global-search'" [type]="'rotate-layout'"
              [hint]="'Hit enter to search everywhere'" [placeholder]="'start typing...'"></nb-search>
            <span class="dp-search-text ml-4" nbButton (click)="openSearch()">
              <i class="ri-search-2-line mr-2"></i>
              Search
            </span>
            </div> -->
        </div>

        <div class="col-auto text-right my-auto">
          <div class="row">
            <div class="col-auto my-auto pr-1">
              <div
                [nbPopover]="templateRef"
                [nbPopoverPlacement]="nbPosition.END"
              >
                <i
                  *ngIf="!user || (user && !user.photoURL)"
                  class="ri-user-4-line dp-user"
                ></i>
                <img
                  *ngIf="user && user.photoURL"
                  [src]="user.photoURL"
                  alt=""
                  class="dp-profile-photo"
                />
              </div>
            </div>
            <div class="col my-auto p-0">
              <i class="ri-arrow-down-s-fill"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nb-layout-header>

  <nb-sidebar tag="left" *ngIf="showSideMenu" #leftSideBar>
    <app-side-menu-content></app-side-menu-content>
    <nb-sidebar-footer start class="dp-side-bar-footer">
      <div class="row dp-side-menu-footer-content">
        <div class="col-auto my-auto">
          <button class="btn btn-clear" (click)="goToSettings()">
            <i class="ri-settings-5-fill"></i>
          </button>
        </div>
      </div>
    </nb-sidebar-footer>
  </nb-sidebar>

  <nb-layout-column class="dp-portal-main-content-area">
    <div class="dp-container-fluid">
      <ng-content></ng-content>
    </div>
  </nb-layout-column>

  <nb-sidebar
    state="compacted"
    tag="right"
    right="true"
    *ngIf="showRightSidePanel"
  >
    <nb-menu
      [items]="rightSideMenuItems"
      tag="ai-toolbar"
      #rightSideMenu
      (onItemClick)="openAIWindow($event)"
    >
    </nb-menu>
  </nb-sidebar>
</nb-layout>

<ng-template #templateRef let-data="data">
  <div class="dp-custom-context-menu-container">
    <ng-container *ngFor="let contextMenuItem of items; index as i">
      <div
        [ngClass]="{
          'dp-c-context-menu-option': true,
          'dp-role-delete': i == items.length - 1
        }"
        (click)="onContextMenuItemClicked(contextMenuItem.title)"
      >
        {{ contextMenuItem?.title }}
      </div>
    </ng-container>
  </div>
</ng-template>

<app-artifact-window
  #dialogWindow
  [showLabel]="false"
  [showFooter]="true"
  [showSaveBtn]="false"
  [showOptsPopOver]="false"
  [showSidePane]="false"
  [saveBtnText]="'Save'"
  [showLabel]="false"
  [showSideBtn]="false"
  (onCloseBtn)="closeAIWindow()"
>
  <div dialog-side-panel></div>
  <p dialog-date class="m-0">
    Sema <span class="text-muted"><strong>(beta)</strong></span>
  </p>
  <div class="" dialog-main-content-area>
    <div class="row">
      <div class="col">
        <nb-card
          (click)="
            dataTransferService.$presetAiPrompt.next(
              'Update me on my teams tasks this week.'
            )
          "
        >
          <nb-card-body> Update me on my team's tasks this week. </nb-card-body>
        </nb-card>
      </div>
      <!-- <div class="col">
        <nb-card (click)="dataTransferService.$presetAiPrompt.next('Summarise the latest comments on One Project')">
          <nb-card-body>
            Summarise the latest comments on One Project
          </nb-card-body>
        </nb-card>
      </div> -->
      <div class="col">
        <nb-card
          (click)="
            dataTransferService.$presetAiPrompt.next(
              'Highlight the critical tasks for today.'
            )
          "
        >
          <nb-card-body> Highlight the critical tasks for today. </nb-card-body>
        </nb-card>
      </div>
    </div>

    <div class="row">
      <!-- <div class="col">
        <nb-card (click)="dataTransferService.$presetAiPrompt.next('how me tasks assigned to [team member].')">
          <nb-card-body>
            Show me tasks assigned to [team member].
          </nb-card-body>
        </nb-card>
      </div> -->
      <!-- <div class="col">
        <nb-card (click)="dataTransferService.$presetAiPrompt.next('What tasks are overdue in One project')">
          <nb-card-body>
            What tasks are overdue in One project
          </nb-card-body>
        </nb-card>
      </div> -->
      <!-- <div class="col">
        <nb-card (click)="dataTransferService.$presetAiPrompt.next('Create a stakeholder interview template')">
          <nb-card-body>
            Create a stakeholder interview template
          </nb-card-body>
        </nb-card>
      </div> -->
    </div>
  </div>
  <div class="" dialog-footer-content-area>
    <div class="row">
      <div class="col">
        <app-sema-ai></app-sema-ai>
      </div>
    </div>
  </div>
</app-artifact-window>
