import { Injectable } from '@angular/core';
import algoliasearch from 'algoliasearch/lite';

const searchClient = algoliasearch('11U3DZXAS7', '1a8ee4beee926a130f4ed903d4385f5d');
const index = searchClient.initIndex('Projects');

@Injectable({
  providedIn: 'root'
})
export class AlgoliaService {

  constructor() { }

  search(query: string) {
    return index.search(query);
  }
}
