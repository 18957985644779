import { Pipe, PipeTransform } from '@angular/core';
import { Comment } from '../models/@fima/comment.model';
import { CommentService } from '../services/@fima/comment/comment.service';

@Pipe({
  name: 'filterComments'
})
export class FilterCommentsPipe implements PipeTransform {

  constructor(
    private commentService: CommentService
  ) {}

  transform(comments: Comment[], ...args: any[]): Comment[] {

    const project = args[0];
    const artifact = args[1];

    if (!comments || !project || !artifact) {
      return
    }

    return this.commentService.filterComments(comments, project, artifact)

  }

}
