import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-go-to-desktop',
  template: `
    <style>
    body {
        background: #dedede;
    }
    .page-wrap {
        min-height: 80vh;
    }
  </style>
  <div class="page-wrap d-flex flex-row align-items-center">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12 text-center">
                <img src="assets/logo-hires.png" alt="" />
                <div class="my-4 lead">Please use the site on desktop. We're busy preparing a mobile experience.</div>
            </div>
        </div>
    </div>
  </div>
  `,
  styles: []
})
export class GoToDesktopComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
