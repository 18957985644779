import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import {
  NbDialogService,
  NbIconLibraries,
  NbMenuComponent,
  NbTrigger,
  NbMenuItem,
  NbTooltipModule ,
  NbMenuService,
  NbPosition,
  NbSearchService,
  NbSidebarComponent,
  NbSidebarService,
} from '@nebular/theme';
import firebase from 'firebase/compat';
import { map } from 'rxjs';
import { User } from 'src/app/@core/models/@fima/user.model';
import { FirebaseAuthService } from 'src/app/@core/services/@fima/auth/auth.service';
import { ProfileService } from 'src/app/@core/services/@fima/profile/profile.service';
import { ArtifactWindowComponent } from '../../components/artifact-window/artifact-window.component';
import { DataTransferService } from 'src/app/@core/services/@fima/data-transfer/data-transfer.service';


@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,

})
export class DashboardLayoutComponent {
  @ViewChild('dialogWindow', { static: true }) dialogWindow!: ArtifactWindowComponent;

  @ViewChild('rightSideMenu', { static: true }) rightSideMenu!: NbMenuComponent;

  @ViewChild('leftSidebar', {static: true}) leftSidebar: NbSidebarComponent
  
  @Input() showSearchBar = false;

  @Input() showSideMenu = false;

  @Input() showMenuToggle = false;

  @Input() showRightSidePanel = false;

  @Input() showLogo = false;

  @Output() searchQuery = new EventEmitter<any>();

  /**
   *
   *
   * @memberof DashboardLayoutComponent
   */
  items = [{ title: '' }, { title: 'Logout' }];

  /**
   *
   *
   * @memberof DashboardLayoutComponent
   */
  loading = false;

  /**
   *
   *
   * @type {User}
   * @memberof DashboardLayoutComponent
   */
  user!: firebase.User;

  /**
   *
   *
   * @memberof DashboardLayoutComponent
   */
  sideBarState = 'expanded'

  /**
   *
   *
   * @memberof DashboardLayoutComponent
   */
  nbPosition = NbPosition;

  rightSideMenuItems: NbMenuItem[] = [];
  nbTrigger = NbTrigger;

  today = new Date()

  isAIWindowOpen = false;


  constructor(
    public profileService: ProfileService,
    public autService: FirebaseAuthService,
    public sidebarService: NbSidebarService,
    private nbMenuService: NbMenuService,
    private authService: FirebaseAuthService,
    private router: Router,
    private searchService: NbSearchService,
    private dialogService: NbDialogService,
    private menuService: NbMenuService,
    private iconLibraries: NbIconLibraries,
    public dataTransferService: DataTransferService
  ) {
    this.searchService.onSearchSubmit().subscribe((data) => {
      this.searchQuery.emit(data);
    })



    const rightMenu = this.menuService.onItemClick().subscribe((click) => {
      console.log(click)

      this.openAIWindow(click)
    })


    this.iconLibraries.registerSvgPack('custom-icons', {
      'fima-ai-assistant':
        `
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_4867_2084)">
        <circle cx="25" cy="22" r="18" fill="white"/>
        <circle cx="25" cy="22" r="17.5" stroke="#E0E0E0"/>
        </g>
        <path d="M19.9788 12.6815C19.6426 11.7728 18.3574 11.7728 18.0212 12.6815L17.5558 13.9392C17.4501 14.2249 17.2249 14.4501 16.9392 14.5558L15.6815 15.0212C14.7728 15.3574 14.7728 16.6426 15.6815 16.9788L16.9392 17.4442C17.2249 17.5499 17.4501 17.7751 17.5558 18.0608L18.0212 19.3185C18.3574 20.2272 19.6426 20.2272 19.9788 19.3185L20.4442 18.0608C20.5499 17.7751 20.7751 17.5499 21.0608 17.4442L22.3185 16.9788C23.2272 16.6426 23.2272 15.3574 22.3185 15.0212L21.0608 14.5558C20.7751 14.4501 20.5499 14.2249 20.4442 13.9392L19.9788 12.6815Z" fill="url(#paint0_linear_4867_2084)"/>
        <path d="M28.3352 18.2777C27.7048 16.5741 25.2952 16.5741 24.6648 18.2777L23.7921 20.636C23.5939 21.1716 23.1716 21.5939 22.636 21.7921L20.2777 22.6648C18.5741 23.2952 18.5741 25.7048 20.2777 26.3352L22.636 27.2079C23.1716 27.4061 23.5939 27.8284 23.7921 28.364L24.6648 30.7223C25.2952 32.4259 27.7048 32.4259 28.3352 30.7223L29.2079 28.364C29.4061 27.8284 29.8284 27.4061 30.364 27.2079L32.7223 26.3352C34.4259 25.7048 34.4259 23.2952 32.7223 22.6648L30.364 21.7921C29.8284 21.5939 29.4061 21.1716 29.2079 20.636L28.3352 18.2777Z" fill="url(#paint1_linear_4867_2084)"/>
        <defs>
        <filter id="filter0_d_4867_2084" x="0.6" y="0.8" width="48.8" height="48.8" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="3.2"/>
        <feGaussianBlur stdDeviation="3.2"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4867_2084"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4867_2084" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_4867_2084" x1="31.2175" y1="29.0711" x2="14.8565" y2="18.7331" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FE871A"/>
        <stop offset="0.901042" stop-color="#A660FF"/>
        <stop offset="1" stop-color="#FE871A" stop-opacity="0.46"/>
        </linearGradient>
        <linearGradient id="paint1_linear_4867_2084" x1="31.2175" y1="29.0711" x2="14.8565" y2="18.7331" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FE871A"/>
        <stop offset="0.901042" stop-color="#A660FF"/>
        <stop offset="1" stop-color="#FE871A" stop-opacity="0.46"/>
        </linearGradient>
        </defs>
        </svg>
    `,
      'fima-video-chat':

        `
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_4867_2090)">
        <circle cx="25" cy="22" r="18" fill="white"/>
        <circle cx="25" cy="22" r="17.5" stroke="#E0E0E0"/>
        </g>
        <path d="M20 16H27.5C29.433 16 31 17.567 31 19.5L32.7929 17.7071C33.4229 17.0771 34.5 17.5233 34.5 18.4142V25.5858C34.5 26.4767 33.4229 26.9229 32.7929 26.2929L31 24.5C31 26.433 29.433 28 27.5 28H20C17.7909 28 16 26.2091 16 24V20C16 17.7909 17.7909 16 20 16Z" fill="url(#paint0_linear_4867_2090)"/>
        <defs>
        <filter id="filter0_d_4867_2090" x="0.6" y="0.8" width="48.8" height="48.8" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="3.2"/>
        <feGaussianBlur stdDeviation="3.2"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4867_2090"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4867_2090" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_4867_2090" x1="31.7907" y1="26.2426" x2="20.9248" y2="15.1008" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FE871A"/>
        <stop offset="0.901042" stop-color="#A660FF"/>
        <stop offset="1" stop-color="#2F8AE4"/>
        <stop offset="1" stop-color="#FE871A" stop-opacity="0.46"/>
        </linearGradient>
        </defs>
        </svg>

    `,
    'fima-text-chat': 
    `
    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_4867_2094)">
    <circle cx="25" cy="22" r="18" fill="white"/>
    <circle cx="25" cy="22" r="17.5" stroke="#E0E0E0"/>
    </g>
    <path d="M17.661 23.7361L16.0779 27.5793C15.8163 28.2141 16.2423 28.9211 16.9265 28.9875L20.8234 29.3659L22.683 30.016C28.2139 31.9495 34 27.8493 34 21.9965V21.6596C34 17.1532 30.3424 13.5 25.8305 13.5C21.3186 13.5 17.661 17.1532 17.661 21.6596V23.7361Z" fill="url(#paint0_linear_4867_2094)"/>
    <defs>
    <filter id="filter0_d_4867_2094" x="0.6" y="0.8" width="48.8" height="48.8" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="3.2"/>
    <feGaussianBlur stdDeviation="3.2"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4867_2094"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4867_2094" result="shape"/>
    </filter>
    <linearGradient id="paint0_linear_4867_2094" x1="31.364" y1="28.0104" x2="16.866" y2="17.8003" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FE871A"/>
    <stop offset="0.901042" stop-color="#A660FF"/>
    <stop offset="1" stop-color="#2F8AE4"/>
    <stop offset="1" stop-color="#FE871A" stop-opacity="0.46"/>
    </linearGradient>
    </defs>
    </svg>
    `
    });
    this.iconLibraries.setDefaultPack('custom-icons');

    this.rightSideMenuItems = [
      {
        title: '',
        icon: 'fima-ai-assistant',

      },
      {
        title: '',
        icon: 'fima-video-chat',
      },
      {
        title: '',
        icon: 'fima-text-chat',
      }
    ]
  }


  openAIWindow(event) {
    this.isAIWindowOpen = true;
    if (event.item.icon == 'fima-ai-assistant') {
      const dialogRef = this.dialogService.open(
        this.dialogWindow.showDialogComponent,
        {
          context: {
          },
          hasScroll: false,
          hasBackdrop: true,
          closeOnEsc: true,
          closeOnBackdropClick: false,
        }
      );

      dialogRef.onBackdropClick.subscribe((data) => {
        if (data) {

          console.log('MOUSE_EVENT', data)
          this.closeAIWindow()
          dialogRef.close()
        }
      })
    }
  }

  closeAIWindow() {
    this.isAIWindowOpen = false;
  }

  /**
   *
   *
   * @return {*}
   * @memberof DashboardLayoutComponent
   */
  async toggle() {
    this.sidebarService.toggle(false, 'left');
    this.sideBarState === 'expanded' ? this.sideBarState = 'collapsed' : this.sideBarState = 'expanded';
    return false;
  }

  /**
   *
   *
   * @memberof DashboardLayoutComponent
   */
  async ngOnInit() {
    this.user = await this.authService.auth.currentUser
    this.items[0].title = `Signed in as  ${this.user?.displayName}`;


    this.nbMenuService
      .onItemClick()
      .pipe(map(({ item: { title } }) => title))
      .subscribe(async (title) => {
        this.onContextMenuItemClicked(title)
      });
  }


  /**
   *
   *
   * @memberof DashboardLayoutComponent
   */
  openSearch() {
    this.searchService.activateSearch('rotate-layout', 'dp-global-search');
  }

  /**
   *
   *
   * @memberof DashboardLayoutComponent
   */
  onLogoClicked() {
    this.toggle()
    // this.router.navigate(['/portal'])
  }


  /**
   *
   *
   * @param {string} title
   * @memberof DashboardLayoutComponent
   */
  async onContextMenuItemClicked(title: string) {
    if (title == 'Logout') {
      this.loading = true;

      try {
        await this.authService.signOut();
        this.router.navigate(['start'], { replaceUrl: true });
      } catch (error) {
        console.log('DashboardLayoutComponent >> signOut >> error ', error);
      }

      this.loading = true;
    }
  }

  goToSettings() {
    this.router.navigate(['portal/settings'], {replaceUrl: true})
  }



}
