import { Injectable } from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';


@Injectable({
  providedIn: 'root'
})

export class CacheService {

  constructor(
    private storage: SessionStorageService
  ) { }


  storeItem(key: string, value: any) {
    this.storage.store(key,value)
  }

  retrieveItem(key: string) {
    return this.storage.retrieve(key)
  }

  clear(){
    this.storage.clear()
  }

}
