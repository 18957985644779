import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {
  NbCardModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NbDialogModule,
  NbFormFieldModule,
  NbInputModule,
  NbPopoverModule,
  NbSelectModule,
  NbTagModule,
  NbThemeModule,
  NbToastrModule,
  NbTooltipModule,
  NbUserModule,
  NbTabsetModule,
  NbStepperModule,
  NbButtonModule,
  NbAutocompleteModule,
  NbToggleModule,
} from '@nebular/theme';
import { DashboardComponent } from './@fima/dashboard/dashboard.component';
import { ProjectComponent } from './@fima/project/project.component';
import { ComponentModule } from 'src/app/@theme/components/component.module';
import { LayoutModule } from 'src/app/@theme/layouts/layout.module';
import { PortalComponent } from './portal.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { CreateComponent } from './@fima/create/create.component';
import { ProjectSettingsComponent } from './@fima/project-settings/project_settings.component';
import { HttpClientModule } from '@angular/common/http';
import { SettingsComponent } from './@fima/settings/settings.component';
import { DesignComponent } from './@fima/design/design.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { OnboardingComponent } from './@fima/onboarding/onboarding.component';
import { PipeModule } from 'src/app/@core/pipes/pipe.module';
import { InviteResolvedComponent } from './@fima/invite/invite.component';
import { TruncateModule } from 'ng2-truncate';


export const portalRoutingComponents = [
  PortalComponent,
  DashboardComponent,
  ProjectComponent,
  CreateComponent,
  SettingsComponent,
  DesignComponent,
  ProjectSettingsComponent,
  OnboardingComponent,
  InviteResolvedComponent
]

@NgModule({
  declarations: portalRoutingComponents,
  imports: [
    // # Start: Custom modules #//
    PipeModule,
    ComponentModule,
    LayoutModule,
    // # End: User Custom modules #//

    // # Start: System modules #//
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    HttpClientModule,
    // # End: System modules #//

    // # Start: Vendor modules #//
    TruncateModule,
    NbToggleModule,
    NbAutocompleteModule,
    NbButtonModule,
    NbStepperModule,
    NbTagModule,
    NbFormFieldModule,
    NgxSkeletonLoaderModule.forRoot({
      theme: {
        // Enabliong theme combination
        extendsFromRoot: true,
        // ... list of CSS theme attributes
        height: '30px',
      },
    }),
    NbToastrModule.forRoot({}),
    NbSelectModule,
    NbPopoverModule,
    NbContextMenuModule,
    NbUserModule,
    NbTooltipModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbEvaIconsModule,
    NbInputModule,
    NbCheckboxModule,
    NbCardModule,
    NbTabsetModule,
    NbDialogModule.forRoot({
      hasBackdrop: true,
      closeOnBackdropClick: true,
      closeOnEsc: true,
    })
    // # End: Vendor modules #//

  ],
  exports: portalRoutingComponents
})
export class PortalPagesModule { }
