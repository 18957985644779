<div class="container-fluid dp-container-fluid">
  <div class="row row-top">
    <div class="col m-0 pl-0">
      <p class="m-0">Apps</p>
    </div>
  </div>

  <div class="row">
    <div
      class="col ri-icon-col"
      nbTooltip="Research"
      nbTooltipStatus="primary"
      [nbTooltipTrigger]="nbTrigger.HINT"
      [nbTooltipPlacement]="'top'"
    >
      <img
        class="dp-apps-icon"
        src="assets/create-modal-icons/users.svg"
        alt=""
      />
    </div>
    <div
      class="col ri-icon-col"
      nbTooltip="Design"
      nbTooltipStatus="primary"
      [nbTooltipTrigger]="nbTrigger.HINT"
      [nbTooltipPlacement]="'top'"
    >
      <img
        class="dp-apps-icon"
        src="assets/create-modal-icons/pen-tool.svg"
        alt=""
      />
    </div>
    <div
      class="col ri-icon-col"
      nbTooltip="Documentation"
      nbTooltipStatus="primary"
      [nbTooltipTrigger]="nbTrigger.HINT"
      [nbTooltipPlacement]="'top'"
    >
      <img
        class="dp-apps-icon"
        src="assets/create-modal-icons/document.svg"
        alt=""
      />
    </div>
    <div
      class="col ri-icon-col"
      nbTooltip="Insights"
      nbTooltipStatus="primary"
      [nbTooltipTrigger]="nbTrigger.HINT"
      [nbTooltipPlacement]="'top'"
    >
      <img
        class="dp-apps-icon"
        src="assets/create-modal-icons/chart-up.svg"
        alt=""
      />
    </div>
  </div>

  <div
    class="row dp-home-btn-row dp-clickable dash-step1"
    [routerLink]="['/portal']"
  >
    <div class="col-auto m-0 pl-0 my-auto">
      <img
      src="assets/create-modal-icons/home-4.svg"
      alt=""
      width="18px"
      style="margin-bottom: 3px"
    />    </div>
    <div class="col-auto m-0 pl-0 my-auto">
      <p class="m-0">Home</p>
    </div>
  </div>

  <div class="dash-step3">
    <div class="row">
      <div class="col m-0 pl-0">
        <p class="m-0">Folders</p>
      </div>
    </div>

    <ng-container *ngIf="projects && (dataTransferService.$projects | async).length > 0; else noProjects">
      <div
        class="row dp-clickable dp-project-container"
        *ngFor="let project of (dataTransferService.$projects | async)"
        [routerLink]="['/portal/project']"
        [queryParams]="{ id: project?.id }"
      >
        <div class="col-auto m-0 pl-0 my-auto">
            <img  src="assets/create-modal-icons/folder.svg" alt="Project image" />
        </div>
        <div class="col-auto m-0 pl-0 my-auto">
          <p class="m-0">{{ project.projectName | truncate : 12 : "..." }}</p>
        </div>

        <div class="col text-right m-0 pl-0 my-auto">
          <p class="m-0 text-muted">{{ project?.projectArtifacts?.length }}</p>
        </div>
      </div>
    </ng-container>

    <ng-template #noProjects>
      <div class="row dp-project-container">
        <div class="col-auto m-0 pl-0 my-auto text-center d-flex flex-column align-items-center">
          <span class="text-muted">You have not yet created any projects.</span>
        </div>
      </div>
    </ng-template>
    
  </div>
</div>
