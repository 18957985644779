import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParseArtifactForProjectPipe } from './parse-artifact-for-project.pipe';
import { ResolveUserIdPipe } from './resolve-user-id.pipe';
import { FilterCommentsPipe } from './filter-comments.pipe';
import { ResolveTaigaUserIdPipe } from './resolve-taiga-user.pipe';

const pipes = [
  ParseArtifactForProjectPipe,
  ResolveUserIdPipe,
  FilterCommentsPipe,
  ResolveTaigaUserIdPipe
]

@NgModule({
  declarations: [
    pipes
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    pipes
  ]
})
export class PipeModule { }
