import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import {
  ArtifactPropTree,
  Child,
  Type,
} from 'src/app/@core/models/@fima/prop.model';
import { DataTransferService } from 'src/app/@core/services/@fima/data-transfer/data-transfer.service';

const CK_EDITOR_BUILD_SRC =
  '../../../../assets/ckeditor5-38.0.1-5jo3wrnrnd8h/build/ckeditor.js';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
})
export class EditorComponent implements AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('editor', { static: true }) editorSelector!: ElementRef<any>;

  @Input() jsonData!: ArtifactPropTree | undefined;
  @Output() ckEditorData: EventEmitter<any> = new EventEmitter();

  editor!: any;
  observer!: IntersectionObserver;
  sidePaneStateObserver!: Subscription;

  toolBarInViewPort = true;

  constructor(
    private dataTransferService: DataTransferService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: any
  ) {
    this.dataTransferService.$jsonData.subscribe({
      next: (data) => {
        if (data) {
          this.jsonData = data;

          if (this.editor) {
            // Pre-populate the editor with data
            const jsonData = this.jsonData;
            // const htmlData = this.jsonToHtml(jsonData);
            this.editor.setData(jsonData);
            this.observeScrollEvents();
          }
        }

        if (data == undefined || data == null) {
          if (this.editor) {
            this.editor.setData('');
            this.observeScrollEvents();
          }
        }
      },
      error: (error) => {
        console.log(
          'EditorComponent >> DataTransferService >> subscribe >> jsonData >> error',
          error
        );
      },
      complete: () => console.info('complete'),
    });
  }

  ngAfterViewInit(): void {
    this.loadCkEditor();
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> { }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }

    if (this.sidePaneStateObserver) {
      this.sidePaneStateObserver.unsubscribe();
    }
  }

  /**
   *
   *
   * @private
   * @memberof EditorComponent
   */
  private observeScrollEvents() {
    if (!this.observer) {
      setTimeout(() => {
        this.initIntersectionObserver();
      }, 500);
    }
  }

  /**
   *
   *
   * @private
   * @memberof EditorComponent
   */
  private initIntersectionObserver(): void {
    const toolbar = $('.ck-editor__top');
    const nativeToolbarElement = toolbar[0]; // or toolbar.get(0);

    const options = {
      root: null, // viewport
      rootMargin: '0px',
      threshold: 0, // trigger the callback as soon as the target starts exiting the viewport
    };

    this.observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        this.sidePaneStateObserver =
          this.dataTransferService.$artifactWindowSidePaneState.subscribe({
            next: (data) => {
              if (data) {
                this.toggleFloatingToolBar(entry, data);
              }
            },
            error: (error) => {
              console.log(
                'EditorComponent >> DataTransferService >> subscribe >> toggleFloatingToolBar >> error',
                error
              );
            },
            complete: () => console.info('complete'),
          });
      });
    }, options);

    if (nativeToolbarElement) {
      this.observer.observe(nativeToolbarElement);
    }
  }

  private toggleFloatingToolBar(
    entry: IntersectionObserverEntry,
    data: string
  ) {
    if (!entry.isIntersecting) {
      // The toolbar is not in the viewport, make the CKEditor toolbar floating
      this.toolBarInViewPort = false;
      this.dataTransferService.$toolBarInViewPort.next(false);
      $('.ck-sticky-panel__placeholder').css({
        display: 'block',
        height: '38.4609px',
      });
      $('.ck-sticky-panel__content')
        .addClass('ck-sticky-panel__content_sticky')
        .css({
          width: data == 'closed' ? '93.4vw' : '74.4vw',
          'margin-left': '0px',
        });
    } else {
      this.toolBarInViewPort = true;
      this.dataTransferService.$toolBarInViewPort.next(true);
      // The toolbar is in the viewport, remove the floating style
      $('.ck-sticky-panel__placeholder').css({
        display: '',
        height: '',
      });

      $('.ck-sticky-panel__content')
        .removeClass('ck-sticky-panel__content_sticky')
        .css({
          width: '',
          'margin-left': '',
        });
    }
  }

  /**
   * Loads and initializes the CKEditor.
   * This function dynamically loads the CKEditor script and initializes the editor with a configuration.
   * It also pre-populates the editor with data and listens for changes in the editor content.
   */
  loadCkEditor() {
    // Check if CKEditor is already loaded
    if ((window as any).ClassicEditor) {
      this.initCkEditor((window as any).ClassicEditor);
      return;
    }

    const script = this._renderer2.createElement('script');
    script.type = 'application/javascript';
    script.src = CK_EDITOR_BUILD_SRC;

    script.text = `
      ${(script.onload = async () => {
        const CKEditor = (window as any).ClassicEditor;
        await this.initCkEditor(CKEditor);
      })}
    `;

    this._renderer2.appendChild(this._document.body, script);
  }

  /**
   *
   *
   * @private
   * @param {*} CKEditor
   * @memberof EditorComponent
   */
  private async initCkEditor(CKEditor: any) {
    const plugins = CKEditor.builtinPlugins.filter(
      (plugin: any) => plugin.pluginName !== 'Title'
    );

    const editor = await CKEditor.create(this.editorSelector.nativeElement, {
      plugins: plugins ? plugins : [],
      link: {
        // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
        addTargetToExternalLinks: true,

        // Let the users control the "download" attribute of each link.
        decorators: [
          {
            mode: 'manual',
            label: 'Downloadable',
            attributes: {
              download: 'download',
            },
          },
        ],
      },
      extraPlugins: [
        function (editor: any) {
          // Allow <iframe> elements in the model.
          editor.model.schema.register('iframe', {
            allowWhere: '$block',
            allowContentOf: '$block',
          });

          editor.config.allowedContent = {
            iframe: {
              attributes: true,
              classes: true,
              styles: true
            }
          };

          editor.config.extraAllowedContent = 'iframe[width,height,src,style,sandbox]';

          // Allow <iframe> elements in the model to have all attributes.
          editor.model.schema.addAttributeCheck((context: string) => {
            if (context.endsWith('iframe')) {
              return true;
            }
          });
          // View-to-model converter converting a view <iframe> with all its attributes to the model.
          editor.conversion.for('upcast').elementToElement({
            view: 'iframe',
            model: (
              viewElement: { getAttributes: () => any },
              modelWriter: any
            ) => {
              return modelWriter.writer.createElement(
                'iframe',
                viewElement.getAttributes()
              );
            },
          });

          // Model-to-view converter for the <iframe> element (attributes are converted separately).
          editor.conversion.for('downcast').elementToElement({
            model: 'iframe',
            view: 'iframe',
          });

          // Model-to-view converter for <iframe> attributes.
          // Note that a lower-level, event-based API is used here.
          editor.conversion
            .for('downcast')
            .add(
              (dispatcher: {
                on: (
                  arg0: string,
                  arg1: (evt: any, data: any, conversionApi: any) => void
                ) => void;
              }) => {
                dispatcher.on(
                  'attribute',
                  (
                    evt: any,
                    data: {
                      item: { name: string };
                      attributeNewValue: any;
                      attributeKey: any;
                    },
                    conversionApi: {
                      writer: any;
                      mapper: { toViewElement: (arg0: any) => any };
                    }
                  ) => {
                    // Convert <iframe> attributes only.
                    if (data.item.name != 'iframe') {
                      return;
                    }

                    const viewWriter = conversionApi.writer;
                    const viewIframe = conversionApi.mapper.toViewElement(
                      data.item
                    );

                    // In the model-to-view conversion we convert changes.
                    // An attribute can be added or removed or changed.
                    // The below code handles all 3 cases.
                    if (data.attributeNewValue) {
                      viewWriter.setAttribute(
                        data.attributeKey,
                        data.attributeNewValue,
                        viewIframe
                      );
                    } else {
                      viewWriter.removeAttribute(data.attributeKey, viewIframe);
                    }
                  }
                );
              }
            );
        },
      ],
    });
    // Pre-populate the editor with data
    if (this.jsonData) {
      const jsonData = this.jsonData;
      // const htmlData = this.jsonToHtml(jsonData);
      editor.setData(jsonData);
    }

    editor.model.document.on('change', () => {
      const htmlData = editor.getData();
      const ckEditorData = this.htmlToJson(htmlData);
      console.log('EditorComponent >> onChange >> ckEditorData >> ', ckEditorData)
      this.ckEditorData.emit(ckEditorData);
    });

    this.editor = editor;
  }

  /**
   * Converts HTML content to a JSON object representation.
   *
   * @param {string} html - The HTML content to convert.
   * @returns {any} The JSON object representing the HTML content. The structure of the JSON object reflects the hierarchical structure of the HTML elements.
   *                Each element in the JSON object has a "type" property representing the element tag name,
   *                "attributes" property containing the element's attributes, "children" property containing its child elements,
   *                and "text" property containing the text content if the element is a text node.
   */
  private htmlToJson(html: string): any {
    // const parser = new DOMParser();
    // const doc = parser.parseFromString(html, 'text/html');
    // return this.elementToJson(doc.body);
    return html;
  }
  /**
   * Converts a DOM element to its JSON representation.
   *
   * @param {Node} element - The DOM element to convert.
   * @returns {any} The JSON object representing the DOM element. The structure of the JSON object is similar to the result of `htmlToJson()`.
   *                Each element in the JSON object has a "type" property representing the element tag name,
   *                "attributes" property containing the element's attributes, "children" property containing its child elements,
   *                and "text" property containing the text content if the element is a text node.
   */
  private elementToJson(element: Node): any {
    const json: any = {
      type: element.nodeName,
      attributes: {},
      children: [],
    };

    if (element.nodeType === Node.TEXT_NODE) {
      json.text = element.textContent;
    } else if (element.nodeType === Node.ELEMENT_NODE) {
      const elem = element as Element;
      for (let i = 0; i < elem.attributes.length; i++) {
        const attr = elem.attributes[i];
        json.attributes[attr.name] = attr.value;
      }
      for (let i = 0; i < elem.childNodes.length; i++) {
        const child = elem.childNodes[i];
        json.children.push(this.elementToJson(child));
      }

      // Specifically handle iframe elements
      if (element.nodeName === 'IFRAME') {
        json.src = (element as HTMLIFrameElement).src;
      }
    }

    return json;
}


  /**
   * Converts a JSON object representation of HTML content to an HTML string.
   *
   * @param {(ArtifactPropTree | Child)} json - The JSON object representing the HTML content.
   * @returns {string} The HTML string generated from the JSON object. It represents the original HTML structure and content.
   */
  private jsonToHtml(json: ArtifactPropTree | Child): string {
    let html = '';
    if (json.type === Type.Text) {
      html += (json as Child).text || ''; // directly return the text property
    } else {
      html += `<${json.type}`;
      for (const attr in json.attributes) {
        html += ` ${attr}="${(json.attributes as any)[attr]}"`;
      }
      html += '>';
      for (const child of json.children) {
        html += this.jsonToHtml(child);
      }
      html += `</${json.type}>`;
    }
    return html;
  }
}
