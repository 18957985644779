import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Company } from '../../../models/@fima/company.model';
import { User } from '../../../models/@fima/user.model';
import { AfsService } from '../afs/afs.service';
import { DataTransferService } from '../data-transfer/data-transfer.service';
import { UtilsService } from '../utils/utils.service';

const COMPANY_COLLECTION_NAME = 'Companies';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(
    private afsService: AfsService,
    private dataTransferService: DataTransferService,
    private utils: UtilsService
  ) {}

  /**
   *
   *
   * @param {Company} company
   * @return {*}
   * @memberof CompanyService
   */
  createCompany(company: Company): Promise<Company> {
    return new Promise<Company>(async (resolve, reject) => {
      try {
        const createdCompany = await this.afsService.addDocument<Company>(
          company,
          COMPANY_COLLECTION_NAME
        );
        return resolve(createdCompany as Company);
      } catch (error) {
        return reject(error);
      }
    });
  }

  /**
   * Fetches the company the logged in user belongs to
   *
   * @param {string} companyId
   * @return {*}
   * @memberof CompanyService
   */
  getCompany(companyId: string): Promise<Company> {
    return new Promise<Company>(async (resolve, reject) => {
      try {
        const company = await this.afsService.findDocumentById<Company>(
          companyId,
          COMPANY_COLLECTION_NAME
        );

        // Update the company.users array with full user documents

        company.users?.forEach(
          async (userObjOrId: User | string, index: number) => {
            // only check for users that only have a userId property, check if element is an object instead of just a string
            const { isObject, type } = this.utils.isObject(userObjOrId);

            if (!isObject && type == 'string') {
              // this means that the users has not been mutated yet

              const queryRes = await this.afsService.findDocuments<User>(
                'uid',
                '==',
                userObjOrId as string,
                'Users',
                1
              );
              const user: User = queryRes.shift() as User;
              (company.users as any[])[index] = user;
            }
          }
        );

        // TODO: Above implement logic to only fetch users if updatedAtDate has been modified or user does not exist in..
        // ..in arr

        if (!company) {
          return reject({
            code: '404',
            message: 'Company does not exist',
          });
        }
        return resolve(company);
      } catch (error) {
        return reject(error);
      }
    });
  }

  /**
   *
   *
   * @param {Company} company
   * @return {*}
   * @memberof CompanyService
   */
  updateCompany(company: Company) {
    // TODO: fix thing where when company is updated , the mutated users from translating users array from ids to objects get written back to dp as objects and not id's, might not be a problem
    return new Promise(async (resolve, reject) => {
      try {
        await this.afsService.updateDocument<Company>(
          company?.id as string,
          COMPANY_COLLECTION_NAME,
          company
        );
        return resolve(company);
      } catch (error) {
        return reject(error);
      }
    });
  }

  /**
   *
   *
   * @return {*}
   * @memberof CompanyService
   */
  deleteCompany() {
    return new Promise(async (resolve, reject) => {
      try {
      } catch (error) {}
    });
  }

  /**
   *
   *
   * @param {string} email
   * @return {*}  {Observable<User[]>}
   * @memberof CompanyService
   */
  searchUsers(searchQuery: string): Observable<User[]> {

    searchQuery = searchQuery.trim().toLowerCase();

    const ALLOWED_SEARCH_QUERY_CHAR_LEN = 3;

    if (searchQuery.length < ALLOWED_SEARCH_QUERY_CHAR_LEN) {
      return of([]);
    }
    // When users are pulled for the first time , a query happens to being in entire user object
    const users = this.dataTransferService.$company.value?.users;

    const searchResults = (users as User[]).filter((user) => {
      const stringifiedUser = JSON.stringify(user).toLowerCase();

      return stringifiedUser.includes(searchQuery);
    });
    return of(searchResults);
  }
}
