<app-loader *ngIf="loading"></app-loader>

<div class="container">
    <div class="dp-start-screen-padding">


        <div class="row">
            <div class="col">
                <app-error-message *ngIf="loginErrors" [loginErrors]="loginErrors"></app-error-message>
            </div>
        </div>

        <div class="row">
            <div class="col text-center">
                <img class="dp-logo" src="assets/logo-hires.png" alt="" />
            </div>
        </div>

        <div class="row">
            <div class="col-12 my-auto mx-auto text-center">
                <div class="row">
                    <div class="col dp-welcome-container text-center">
                        <h1 class="dp-welcome-header">You've been invited</h1>
                        <p class="dp-welcome-subheader text-muted">Ali has invited you to join Stack0</p>
                        <hr>
                    </div>
                </div>
            </div>
        </div>

        <!-- Register form -->
        <form [formGroup]="signUpForm">
            <div class="form-group m-0">
                <div class="row">
                    <div class="col" style="padding-right: 4px;">
                        <label for="nameInput" class="text-muted dp-input-label">Name</label>
                        <input [ngClass]="{
                      'dp-text-input': true,
                      'input-sm': true,
                      'form-control': true,
                      'is-invalid':
                        signUpForm.get('name')?.errors &&
                        signUpForm.get('name')?.touched
                    }" formControlName="name" type="text" id="nameInput" aria-describedby="nameInput"
                            placeholder="Your name" />
                        <ng-container *ngFor="let validation of validationMessages.name">
                            <div class="invalid-feedback" *ngIf="
                        signUpForm.get('name')?.hasError(validation.type) &&
                        (signUpForm.get('name')?.dirty ||
                          signUpForm.get('name')?.touched)
                      ">
                                {{ validation.message }}
                            </div>
                        </ng-container>
                    </div>

                    <div class="col" style="padding-left: 4px;">
                        <label for="surnameInput" class="text-muted dp-input-label">Surname</label>
                        <input [ngClass]="{
                      'dp-text-input': true,
                      'input-sm': true,
                      'form-control': true,
                      'is-invalid':
                        signUpForm.get('surname')?.errors &&
                        signUpForm.get('surname')?.touched
                    }" formControlName="surname" type="text" id="surnameInput" aria-describedby="surnameInput"
                            placeholder="Your surname" />
                        <ng-container *ngFor="let validation of validationMessages.surname">
                            <div class="invalid-feedback" *ngIf="
                        signUpForm.get('surname')?.hasError(validation.type) &&
                        (signUpForm.get('surname')?.dirty ||
                          signUpForm.get('surname')?.touched)
                      ">
                                {{ validation.message }}
                            </div>
                        </ng-container>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col">
                        <label for="emailInput" class="text-muted dp-input-label">Email</label>
                        <input [ngClass]="{
                      'dp-text-input': true,
                      'input-sm': true,
                      'form-control': true,
                      'is-invalid':
                        signUpForm.get('email')?.errors &&
                        signUpForm.get('email')?.touched
                    }" formControlName="email" type="text" id="emailInput" aria-describedby="emailInput"
                            placeholder="your-email@example.com" />
                        <ng-container *ngFor="let validation of validationMessages.email">
                            <div class="invalid-feedback" *ngIf="
                        signUpForm.get('email')?.hasError(validation.type) &&
                        (signUpForm.get('email')?.dirty ||
                          signUpForm.get('email')?.touched)
                      ">
                                {{ validation.message }}
                            </div>
                        </ng-container>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col">
                        <label for="passwordInput" class="text-muted dp-input-label">Password</label>
                        <input [ngClass]="{
                      'dp-text-input': true,
                      'input-sm': true,
                      'form-control': true,
                      'is-invalid':
                        signUpForm.get('password')?.errors &&
                        signUpForm.get('password')?.touched
                    }" formControlName="password" type="password" id="passwordInput" aria-describedby="passwordInput"
                            placeholder="Create password" />
                        <ng-container *ngFor="let validation of validationMessages.password">
                            <div class="invalid-feedback" *ngIf="
                        signUpForm.get('password')?.hasError(validation.type) &&
                        (signUpForm.get('password')?.dirty ||
                          signUpForm.get('password')?.touched)
                      ">
                                {{ validation.message }}
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12">
                    <button type="button" class="btn btn-primary btn-block dp-login-btn mt-3 rounded-pill py-2"
                        (click)="onRegister()">
                        Accept Invitation
                    </button>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col text-left mb-3">
                    <p>
                        By accepting this invitation you
                        accept the fima
                        <a href="#">Terms of Use and acknowledge the Privacy Policy and Cookie
                            Policy</a>.
                    </p>
                </div>
                <div class="col-12">
                    <button type="button" class="btn btn-block dp-login-btn dp-login-google my-auto rounded-pill py-2"
                        (click)="registerWithGoogle()">
                        <img src="assets/google.png" alt="" class="g-logo" />
                        Accept Invitation with Google
                    </button>
                </div>
            </div>
        </form>
    </div>

</div>