import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { DataTransferService } from 'src/app/@core/services/@fima/data-transfer/data-transfer.service';
import { FormBuilder, Validators } from '@angular/forms';
import { NbMenuService, NbDialogService } from '@nebular/theme';
import { CacheService } from 'src/app/@core/services/@fima/cache/cache.service';
import { CompanyService } from 'src/app/@core/services/@fima/company/company.service';
import { ProfileService } from 'src/app/@core/services/@fima/profile/profile.service';
import { ProjectService } from 'src/app/@core/services/@fima/project/project.service';
import { ToastService } from 'src/app/@core/services/@fima/toast/toast.servie';
import { UtilsService } from 'src/app/@core/services/@fima/utils/utils.service';
import { TaigaRoleService } from 'src/app/@core/services/@taiga/role/role.service';
import { Project, STATUS } from 'src/app/@core/models/@fima/project.model';
import { Artifact, ParentHeaderEnum } from 'src/app/@core/models/@fima/artifact.model';
import { User } from 'src/app/@core/models/@fima/user.model';
import * as _ from 'lodash';
import { Company } from 'src/app/@core/models/@fima/company.model';

const DEFAULT_VIEW_INDEX = 0;

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
})
export class ProjectComponent extends DashboardComponent {

  @ViewChild('deleteAlertTemplate', { static: true }) deleteAlertTemplate!: TemplateRef<any>;


  override active_view = DEFAULT_VIEW_INDEX

  override projects: Project[] = []

  override loading = false;

  override loadingProjects = false;

  override currentArtifact: Artifact; 

  project: Project

  user: User;

  groupedProjectArtifacts: _.Dictionary<any[]>;

  constructor(
    // Inject all dependencies required by ProjectComponent
    router: Router,
    dataTransferService: DataTransferService,
    cacheService: CacheService,
    nbMenuService: NbMenuService,
    dialogService: NbDialogService,
    formBuilder: FormBuilder,
    projectService: ProjectService,
    utils: UtilsService,
    roleService: TaigaRoleService,
    companyService: CompanyService,
    toastService: ToastService,
    profileService: ProfileService,
    private route: ActivatedRoute
  ) {
    super(
      router,
      dataTransferService,
      cacheService,
      nbMenuService,
      dialogService,
      formBuilder,
      projectService,
      utils,
      roleService,
      companyService,
      toastService,
      profileService
    );

    this.route.queryParams.subscribe(async (params) => {
      if (params && (params as any).id) {

        this.user = this.dataTransferService.$user.value;

        this.loadingProjects = true;

        this.pollAppData(params)

        this.loadingProjects = false;


      }
    });


    this.artifactForm = this.formBuilder.group({
      artifactName: ['', [Validators.required]],
      artifactDescription: ['', [Validators.required]],
    });


  }

  override async pollAppData(params?: any): Promise<void> {
    try {
      this.project = await this.projectService.getProject((params as any).id, this.dataTransferService.$user.value.companyId);

      this.groupedProjectArtifacts = _.groupBy(this.project.projectArtifacts, 'parent');

      this.hasProjects = true


      this.projects = [this.project]

      if (!this.projects || this.projects.length == 0) {
        this.hasProjects = false
        return
      }

      console.log('PROJECTS', this.projects)

      const projectArtifactsTickets = this.getProjectsArtifactsAsTickets(this.projects);

      this.projectArtifactsTickets = projectArtifactsTickets;

      // Convert the sorted entries back into an object
      this.projectArtifactsTicketsGroup = _.groupBy(projectArtifactsTickets, 'whenFlag');

      this.todo = projectArtifactsTickets.filter((todo) => {
        return (
          todo.status == this.statusEnum.NOT_STARTED || todo.status == null
        );
      });

      this.inProgress = projectArtifactsTickets.filter((todo) => {
        return todo.status == this.statusEnum.IN_PROGRESS;
      });

      this.completed = projectArtifactsTickets.filter((todo) => {
        return todo.status == this.statusEnum.DONE;
      });
    } catch (error) { }

    this.bento_view_func();

    this.dataTransferService.$onfetchAppData.next(true);

  }

  override initDashData() {
    return;
  }


  async onOpenNewArtifact(event: any) {
    this.currentArtifact = undefined

    this.dataTransferService.$jsonData.next(null)
    this.artifactForm.get('artifactName')?.setValue('')
    this.artifactForm.get('artifactDescription')?.setValue('')

    this.currentArtifact = {} as any
    (this.currentArtifact as any)['createdAt'] = new Date();
    (this.currentArtifact as any)['updatedAt'] = new Date();

    const dialogRef = this.dialogService.open(
      this.dialogWindow.showDialogComponent,
      {
        context: {
          item: this.currentArtifact,
        },
        hasScroll: false,
        hasBackdrop: true,
        closeOnEsc: true,
        closeOnBackdropClick: true,
      }
    );
  }

  /**
   *
   *
   * @memberof ProjectComponent
   */
  deleteProject() {
    const dialogRef = this.dialogService.open(
      this.deleteAlertTemplate,
      {
        context: {},
        hasScroll: false,
        hasBackdrop: true,
        closeOnEsc: true,
        closeOnBackdropClick: true,
      }
    );
  }

  async onDeleteProject(ref: any) {
    this.loading = true;

    ref.close()

    try {
      // remove project 
      const res = await this.projectService.deleteProject(this.project?.id);

      //update company project count 
      const company = this.dataTransferService.$company.value as Company;
      if (company?.projectsCount as number > 0) {
        company['projectsCount'] = company?.projectsCount as number - 1;
      }

      // remove project from the projects arr 
      const index = (company.projects as string[]).findIndex((project) => {
        return project == this.project?.id
      })

      if (index !== -1) {
        company.projects?.splice(index, 1)
      }

      // update company
      const updatedCompany = await this.companyService.updateCompany(company);

      // broadcast fetch new projects event

      this.groupedProjectArtifacts = _.groupBy(this.project?.projectArtifacts, 'parent');

      this.dataTransferService.$onfetchAppData.next(true)

      this.router.navigate(['/portal'])

      this.toastService.showToast('Project has been deleted', 'danger')

    } catch (error) {
      console.log('ProjectComponent >> onDeleteProject >> error >> ', error)
    }

    this.loading = false;
  }

  private async getUpdatedProject(projectId?: number) {

    this.project = await this.projectService.getProject(projectId, this.dataTransferService.$user.value.companyId);

    this.groupedProjectArtifacts = _.groupBy(this.project?.projectArtifacts, 'parent');

    this.dataTransferService.$onfetchAppData.next(true)

  }


  /**
   *
   *
   * @memberof ProjectComponent
   */
  override async onSaveArtifact() {

    this.loading = true;

    const company = this.dataTransferService.$company.value as Company;

    if (!this.currentArtifact || (this.currentArtifact && !this.currentArtifact.id)) {
      // Create new artifact here 
      const createdArtifact = {
        // id: uuidv4(),
        createdAt: new Date().getTime(),
        updatedAt: new Date().getTime(),
        parent: ParentHeaderEnum.CUSTOM,
        label: this.artifactForm.get('artifactName')?.value,
        aside: this.artifactForm.get('artifactDescription')?.value,
        link: '',
        artifactPropTree: this.artefactPropTree,
        showOpts: false,
        icon: '',
        status: STATUS.NOT_STARTED
      }

      this.currentArtifact = createdArtifact as any; //<-- update current artifact


      // Add this artifact to project 
      this.project?.projectArtifacts.push(createdArtifact)

      // TODO: This is creating a new artifact into a project 

      const updatedProject = await this.projectService.createArtifactsInProject(this.project);

      this.dataTransferService.$closeArtefactWindow.next(true);

      this.loading = false

      this.toastService.showToast('Added your artefact', 'success')

      // get updated project
      await this.pollAppData(this.project);

      return

    }

    this.loading = true;

    await this.updateArtefact(
      this.currentArtifact,
      this.currentTodo?.projectId,
      undefined,
      this.artefactPropTree
    );

    this.loading = false;

    this.dataTransferService.$closeArtefactWindow.next(true);
    // Fetch projects
    await this.pollAppData(this.project);

    this.toastService.showToast('Saved your artefact', 'success');

  }

  override async onDeleteArtifact(artifact: Artifact) {
    this.loading = true;
    // find the artifact that was clicked on's position in the project

    // Update projects
    const project = this.projects
      .filter((project) => {
        return project?.id == this.currentTodo?.projectId;
      })
      .shift() as Project;

    if (!project) {
      return;
    }

    const artifactIndex = project.projectArtifacts.findIndex((a) => {
      return a.id == artifact.id;
    });

    if (artifactIndex == -1) {
      return;
    }

    project.projectArtifacts.splice(artifactIndex, 1);

    await this.projectService.deleteProjectArtifact(
      project,
      this.currentTodo.id,
    );

    this.dataTransferService.$closeArtefactWindow.next(true);

    // Fetch projects
    await this.pollAppData(this.project);

    this.loading = false;

    this.toastService.showToast('Artefact deleted', 'danger');
  }

}
