import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { publicPagesModule } from './pages/public/public-pages.module';

import { AngularFireModule } from '@angular/fire/compat';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PortalPagesModule } from './pages/portal/portal-pages.module';
import { OverviewComponent } from './@theme/widgets/overview/overview.component';


import { AngularFirestoreModule, USE_EMULATOR as FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule, USE_EMULATOR as AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './@core/services/@taiga/auth/auth.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    OverviewComponent,
  ],
  imports: [
    HttpClientModule,
    // # Start: Custom modules #//
    PortalPagesModule,
    publicPagesModule,
    // # End: User Custom modules #//

    // # Start: System modules #//
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    // # End: System modules #//

    // # Start: Vendor modules #//
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    NgxWebstorageModule.forRoot(),
    AngularFirestoreModule
    // # End: Vendor modules #//
  ],
  providers: [
    {
      provide: AUTH_EMULATOR,
      useValue: environment.production ? undefined : ['http://127.0.0.1:9099'],
    },
    {
      provide: FIRESTORE_EMULATOR,
      useValue: environment.production ? undefined : ['localhost', 8080],
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
