import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FirebaseError } from 'firebase/app';
import * as auth from 'firebase/auth';
import firebase from 'firebase/compat';
import { CacheService } from '../cache/cache.service';
import { ProfileService } from '../profile/profile.service';
import { User } from '../../../models/@fima/user.model';
import mixpanel from 'mixpanel-browser';

@Injectable({
  providedIn: 'root'
})
export class FirebaseAuthService {


  constructor(
    public auth: AngularFireAuth,
    private profileService: ProfileService,
    private store: CacheService
  ) { }


  /**
   * Create user using email and password && Create user in users collection
   *
   * @param {CreateUser} createUser
   * @return {*}
   * @memberof FirebaseAuthService
   */
  registerWithEmail(createUser: User) {
    return new Promise<any>(async (resolve, reject) => {
      try {
        await this.auth.createUserWithEmailAndPassword(createUser.email, createUser.password!);
        const user = await this.auth.currentUser;
        user?.updateProfile({ displayName: `${createUser.name}` });
        // Create user profile in users collection
        createUser.uid = user?.uid;
        delete createUser['password']
        const createdUser = await this.profileService.createUserProfile(createUser);
        // Track an event. It can be anything, but in this example, we're tracking a Sign Up event.
        mixpanel.track('Sign Up', {
          'Signup Type': 'Email_Password'
        })
        return resolve(createdUser);
      } catch (err) {
        return reject(err);
      }
    });
  }


  /**
   * Signs ina user using their email and password credentials
   *
   * @param {string} email
   * @param {string} password
   * @return {*}
   * @memberof FirebaseAuthService
   */
  signInWithEmail(email: string, password: string) {
    return new Promise<any>(async (resolve, reject) => {
      try {
        await this.auth.signInWithEmailAndPassword(email, password);
        // Track an event. It can be anything, but in this example, we're tracking a Sign Up event.
        mixpanel.track('Sign Up', {
          'Login Type': 'Email_Password'
        })
        const user = await this.auth.currentUser;
        return resolve(user);
      } catch (err: FirebaseError | any) {
        return reject(err as FirebaseError);
      }
    });
  }


  /**
   * Register or login with google
   *
   * @return {*} 
   * @memberof FirebaseAuthService
   */
  continueWithGoogle() {
    return new Promise<any>(async (resolve, reject) => {
      let user!: firebase.User;
      let existingUser!: User

      try {
        user = await this.googleAuth() as firebase.User;
        existingUser = await this.profileService.getUserProfile(user?.uid!);

        if (existingUser) {
          const signInWithGoogleUser = await this.signInWithGoogle(user)
          // Track an event. It can be anything, but in this example, we're tracking a Sign Up event.
          mixpanel.track('Sign Up', {
            'Login Type': 'Google'
          })
          return resolve(signInWithGoogleUser)
        } else {
          const registerWithGoogleUser = await this.registerWithGoogle(user)
          // Track an event. It can be anything, but in this example, we're tracking a Sign Up event.
          mixpanel.track('Sign Up', {
            'Signup Type': 'Google'
          })
          return resolve(registerWithGoogleUser)
        }
      } catch (error) {
        // Here handle error that is thrown if user does not exist
        if ((error as any).code == 'logged-in-user:no-exists') {
          const createdUser = await this.createUserForGoogleSignUp(user); //<-- Create user 
          return resolve(user)
        }
        return reject(error)
      }
    });
  }

  /**
   * Create user using google oAuth
   *
   * @param {*} role
   * @return {*}
   * @memberof FirebaseAuthService
   */
  registerWithGoogle(user: firebase.User) {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const createdUser = await this.createUserForGoogleSignUp(user); //<-- Create user 
        return resolve(user)
      } catch (error) {
        return reject(error)
      }
    });
  }


  /**
   * Signs ina user using using google oAuth
   *
   * @return {*}
   * @memberof FirebaseAuthService
   */
  signInWithGoogle(user: firebase.User) {
    return new Promise<firebase.User>(async (resolve, reject) => {
      try {
        return resolve(user!);
      } catch (err) {
        return reject(err);
      }
    });
  }

  /**
   * Signs out a user and destroys session
   *
   * @memberof FirebaseAuthService
   */
  signOut() {
    return new Promise<any>(async (resolve, reject) => {
      try {
        await this.auth.signOut();
        this.store.clear();
        return resolve({});
      } catch (err) {
        return reject(err);
      }
    });
  }

  /**
   * Checks if a user is authenticated
   *
   * @return {*}  {boolean}
   * @memberof FirebaseAuthService
   */
  async isSignedIn(): Promise<boolean> {
    const user = await this.auth.currentUser;
    if (user) {
      return true;
    }
    return false;
  }

  /**
   *
   *
   * @return {*}
   * @memberof FirebaseAuthService
   */
  private async googleAuth() {
    const provider = new auth.GoogleAuthProvider;
    const credentials = await this.auth.signInWithPopup(provider);
    const user = credentials.user;
    return user;
  }

  /**
   * Helper function for register with google
   *
   * @private
   * @param {firebase.User} user
   * @return {*} 
   * @memberof FirebaseAuthService
   */
  private async createUserForGoogleSignUp(user: firebase.User): Promise<any> {
    user?.updateProfile({ displayName: `${user?.displayName}` });
    // Create user profile in users collection
    const createUser: User = {
      uid: user?.uid,
      companyId: null,
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime(),
      name: (user?.displayName as string),
      surname: '',
      email: user?.email as string,
      roles: [],
      hasSeenOnboarding: false,
      subscribedToProductUpdates: false,
      hasCreatedProject: false,
      profilePictureUrl: user && user.photoURL ? user?.photoURL : null
    };
    const createdUser = await this.profileService.createUserProfile(createUser);
    return createdUser;
  }


  /**
   *
   *
   * @return {*} 
   * @memberof FirebaseAuthService
   */
  private refreshIdToken() {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const user = await this.auth.currentUser
        const token = await user?.getIdToken()
        return resolve(token);
      } catch (err) {
        return reject(err);
      }
    });
  }
}
