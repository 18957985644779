import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CacheService } from '../../@fima/cache/cache.service';


@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(
    private http: HttpClient,
    private cacheService: CacheService,
  ) { }

  /**
  * Stores the refresh token securely using CacheService
  */
  saveRefreshToken(refreshToken: string): void {
    this.cacheService.storeItem('refreshToken', refreshToken);
  }

  /**
   * Retrieves the refresh token securely
   */
  getRefreshToken(): string | null {
    return this.cacheService.retrieveItem('refreshToken');
  }


  /**
   * Fetches auth token using refresh token
   */
  async refreshTaigaToken(refreshToken: string) {
    try {
      const response = await this.http.post<any>(`${environment.taigaApiUrl}/auth/refresh`, { "refresh": refreshToken }, {
        headers: {
          "Content-Type": "application/json"
        }
      }).toPromise();
      return response;
    } catch (error) {
      console.error("TokenService >> refreshTaigaToken >> error >> ", error);
      throw error;
    }
  }

}
