<div class="row">
  <div class="col-auto my-auto pr-0">
    <nb-user
      style="margin-top: 8px !important"
      [size]="'medium'"
      [picture]="
      (comment.commenterUserId | resolveUserId)!== undefined
          ? (comment.commenterUserId | resolveUserId).profilePictureUrl
          : ''
      "
      [showName]="false"
      [name]=" (comment.commenterUserId | resolveUserId)?.name"
    >
    </nb-user>
  </div>
  <div class="col my-auto pl-2" style="line-height: 8px">
    <p class="m-0">{{ comment.commenterFullName }}</p>
    <small class="text-muted" style="font-size: 10px">{{
      comment.createdAt | date : "dd MMM yyyy - HH:mm"
    }}</small>
  </div>
  <div class="col-auto my-auto">
    <button class="btn btn-clear">
      <i class="ri-more-2-fill"></i>
    </button>
  </div>

  <div class="col-12 mt-2">
    <div class="row dp-comment-text-cont">
      <div class="col-auto my-auto">
        <p class="m-0 mb-1 dp-comment-text">
          {{ comment.commentText }}
        </p>
        <span
          class="dp-inserted-emoji"
          *ngFor="let emoji of comment.insertedEmoji"
          >{{ emoji.emoji + " " + emoji.reactions }}</span
        >
      </div>
      <div class="col my-auto text-right">
        <button
          (click)="toggleEmojiPicker(commentIndex)"
          class="emoji-button btn btn-clear p-0"
        >
          <i class="ri-user-smile-line"></i>
        </button>
        <emoji-mart
          [darkMode]="false"
          *ngIf="openEmojiPickerIndex === commentIndex"
          (emojiClick)="addEmoji($event, comment.id, commentIndex)"
        ></emoji-mart>
      </div>
    </div>
  </div>
</div>
