import { NgModule } from '@angular/core';
import {
  AngularFireAuthGuard,
  redirectLoggedInTo,
  redirectUnauthorizedTo,
} from '@angular/fire/compat/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { CreateComponent } from './pages/portal/@fima/create/create.component';
import { DashboardComponent } from './pages/portal/@fima/dashboard/dashboard.component';
import { PortalComponent } from './pages/portal/portal.component';
import { ProjectComponent } from './pages/portal/@fima/project/project.component';
import { SettingsComponent } from './pages/portal/@fima/settings/settings.component';
import { OnboardingComponent } from './pages/portal/@fima/onboarding/onboarding.component';
import { GoToDesktopComponent } from './pages/public/go-to-desktop/go-to-desktop.component';
import { PageNotFoundComponent } from './pages/public/page-not-found/page-not-found.component';
import { StartComponent } from './pages/public/start/start.component';
import { DesignComponent } from './pages/portal/@fima/design/design.component';
import { ProjectSettingsComponent } from './pages/portal/@fima/project-settings/project_settings.component';
import { InviteComponent } from './pages/public/invite/invite.component';
import { InviteResolvedComponent } from './pages/portal/@fima/invite/invite.component';
import { LaunchpadComponent } from './pages/portal/@fima/launchpad/launchpad.component';



const redirectUnauthorizedToStart = () => {
  // Add some logic here todo stuffs

  return redirectUnauthorizedTo(['/start']);
};

const redirectAuthorizedToDashboard = () => redirectLoggedInTo(['/portal']);

const redirectAuthorizedInvitedUser= () => redirectLoggedInTo(['/portal']);

const routes: Routes = [
  {
    path: '',
    redirectTo: '/start',
    pathMatch: 'full',
  },
  {
    path: 'start',
    component: StartComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectAuthorizedToDashboard },
  },
  {
    path: 'invite',
    component: InviteComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectAuthorizedInvitedUser },
  },
  { path: 'm', component: GoToDesktopComponent },
  {
    path: 'portal',
    component: PortalComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToStart },
    children: [
      {
        path: '',
        redirectTo: '/portal',
        pathMatch: 'full',
      },
      {
        path: 'invite',
        component: InviteResolvedComponent,
      },
      {
        path: 'onboarding',
        component: OnboardingComponent,
      },
      {
        path: 'dashboard',
        component: LaunchpadComponent,
      },
      {
        path: 'launchpad',
        component: LaunchpadComponent,
      },
      // {
      //   path: 'create',
      //   component: CreateComponent,
      // },
      {
        path: 'project',
        component: ProjectComponent,
      },
      {
        path: 'settings',
        component: SettingsComponent,
      },
      {
        path: 'designer',
        component: DesignComponent,
      },
      {
        path: 'project-settings',
        component: ProjectSettingsComponent,
      },
    ],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
  providers: [],
  declarations: [],
})
export class AppRoutingModule {}
