<ng-template #showDialogComponent let-data let-ref="dialogRef">
    <div class="container-fluid">
        <div class="row">
            <div [ngClass]="{
                'dp-artifact-window-side-pane-defaults': true,
                'dp-artifact-window-side-pane': showSidePane,
                'dp-artifact-window-side-pane-hidden': !showSidePane
            }" *ngIf="showSidePane">
                <nb-card [size]="'giant'" class="dp-artifact-window-card m-0 p-0">
                    <nb-card-body class="dp-side-panel-body">
                        <ng-content select="[dialog-side-panel]"></ng-content>
                    </nb-card-body>
                </nb-card>
            </div>
            <div [ngClass]="{
                'col dp-artifact-window-defaults': true,
                'dp-artifact-window pr-0': showSidePane,
                'dp-artifact-window-full': !showSidePane,
            }">
                <nb-card [size]="'giant'" class="dp-artifact-window-card m-0" >
                    <nb-card-header [ngClass]="{
                        'dp-artifact-window-card-header-adjust': !(dataTransferService.$toolBarInViewPort | async),
                        'dp-artifact-window-card-header-1': true
                    }">
                        <div class="row">
                            <div class="col-auto my-auto pr-1" *ngIf="showSideBtn">
                                <button class="btn btn-clear glow border-0" (click)="toggleSidePage($event)">
                                    <img src="assets/create-modal-icons/sidebar-right.svg" alt="" >
                                </button>
                            </div>
                            <div class="col-auto my-auto">
                                <ng-content select="[dialog-date]"></ng-content>
                            </div>
                            <div class="col text-right my-auto" >
                                <ng-content select="[dialog-pop-over]" *ngIf="showOptsPopOver"></ng-content>
                                <button class="btn btn-outline-primary m-3 border-0" (click)="onClickSaveBtn.emit()" *ngIf="showSaveBtn">
                                    <div class=" d-flex align-items-center justify-content-center">
                                        <!-- <i class="ri-save-2-line  mr-2"></i> -->
                                        {{!saveBtnText? 'Save': saveBtnText}}
                                    </div>
                                </button>
                              
                                <button class="btn btn-clear  glow dp-artifact-window-close-btn border-0" (click)="ref.close(); onCloseBtn.emit()">
                                    <img src="assets/remix-icons/close-line.svg" alt="" width="18px" width="18px" style="margin-bottom: 3px;">
                                </button>
                            </div>
                        </div>
                    </nb-card-header>
                    <nb-card-body>
                        <div class="row mt-4" *ngIf="showLabel">
                            <div class="col my-auto">
                                <h3>{{data?.item?.label}}</h3>
                            </div>
                            <div class="col-auto my-auto">
                            </div>
                        </div>
                        <ng-content select="[dialog-main-content-area]"></ng-content>
                    </nb-card-body>
                    <nb-card-footer *ngIf="showFooter">
                        <ng-content select="[dialog-footer-content-area]"></ng-content>
                    </nb-card-footer>
                </nb-card>
            </div>
        </div>
    </div>
</ng-template>
  