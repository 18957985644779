import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CacheService } from './@core/services/@fima/cache/cache.service';
import { Title } from '@angular/platform-browser';
import { ProjectService } from './@core/services/@fima/project/project.service';
import mixpanel from 'mixpanel-browser';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {


  constructor(
    private router: Router,
    private store: CacheService,
    private titleService: Title,
    private projectService: ProjectService
  ) {
    this.subToRoutingEvents();
  }

  /**
   *
   *
   * @memberof AppComponent
   */
  ngOnInit() {
    this.checkForMobileBrowser();
    mixpanel.init(environment.mixpanelToken, { debug: environment.production ? false : true, track_pageview: true, persistence: 'localStorage' });
  }


  /**
   *
   *
   * @memberof AppComponent
   */
  @HostListener('window:resize', ['$event'])
  onresize() {
    this.checkForMobileBrowser();
  }

  /**
   *
   *
   * @private
   * @memberof AppComponent
   */
  private checkForMobileBrowser() {
    if (window.innerWidth <= 820) {
      this.store.storeItem('lastViewMode:mobile', JSON.stringify(true));
      this.router.navigate(['m'], { replaceUrl: true });
    } else if (JSON.parse(this.store.retrieveItem('lastViewMode:mobile')) === true) {
      this.router.navigate(['/']);
      this.store.storeItem('lastViewMode:mobile', JSON.stringify(false));
    }
  }

  /**
   *
   *
   * @private
   * @memberof AppComponent
   */
  private subToRoutingEvents() {
    this.router.events.subscribe(async (routingEvent) => {
      if (routingEvent instanceof NavigationEnd) {

        this.checkForMobileBrowser();

        await this.updatePageTitle(routingEvent);
      }
    });
  }

  /**
   *
   *
   * @private
   * @param {NavigationEnd} routingEvent
   * @memberof AppComponent
   */
  private async updatePageTitle(routingEvent: NavigationEnd) {
    const currentRoute = routingEvent.urlAfterRedirects;

    let pageTitle = currentRoute.split('/').reverse().shift();

    let pageTitleSuffix = 'fima.ai';

    if (currentRoute.split('?').shift() == '/portal/project') { // <-- handle url on project page

      const projectId = parseInt(currentRoute.split('=')[currentRoute.split('=').length - 1]); // < -- Extract project id 

      const project = await this.projectService.getProject(projectId, ''); //<-- Fetch related project

      let projectName = project?.projectName; //<-- Update pageTitle to be name of project

      this.titleService.setTitle(`${projectName} - ${pageTitleSuffix}`);

    } else if (pageTitle?.includes('?')) { // <-- handle url that has params

      pageTitle = pageTitle?.split('?').shift();

      pageTitle = `${pageTitle?.charAt(0).toUpperCase() + (pageTitle as string)?.slice(1)}`;

      this.titleService.setTitle(`${pageTitle} - ${pageTitleSuffix}`);
    } else {
      this.titleService.setTitle(`${pageTitle?.charAt(0).toUpperCase() + (pageTitle as string)?.slice(1)} - ${pageTitleSuffix}`);
    }
  }
}
