import { Injectable } from '@angular/core';
import { Artifact, ParentHeaderEnum } from 'src/app/@core/models/@fima/artifact.model';
import { CreateTaigaProject, TaigaProject } from 'src/app/@core/models/@taiga/project.model';
import { Project, STATUS } from '../../../models/@fima/project.model';
import { TaigaProjectService } from '../../@taiga/project/project.service';
import { TaigaUserStoryService } from '../../@taiga/user-story/user-story.service';
import { AfsService } from '../afs/afs.service';
import { CreateUserStory, StatusExtraInfo, TaigaUserStory } from 'src/app/@core/models/@taiga/user-story.model';
import { CacheService } from '../cache/cache.service';
import { environment } from 'src/environments/environment';
import { UtilsService } from '../utils/utils.service';

const PROJECT_COLLECTION_NAME = 'Projects';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(
    private afsService: AfsService,
    private taigaProjectService: TaigaProjectService,
    private taigaUserStoryService: TaigaUserStoryService,
    private cacheService: CacheService,
    private utilsServiceL: UtilsService
  ) { }

  /**
   *
   *
   * @return {*}
   * @memberof ProjectService
   */
  createProject(project: Project): Promise<Project> {
    return new Promise<any>(async (resolve, reject) => {
      // @firebase
      // try {
      //   const createdProject = await this.afsService.addDocument<Project>(
      //     project,
      //     PROJECT_COLLECTION_NAME
      //   );
      //   return resolve(createdProject);
      // } catch (error) {
      //   return reject(error);
      // }


      // @taiga
      // Create project in taiga instance
      const taigaProject: CreateTaigaProject = {
        name: project.projectName,
        description: project.projectGoal
      }

      try {
        const createdTaigaProject = await this.taigaProjectService.createTaigaProject(
          taigaProject
        )

        // append user stories to the project 

        for (let i = 0; i < project.projectArtifacts.length; i++) {
          const artefact = project.projectArtifacts[i];

          const fimaArtefactToUserStory: CreateUserStory = {
            project: createdTaigaProject.id,
            subject: artefact.label,
            description: artefact.artifactPropTree
          }

          const createdTaigaUserStory = await this.taigaUserStoryService.createUserStory(fimaArtefactToUserStory)
        }

        const createdProject = this.taigaProjectToFimaProjectMap(createdTaigaProject, project.ownerCompanyId);

        return resolve(createdProject)
      } catch (e) {
        return reject(e)
      }
    });
  }

  createArtifactsInProject(project: Project): Promise<Project> {
    return new Promise<any>(async (resolve, reject) => {
      // @taiga   
      try {

        // append user stories to the project 
        for (let i = 0; i < project.projectArtifacts.length; i++) {


          const artefact = project.projectArtifacts[i];
          //  Prevent duplicating artifacts tha already exist in the project
          //  Since project already contains some of these artifacts

          if (artefact.id) {
            // Do nothing
          }else {
            const fimaArtefactToUserStory: CreateUserStory = {
              project: project.id,
              subject: artefact.label,
              description: artefact.artifactPropTree
            }
  
            const createdTaigaUserStory = await this.taigaUserStoryService.createUserStory(fimaArtefactToUserStory)
          }
        }

        return resolve(project)
      } catch (e) {
        return reject(e)
      }
    });
  }

  getProjects(companyId: string, limit?: number) {
    return new Promise<Project[]>(async (resolve, reject) => {

      // @firebase
      // try {
      //   const projects = await this.afsService.findDocuments<Project>(
      //     'ownerCompanyId',
      //     '==',
      //     companyId,
      //     PROJECT_COLLECTION_NAME,
      //     limit
      //   );

      //   return resolve(projects);
      // } catch (error) {
      //   return reject(error);
      // }


      // @taiga
      // Get projects from taiga instance
      try {
        const taigaProjects = (await this.taigaProjectService.getTaigaProjects()).filter((project) => {
          return project.id !== environment.artifactsProjectId
        });

        const taigaProjectsToFimaProjectsMap = []

        for (let i = 0; i < taigaProjects.length; i++) {

          const taigaProject = taigaProjects[i]

          const project: Project = this.taigaProjectToFimaProjectMap(taigaProject, companyId)

          const projectUserStories = await this.taigaUserStoryService.getUserStoriesByProject(taigaProject.id);

          const taigaUserStoriesToFimaArtifactsMap: Artifact[] = [];

          const unresolvedUserStories = []
          for (let j = 0; j < projectUserStories.length; j++) {
            unresolvedUserStories.push(this.taigaUserStoryService.getUserStoryById(projectUserStories[j].id));
          }

          const resolvedUserStories = await Promise.all(unresolvedUserStories);

          for (let j = 0; j < resolvedUserStories.length; j++) {

            const taigaUserStory = resolvedUserStories[j]
            // Map user stories to artifacts 

            const artifact: Artifact = this.taigaUserStoryToFimaArtifactMap(taigaUserStory);

            taigaUserStoriesToFimaArtifactsMap.push(artifact);

          }

          project.projectArtifacts = taigaUserStoriesToFimaArtifactsMap;

          taigaProjectsToFimaProjectsMap.push(project)
        }
        
        // console.log('Test' , taigaProjectsToFimaProjectsMap)

        return resolve(taigaProjectsToFimaProjectsMap as any)
      } catch (error) {
        return reject(error)
      }
    });
  }

  /**
   *
   *
   * @param {number} projectId
   * @return {*}  {Promise<Project>}
   * @memberof ProjectService
   */
  getProject(projectId: number, companyId: string): Promise<Project> {
    return new Promise<Project>(async (resolve, reject) => {

      // @firebase
      // try {
      //   const project = this.afsService.findDocumentById<Project>(
      //     projectId,
      //     PROJECT_COLLECTION_NAME
      //   );
      //   return resolve(project);
      // } catch (error) {
      //   return reject(error);
      // }

      // @taiga fetch project

      try {

        const taigaProject = await this.taigaProjectService.getProject(projectId);

        const project: Project = this.taigaProjectToFimaProjectMap(taigaProject, companyId)

        const projectUserStories = await this.taigaUserStoryService.getUserStoriesByProject(taigaProject.id);

        const taigaUserStoriesToFimaArtifactsMap: Artifact[] = [];

        // Map user stories to artifacts 
        for (let j = 0; j < projectUserStories.length; j++) {

          const taigaUserStory = await this.taigaUserStoryService.getUserStoryById(projectUserStories[j].id);

          const artifact: Artifact = this.taigaUserStoryToFimaArtifactMap(taigaUserStory);

          taigaUserStoriesToFimaArtifactsMap.push(artifact);
        }
        project.projectArtifacts = taigaUserStoriesToFimaArtifactsMap;
        return resolve(project)

      } catch (error) {
        return reject(error)
      }
    });
  }


  /**
   *
   *
   * @param {*} project
   * @param {number} projectId
   * @return {*} 
   * @memberof ProjectService
   */
  updateProject(project: Project) {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const taigaProject = {
          name: project.projectName,
          description: project.projectGoal
        }

        const updatedTaigaProject = await this.taigaProjectService.updateTaigaProject(project.id, taigaProject);
        const updatedProject: Project = this.taigaProjectToFimaProjectMap(updatedTaigaProject, project.ownerCompanyId)

        return resolve(updatedProject);
      } catch (e) {
        return reject(e);
      }
    });
  }

  /**
   *
   *
   * @param {number} projectId
   * @param {number} artifactId
   * @return {*} 
   * @memberof ProjectService
   */
  updateProjectArtifact(project: Project, artifact: Artifact) {
    return new Promise<any>(async (resolve, reject) => {

      try {

        const userStoryStatuses = await this.taigaUserStoryService.getUserStoryStatuses(project.id)

        const fimaArtifactToUserStoryMap: TaigaUserStory = {
          ...artifact,
          id: artifact.id,
          subject: artifact.label,
          status: this.fimaStatusToTaigaStatusMap(artifact.status, userStoryStatuses),
          modified_date: new Date(artifact.updatedAt).toISOString(),
          created_date: new Date(artifact.createdAt).toISOString(),
          description: artifact.description,
          description_html: artifact.artifactPropTree,
          assigned_users: artifact.assignees,
          due_date: artifact.due_date,
          tags: []
        }


        const updatedTaigaUsrStory = await this.taigaUserStoryService.updateUserStory(artifact.id, fimaArtifactToUserStoryMap);

        const artifactIndex = (project as Project).projectArtifacts.findIndex((a) => {
          return a.id == artifact.id
        })

        const updatedArtifact = this.taigaUserStoryToFimaArtifactMap(updatedTaigaUsrStory)

        project.projectArtifacts[artifactIndex] = updatedArtifact
        return resolve(project);
      } catch (e) {
        return reject(e);
      }
    });
  }

  /**
   *
   *
   * @param {string} projectId
   * @return {*} 
   * @memberof ProjectService
   */
  deleteProject(projectId: number) {
    return new Promise<any>(async (resolve, reject) => {
      // @firebase

      // try {
      //   await this.afsService.removeDocument<Project>(projectId, PROJECT_COLLECTION_NAME);
      //   return resolve({
      //     message: `Project with id ${projectId} has been removed`
      //   })
      // } catch (error) {
      //   return reject(error)
      // }

      // @taiga
      try {
        const deletedProject = await this.taigaProjectService.deleteTaigaProject(projectId);
        return resolve({
          message: `Project with id ${projectId} has been removed`
        })
      } catch (error) {
        return reject(error)
      }
    });

  }

  deleteProjectArtifact(project: any, artifactId: number) {
    return new Promise<any>(async (resolve, reject) => {
      // @taiga
      try {
        const deletedArtefact = await this.taigaUserStoryService.deleteUserStory(artifactId);
        return resolve({
          message: `Artifact  with id ${artifactId} has been removed`
        })
      } catch (error) {
        return reject(error)
      }
    });
  }

  /**
   *
   *
   * @private
   * @param {TaigaProject} taigaProject
   * @param {string} companyId
   * @return {*}  {Project}
   * @memberof ProjectService
   */
  private taigaProjectToFimaProjectMap(taigaProject: TaigaProject, companyId: string): Project {
    return {
      ...taigaProject,
      id: taigaProject.id,
      ownerCompanyId: companyId,
      projectName: taigaProject.name,
      projectGoal: taigaProject.description,
      createdBy: taigaProject.owner.full_name_display,
      status: STATUS.NOT_STARTED, // TODO: define status mapping for projects
      createdAt: new Date(taigaProject.created_date),
      updatedAt: new Date(taigaProject.modified_date),
      projectIntegrations: [],
      projectArtifacts: [],
      invitedUsers: [],
      users: [],
      inviteAllCompany: false,
      endDate: null,
      projectThumbnailUrl: '',
    };
  }

  private taigaUserStoryToFimaArtifactMap(taigaUserStory: TaigaUserStory): Artifact {
    return {
      ...taigaUserStory,
      id: taigaUserStory.id,
      label: taigaUserStory.subject,
      parent: ParentHeaderEnum.CUSTOM,
      status: this.taigaStatusToFimaStatusMap(taigaUserStory.status_extra_info),
      updatedAt: new Date(taigaUserStory.modified_date).getTime(),
      createdAt: new Date(taigaUserStory.created_date).getTime(),
      artifactPropTree: taigaUserStory.description_html,
      assignees: taigaUserStory.assigned_users,
      duration: taigaUserStory.due_date,

      // TODO:  define mapping
      showOpts: false,
      templateId: '',
      aside: '',
    };
  }

  private fimaStatusToTaigaStatusMap(fimaStatus: string, taigaStatuses: any[]): number {
    // Define the mapping from FIMA status codes to Taiga status names
    const statusMapping = {
      '1': "New", // Assuming "New" maps to NOT_STARTED, if applicable
      '2': "Ready",
      '3': "In progress",
      '4': "Ready for test", // Assuming "Ready for test" maps to REVIEW
      '5': "Done",
      '6': "Archived"
    };

    // Get the corresponding Taiga status name for the FIMA status code
    const taigaStatusName = statusMapping[fimaStatus];

    if (!taigaStatusName) {
      throw new Error('Invalid FIMA Status');
    }

    // Assume taigaStatuses is now passed as a parameter, containing the array of statuses from the endpoint
    // Find the first status object in Taiga statuses that matches the name
    const matchingStatus = taigaStatuses.find(status => status.name === taigaStatusName);

    if (!matchingStatus) {
      throw new Error('No matching Taiga status for the FIMA status code');
    }

    return matchingStatus.id;
  }


  private taigaStatusToFimaStatusMap(statusExtraInfo: StatusExtraInfo): string {
    // Define the mapping from Taiga status names to FIMA status codes
    const statusMapping = {
      "New": '1', // Assuming "New" maps to NOT_STARTED, if applicable
      "Ready": '2',
      "In progress": '3',
      "Ready for test": '4', // Assuming "Ready for test" maps to REVIEW
      "Done": '5',
      "Archived": '6'
    };

    // Directly use the name from StatusExtraInfo to map to a FIMA status code
    const fimaStatus = statusMapping[statusExtraInfo.name];

    if (!fimaStatus) {
      throw new Error(`No matching FIMA status for the Taiga status name: ${statusExtraInfo.name}`);
    }

    return fimaStatus;
  }

}
