<app-loader *ngIf="loading"></app-loader>

<div class="container">
  <div class="dp-start-screen-padding">

    <div class="row">
      <div class="col">
        <app-error-message *ngIf="loginErrors" [loginErrors]="loginErrors"></app-error-message>
      </div>
    </div>

    <div class="row">
      <div class="col text-center">
        <img class="dp-logo" src="assets/logo-hires.png" alt="" />
      </div>
    </div>
  
    <div class="row">
      <div
        class="col-12 my-auto mx-auto text-center"
        *ngIf="showLoginForm"
      >

        <div class="row">
          <div class="col dp-welcome-container text-center">
            <h1 class="dp-welcome-header">Welcome back</h1>
            <p class="dp-welcome-subheader">Login to your account</p>
          </div>
        </div>
  
        <!-- Login form -->
        <form [formGroup]="loginForm">
          <div class="form-group m-0">
            <div class="row">
              <div class="col">
                <label for="emailInput" class="text-muted dp-input-label"
                  >Email</label
                >
                <input
                  [ngClass]="{
                    'dp-text-input': true,
                    'input-sm': true,
                    'form-control': true,
                    'is-invalid':
                      loginForm.get('email')?.errors &&
                      loginForm.get('email')?.touched
                  }"
                  formControlName="email"
                  type="text"
                  id="emailInput"
                  aria-describedby="emailInput"
                  placeholder="your-email@example.com"
                />
                <ng-container *ngFor="let validation of validationMessages.email">
                  <div
                    class="invalid-feedback"
                    *ngIf="
                      loginForm.get('email')?.hasError(validation.type) &&
                      (loginForm.get('email')?.dirty ||
                        loginForm.get('email')?.touched)
                    "
                  >
                    {{ validation.message }}
                  </div>
                </ng-container>
              </div>
            </div>
  
            <div class="row mt-3">
              <div class="col">
                <label for="passwordInput" class="text-muted dp-input-label"
                  >Password</label
                >
                <input
                  [ngClass]="{
                    'dp-text-input': true,
                    'input-sm': true,
                    'form-control': true,
                    'is-invalid':
                      loginForm.get('password')?.errors &&
                      loginForm.get('password')?.touched
                  }"
                  formControlName="password"
                  type="password"
                  id="passwordInput"
                  aria-describedby="passwordInput"
                  placeholder="Password"
                />
                <ng-container
                  *ngFor="let validation of validationMessages.password"
                >
                  <div
                    class="invalid-feedback"
                    *ngIf="
                      loginForm.get('password')?.hasError(validation.type) &&
                      (loginForm.get('password')?.dirty ||
                        loginForm.get('password')?.touched)
                    "
                  >
                    {{ validation.message }}
                  </div>
                </ng-container>
              </div>
            </div>
  
            <div class="row mt-3">
              <div class="col-6">
                <div class="form-group form-check m-0 p-0">
                  <div class="form-group form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="dp-remember-check"
                    />
                    <label
                      class="form-check-label dp-input-label"
                      for="dp-remember-check"
                      >Remember me</label
                    >
                  </div>
                </div>
              </div>
              <div class="col-6 text-right">
                <a href="">Forgot your password?</a>
              </div>
  
              <div class="col">
                <button
                  type="button"
                  class="btn btn-primary btn-block dp-login-btn rounded-pill py-2"
                  (click)="onLogin()"
                >
                  Login
                </button>
                <div class="dp-start-half-section-container-width">
                  <p>
                    By clicking Login or continuing through a third party you
                    accept the fima
                    <a href="#"
                      >Terms of Use and acknowledge the Privacy Policy and Cookie
                      Policy</a
                    >.
                  </p>
                </div>
              </div>
            </div>
  
            <div class="row mt-3">
              <div class="col">
                <button
                  type="button"
                  class="btn btn-block dp-login-btn dp-login-google my-auto rounded-pill py-2"
                  (click)="loginWithGoogle()"
                >
                  <img src="assets/google.png" alt="" class="g-logo" />
                  Sign in with Google
                </button>
              </div>
            </div>
  
            <div class="row mt-5">
              <div class="col text-center">
                <hr class="db-login-hr-line" />
                <p>
                  Don’t have an account?
                  <a [routerLink]="'/start'" [queryParams]="{ register: true }"
                    >Sign up</a
                  >
                </p>
              </div>
            </div>
          </div>
        </form>
      </div>
  
      <div
        class="col-12  my-auto mx-auto text-center"
        *ngIf="!showLoginForm"
      >
        <div class="row">
          <div class="col dp-welcome-container text-center">
            <h1 class="dp-welcome-header">Welcome</h1>
            <p class="dp-welcome-subheader">Register your account</p>
          </div>
        </div>
  
        <!-- Register form -->
        <form [formGroup]="signUpForm">
          <div class="form-group m-0">
            <div class="row">
              <div class="col" style="padding-right: 4px;">
                <label for="nameInput" class="text-muted dp-input-label"
                  >Name</label
                >
                <input
                  [ngClass]="{
                    'dp-text-input': true,
                    'input-sm': true,
                    'form-control': true,
                    'is-invalid':
                      signUpForm.get('name')?.errors &&
                      signUpForm.get('name')?.touched
                  }"
                  formControlName="name"
                  type="text"
                  id="nameInput"
                  aria-describedby="nameInput"
                  placeholder="Your name"
                />
                <ng-container *ngFor="let validation of validationMessages.name">
                  <div
                    class="invalid-feedback"
                    *ngIf="
                      signUpForm.get('name')?.hasError(validation.type) &&
                      (signUpForm.get('name')?.dirty ||
                        signUpForm.get('name')?.touched)
                    "
                  >
                    {{ validation.message }}
                  </div>
                </ng-container>
              </div>
  
              <div class="col" style="padding-left: 4px;">
                <label for="surnameInput" class="text-muted dp-input-label"
                  >Surname</label
                >
                <input
                  [ngClass]="{
                    'dp-text-input': true,
                    'input-sm': true,
                    'form-control': true,
                    'is-invalid':
                      signUpForm.get('surname')?.errors &&
                      signUpForm.get('surname')?.touched
                  }"
                  formControlName="surname"
                  type="text"
                  id="surnameInput"
                  aria-describedby="surnameInput"
                  placeholder="Your surname"
                />
                <ng-container
                  *ngFor="let validation of validationMessages.surname"
                >
                  <div
                    class="invalid-feedback"
                    *ngIf="
                      signUpForm.get('surname')?.hasError(validation.type) &&
                      (signUpForm.get('surname')?.dirty ||
                        signUpForm.get('surname')?.touched)
                    "
                  >
                    {{ validation.message }}
                  </div>
                </ng-container>
              </div>
            </div>
  
            <div class="row mt-3">
              <div class="col">
                <label for="emailInput" class="text-muted dp-input-label"
                  >Email</label
                >
                <input
                  [ngClass]="{
                    'dp-text-input': true,
                    'input-sm': true,
                    'form-control': true,
                    'is-invalid':
                      signUpForm.get('email')?.errors &&
                      signUpForm.get('email')?.touched
                  }"
                  formControlName="email"
                  type="text"
                  id="emailInput"
                  aria-describedby="emailInput"
                  placeholder="your-email@example.com"
                />
                <ng-container *ngFor="let validation of validationMessages.email">
                  <div
                    class="invalid-feedback"
                    *ngIf="
                      signUpForm.get('email')?.hasError(validation.type) &&
                      (signUpForm.get('email')?.dirty ||
                        signUpForm.get('email')?.touched)
                    "
                  >
                    {{ validation.message }}
                  </div>
                </ng-container>
              </div>
            </div>
  
            <div class="row mt-3">
              <div class="col">
                <label for="passwordInput" class="text-muted dp-input-label"
                  >Password</label
                >
                <input
                  [ngClass]="{
                    'dp-text-input': true,
                    'input-sm': true,
                    'form-control': true,
                    'is-invalid':
                      signUpForm.get('password')?.errors &&
                      signUpForm.get('password')?.touched
                  }"
                  formControlName="password"
                  type="password"
                  id="passwordInput"
                  aria-describedby="passwordInput"
                  placeholder="Create password"
                />
                <ng-container
                  *ngFor="let validation of validationMessages.password"
                >
                  <div
                    class="invalid-feedback"
                    *ngIf="
                      signUpForm.get('password')?.hasError(validation.type) &&
                      (signUpForm.get('password')?.dirty ||
                        signUpForm.get('password')?.touched)
                    "
                  >
                    {{ validation.message }}
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
  
          <div class="row mt-3">
            <div class="col-12">
              <button
                type="button"
                class="btn btn-primary btn-block dp-login-btn mt-3 rounded-pill py-2"
                (click)="onRegister()"
              >
                Register
              </button>
            </div>
          </div>
  
          <div class="row mt-3">
            <div class="col text-left mb-3">
              <p>
                By clicking Register or registering through a third party you
                accept the fima
                <a href="#"
                  >Terms of Use and acknowledge the Privacy Policy and Cookie
                  Policy</a
                >.
              </p>
            </div>
            <div class="col-12">
              <button
                type="button"
                class="btn btn-block dp-login-btn dp-login-google my-auto rounded-pill py-2"
                (click)="registerWithGoogle()"
              >
                <img src="assets/google.png" alt="" class="g-logo" />
                Sign up with Google
              </button>
            </div>
          </div>
  
          <div class="row mt-5">
            <div class="col text-center">
              <hr class="db-login-hr-line" />
              <p>
                Already have an account?
                <a [routerLink]="'/start'" [queryParams]="{ login: true }"
                  >Sign In</a
                >
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

</div>