<div class="container-fluid">
  <ng-container>
    <div class="dp-header-area">
      <div class="row">
        <div class="col-auto my-auto">
          <h2 class="m-0">Settings</h2>
        </div>
        <div class="col text-right my-auto">
        </div>
      </div>
      <hr>

    </div>
    <div class="dp-content-area">
      <div class="row">
        <div class="col-3">
          <!-- <div class="row dp-settings-item active" >
            <div class="col-auto my-auto">
              <i class="ri-user-4-line"></i>
            </div>
            <div class="col-auto text-left my-auto p-0">
              <p class="m-0">Account settings</p>
            </div>
          </div>

          <div class="row dp-settings-item">
            <div class="col-auto my-auto">
              <i class="ri-team-line"></i>
            </div>
            <div class="col-auto text-left my-auto p-0">
              <p class="m-0">Members</p>
            </div>
          </div>

          <div class="row dp-settings-item">
            <div class="col-auto my-auto">
              <i class="ri-notification-2-line"></i>
            </div>
            <div class="col-auto text-left my-auto p-0">
              <p class="m-0">Notification settings</p>
            </div>
          </div>

          <div class="row dp-settings-item">
            <div class="col-auto my-auto">
              <i class="ri-lock-line"></i>
            </div>
            <div class="col-auto text-left my-auto p-0">
              <p class="m-0">Privacy settings</p>
            </div>
          </div> -->
          <div class="row dp-settings-item active">
            <div class="col-auto my-auto">
              <i class="ri-bill-line"></i>
            </div>
            <div class="col-auto text-left my-auto p-0">
              <p class="m-0">Billing and subscription</p>
            </div>
          </div>
        </div>

        <div class="col-9" style="border-left: 1px solid #ced4da;">
          <div class="pricing-header px-3 pb-2 mx-auto text-center">
            <h1 class="display-5">Pricing</h1>
            <!-- <p class="lead">Quickly build an effective pricing table for your potential customers with this Bootstrap
              example. It’s built with default Bootstrap components and utilities with little customization.
            </p> -->
            <nb-card class="dp-pricing-toggle-container">
              <nb-card-body class="dp-pricing-toggle">
                <nb-toggle status="info" [(ngModel)]="isYearly">Yearly</nb-toggle>
                <p class="dp-fima-promo" *ngIf="isYearly">Save 30%</p>
              </nb-card-body>
            </nb-card>
          </div>

          <div class="container">
            <div class="card-deck mb-3 text-center">
              <div class="card mb-4 shadow-sm dp-pricing-card">
                <div class="card-header">
                  <h4 class="my-0 font-weight-normal dp-fima-starter">Starter</h4>
                </div>
                <div class="card-body">
                  <h1 class="card-title pricing-card-title">Free</h1>
                  <ul class="list-unstyled mt-3 mb-4">
                    <li class="mb-3">Up to 5 projects</li>
                    <li class="mb-3">Unlimited integrations</li>
                    <li class="mb-3">Up to 3 members</li>
                  </ul>
                  <button type="button" disabled class="btn btn-lg btn-block btn-outline-primary">Current Plan</button>
                </div>
              </div>
              <div class="card mb-4 shadow-sm">
                <div class="card-header">
                  <h4 class="my-0 font-weight-normal dp-fima-pro">Pro</h4>
                </div>
                <div class="card-body">
                  <h1 class="card-title pricing-card-title">{{!isYearly ? '$10': '$84'}}</h1>
                  <ul class="list-unstyled mt-3 mb-4">
                    <li class="mb-3">Everything in starter and more</li>
                    <li class="mb-3">Unlimited projects</li>
                    <li class="mb-3">Up to 20 members</li>
                    <li class="mb-3">AI assistant</li>
                  </ul>
                  <button type="button" class="btn btn-lg btn-block btn-primary" (click)="buyFimaPro()">Get pro</button>
                </div>
              </div>
              <div class="card mb-4 shadow-sm">
                <div class="card-header">
                  <h4 class="my-0 font-weight-normal dp-fima-business">Business</h4>
                </div>
                <div class="card-body">
                  <h1 class="card-title pricing-card-title">Custom</h1>
                  <ul class="list-unstyled mt-3 mb-4">
                    <li class="mb-3">Everything in Pro and more</li>
                    <li class="mb-3">SSO</li>
                    <li class="mb-3">Private support</li>
                    <li class="mb-3">Custom onboarding</li>
                  </ul>
                  <button type="button" class="btn btn-lg btn-block btn-primary" (click)="buyFimaBusiness()">Get in
                    touch</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>