import { Pipe, PipeTransform } from '@angular/core';
import { DataTransferService } from '../services/@fima/data-transfer/data-transfer.service';
import { Artifact } from '../models/@fima/artifact.model';
import { Project } from '../models/@fima/project.model';

@Pipe({
  name: 'parseArtifactForProject'
})
export class ParseArtifactForProjectPipe implements PipeTransform {

  constructor(  
    private dataTransferService: DataTransferService
  ) {

  }

  /**
   *
   *
   * @param {(Artifact | any)} artifact
   * @param {...any[]} args
   * @return {*}  {Project}
   * @memberof ParseArtifactForProjectPipe
   */
  transform(artifact: Artifact | any, ...args: any[]): Project {
    if(!artifact) {
      return
    }
    return this.dataTransferService.$projects.value.filter((project) => {
      return project.id == artifact.projectId;
    }).shift() as Project;
  }

}
