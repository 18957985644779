import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseError } from 'firebase/app';
import { User } from 'src/app/@core/models/@fima/user.model';
import { FirebaseAuthService } from 'src/app/@core/services/@fima/auth/auth.service';
import { CacheService } from 'src/app/@core/services/@fima/cache/cache.service';
import { UtilsService } from 'src/app/@core/services/@fima/utils/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteComponent {

  /**
 *
 *
 * @memberof StartComponent
 */
  validationMessages = {
    email: [
      {
        type: 'required',
        message: 'This field is required.',
      },
      {
        type: 'email',
        message: 'Please enter a valid email address',
      },
    ],
    password: [
      {
        type: 'required',
        message: 'This field is required.',
      },
      {
        type: 'minlength',
        message: 'Minimum length is 8 characters.',
      },
    ],
    name: [
      {
        type: 'required',
        message: 'This field is required.',
      },
    ],
    surname: [
      {
        type: 'required',
        message: 'This field is required.',
      },
    ],
  };

  /**
   *
   *
   * @memberof StartComponent
   */
  showLoginForm = true;

  /**
   *
   *
   * @type {FormGroup}
   * @memberof StartComponent
   */
  loginForm: FormGroup;

  /**
   *
   *
   * @type {FormGroup}
   * @memberof StartComponent
   */
  signUpForm: FormGroup;

  /**
   *
   *
   * @type {boolean}
   * @memberof StartComponent
   */
  loading: boolean = false;

  /**
   *
   *
   * @memberof StartComponent
   */
  loginErrors: any;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: FirebaseAuthService,
    private utilitiesService: UtilsService,
    private cacheService: CacheService
  ) {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });

    this.signUpForm = this.formBuilder.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });

    // parse query params
    this.route.queryParams.subscribe(async (params) => {
      if (
        (params && (params as any).login == 'true') ||
        (params as any).login == undefined ||
        (params as any).register == undefined
      ) {
        this.showLoginForm = true;
      }

      if (params && (params as any).register == 'true') {
        this.showLoginForm = false;
      }

      // watch for query param called invitation that contains data about an invitation

      if (
        (params && (params as any).invite !== undefined) // <-- invitation exists 
      ) {
        //  extract the invite param value 

        const companyIdForInvite = (params as any).invite

        console.log('InviteCompanyId', companyIdForInvite);

        try {
          this.cacheService.storeItem(environment.cacheKeys.inviteCompanyId, companyIdForInvite)
        } catch (error) {
          console.log('StartComponent >> storeItem >> inviteCompanyId > error', error);
        }

      }

    });
  }

  /**
   *
   *
   * @memberof StartComponent
   */
  toggleRegisterOrLogin() {
    this.showLoginForm = !this.showLoginForm;
  }

  /**
   *
   *
   * @memberof StartComponent
   */
  async loginWithGoogle() {
    try {
      const user = await this.authService.continueWithGoogle();
      if (user) {
        this.router.navigate(['/portal/invite'], { replaceUrl: true });
      }
    } catch (error) {
      console.log('StartComponent >>  loginWithGoogle >> getUserProfile >> error >> ', error);
      this.authService.signOut();
    }


  }

  /**
   *
   *
   * @memberof StartComponent
   */
  async registerWithGoogle() {
    try {
      const createdUser = await this.authService.continueWithGoogle();
      if (createdUser) {
        this.router.navigate(['/portal/invite'], { replaceUrl: true });
      }
    } catch (error) {
      console.log('StartComponent >> registerWithGoogle >> error >> ', error);
      this.authService.signOut();
    }
  }

  /**
   *
   *
   * @memberof StartComponent
   */
  async onLogin() {
    try {
      this.loginErrors = undefined;

      if (this.utilitiesService.validateForm(this.loginForm).length > 0) {
        return;
      }

      this.loading = true;

      const loginResult = await this.authService.signInWithEmail(
        this.loginForm.controls['email'].value,
        this.loginForm.controls['password'].value
      );

      if (loginResult) {
        this.router.navigate(['/portal/invite'], { replaceUrl: true });
      }
    } catch (error: FirebaseError | any) {
      if ((error as FirebaseError).code == 'auth/user-not-found') {
        this.loginErrors =
          'Email address provided is not associated with any account, please register a new account.';
      } else {
        this.loginErrors = 'Invalid email or password.';
      }
    }

    this.loading = false;
  }

  /**
   *
   *
   * @memberof StartComponent
   */
  async onRegister() {

    this.loginErrors = undefined;

    try {
      if (this.utilitiesService.validateForm(this.signUpForm).length > 0) {
        return;
      }

      this.loading = true;

      // Form is valid beyond this point, register user

      const createUser: User = {
        companyId: null,
        createdAt: new Date().getTime(),
        updatedAt: new Date().getTime(),
        name: this.signUpForm.controls['name'].value,
        surname: this.signUpForm.controls['surname'].value,
        email: this.signUpForm.controls['email'].value,
        password: this.signUpForm.controls['password'].value,
        roles: [],
        hasSeenOnboarding: false,
        subscribedToProductUpdates: false,
        hasCreatedProject: false,
        profilePictureUrl: null
      };

      const registerUserResult = await this.authService.registerWithEmail(createUser);

      if (registerUserResult) {
        this.router.navigate(['/portal/invite'], { replaceUrl: true });
      }
    } catch (error) {
      console.log('StartComponent >> onRegister >> error ', error);
      if ((error as FirebaseError).code == 'auth/email-already-in-use') {
        this.loginErrors =
          'Email address provided is already in use by another account, please login.';
      } else {
        this.loginErrors = 'Failed to register your account, please try again.';
      }
    }

    this.loading = false;
  }

}
