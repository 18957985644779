import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from 'firebase/auth';
import { map } from 'rxjs';
import { TaigaRole } from 'src/app/@core/models/@taiga/role.model';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class TaigaRoleService {

    constructor(private http: HttpClient) { }

    getRolesForProject(projectId: number): Promise<TaigaRole[]> {
        return new Promise(async (resolve, reject) => {
            this.http.get<TaigaRole[]>(
                `${environment.taigaApiUrl}/roles?project=${projectId}`
            ).pipe(map(async (roles) => {
                return resolve(roles)
            })).subscribe({
                next: (v) => { },
                error: (e) => {
                    return reject(e)
                },
                complete: () => { }
            })

        })
    }
}
