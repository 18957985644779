import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Project } from 'src/app/@core/models/@fima/project.model';
import { ProjectService } from 'src/app/@core/services/@fima/project/project.service';

@Component({
  selector: 'app-export',
  templateUrl: './project_settings.component.html',
  styleUrls: ['./project_settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectSettingsComponent {

  project!: Project;
  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectService
  ) {
    this.route.queryParams.subscribe(async (params) => {
      if (params && (params as any).id) {
        try {
         // Fetch project here
        } catch (error) { }
      }
    });

  }

  ngOnInit() { }
}
