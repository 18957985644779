import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseAuthService } from 'src/app/@core/services/@fima/auth/auth.service';
import { CacheService } from 'src/app/@core/services/@fima/cache/cache.service';
import { UtilsService } from 'src/app/@core/services/@fima/utils/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss']
})
export class InviteResolvedComponent {

  /**
   *
   *
   * @memberof InviteResolvedComponent
   */
  validationMessages = {
    email: [
      {
        type: 'required',
        message: 'This field is required.',
      },
      {
        type: 'email',
        message: 'Please enter a valid email address',
      },
    ],
    password: [
      {
        type: 'required',
        message: 'This field is required.',
      },
      {
        type: 'minlength',
        message: 'Minimum length is 8 characters.',
      },
    ],
    name: [
      {
        type: 'required',
        message: 'This field is required.',
      },
    ],
    surname: [
      {
        type: 'required',
        message: 'This field is required.',
      },
    ],
  };


  /**
   *
   *
   * @type {boolean}
   * @memberof InviteResolvedComponent
   */
  loading: boolean = false;

  /**
   *
   *
   * @memberof InviteResolvedComponent
   */
  loginErrors: any;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: FirebaseAuthService,
    private utilitiesService: UtilsService,
    private cacheService: CacheService
  ) {


    // parse query params
    this.route.queryParams.subscribe(async (params) => {
      // watch for query param called invitation that contains data about an invitation
      if (
        (params && (params as any).invite !== undefined) // <-- invitation exists 
      ) {
        //  extract the invite param value 

        const companyIdForInvite = (params as any).invite

        console.log('InviteCompanyId', companyIdForInvite);

        try {
          this.cacheService.storeItem(environment.cacheKeys.inviteCompanyId, companyIdForInvite)
        } catch (error) {
          console.log('InviteResolvedComponent >> storeItem >> inviteCompanyId > error', error);
        }

      }

    });
  }


}
