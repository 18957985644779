import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { TaigaUser } from 'src/app/@core/models/@taiga/user.model';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TaigaUserService {

    constructor(private http: HttpClient) { }

    resolveUser(userId: number): Promise<TaigaUser> {
        return new Promise<TaigaUser>((resolve, reject) => {
            this.http.get<TaigaUser>(`${environment.taigaApiUrl}/users/${userId}`)
                .pipe(map(async (user) => {
                    return resolve(user)
                })).subscribe({
                    next: (v) => { },
                    error: (e) => {
                        return reject(e)
                    },
                    complete: () => { }
                })
        })

    }


}
