import {
  Artifact,
  ParentHeaderEnum,
} from 'src/app/@core/models/@fima/artifact.model';

import { v4 as uuidv4 } from 'uuid';


export const projectArtifactOptions: Artifact[] = [
  {
    id: uuidv4(),
    templateId: 'kick-off-meeting.template.json',  
    parent: ParentHeaderEnum.DISCOVER,
    icon: '🗓️',
    label: 'Kick-off meeting',
    aside:
      'A stakeholder kickoff is done at the beginning of a project. During a stakeholder kickoff you invite the people that take part in this project, such as the PM and designer of a product team. During this meeting your focus is to gather as much information as needed to build a project plan. This kickoff will help kickstart your project. This is a template you can use for this.',

    link: '',
    showOpts: false,
    artifactPropTree: {},
    status: null,
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
  },
  {
    id: uuidv4(),
    templateId: 'kick-off-meeting.template.json',  
    parent: ParentHeaderEnum.DISCOVER,
    icon: '🔍',
    label: 'Secondary research',
    aside:
      'Secondary research involves the summary, collation, and/or synthesis of existing research rather than primary research where data is collected from first-hand experience or observations. This is a template you can use for secondary research.',

    link: '',
    showOpts: false,
    artifactPropTree: {},
    status: null,
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
  },
  {
    id: uuidv4(),
    templateId: 'kick-off-meeting.template.json',  
    parent: ParentHeaderEnum.DISCOVER,
    icon: '🔍',
    label: 'UX benchmarking',
    link: '',
    aside:
      'UX Benchmarking involves measuring the usability or performance of a website, product, or service over time or in comparison to the competition. This allows for the establishment of a ‘benchmark’ or standard for comparison.',

    showOpts: false,
    artifactPropTree: {},
    status: null,
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
  },
  {
    id: uuidv4(),
    parent: ParentHeaderEnum.DISCOVER,
    icon: '🔍',
    label: 'Heuristic evaluation',
    aside:
      'Heuristic evaluation involves evaluating a product’s user interface based on established usability principles, or heuristics.',

    link: '',
    showOpts: false,
    artifactPropTree: {},
    status: null,
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
  },
  {
    id: uuidv4(),
    templateId: 'kick-off-meeting.template.json',  
    parent: ParentHeaderEnum.DISCOVER,
    icon: '🔍',
    label: 'Stakeholder interviews',
    aside:
      'Stakeholder interviews provide insights into the various expectations and concerns that stakeholders may have regarding a project. They help to understand and align the project objectives with stakeholder needs.',
    link: '',
    showOpts: false,
    artifactPropTree: {},
    status: null,
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
  },
  {
    id: uuidv4(),
    templateId: 'vision-workshop.template.json',  
    parent: ParentHeaderEnum.DEFINE,
    icon: '🗓️',
    label: 'Vision workshop',
    aside:
      'A Vision Workshop brings together stakeholders to align on the project’s vision, objectives, and priorities. It’s a valuable step in project planning and execution.',

    link: '',
    showOpts: false,
    artifactPropTree: {},
    status: null,
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
  },
  {
    id: uuidv4(),
    templateId: 'kick-off-meeting.template.json',  
    parent: ParentHeaderEnum.DEFINE,
    icon: '🧑‍🍳',
    label: 'Design thinking',
    aside:
      'Design Thinking is a problem-solving approach that involves empathy, experimentation, and iteration to meet user needs and create successful solutions.',

    link: '',
    showOpts: false,
    artifactPropTree: {},
    status: null,
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
  },
  {
    id: uuidv4(),
    templateId: 'kick-off-meeting.template.json',  
    parent: ParentHeaderEnum.DEFINE,
    icon: '💭',
    label: 'Brainstorming',
    aside:
      'Brainstorming is a creative problem-solving method that involves the spontaneous contribution of ideas to find a solution to a problem.',

    link: '',
    showOpts: false,
    artifactPropTree: {},
    status: null,
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
  },
  {
    id: uuidv4(),
    templateId: 'kick-off-meeting.template.json',  
    parent: ParentHeaderEnum.DEFINE,
    icon: '🎬',
    label: 'Storyboarding',
    aside:
      'Storyboarding is a method of visually depicting a user’s journey through a product to help understand the context, process, and user emotions at each step.',

    link: '',
    showOpts: false,
    artifactPropTree: {},
    status: null,
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
  },
  {
    id: uuidv4(),
    templateId: 'kick-off-meeting.template.json',  
    parent: ParentHeaderEnum.DEFINE,
    icon: '📝',
    label: 'Paper prototype',
    aside:
      'Paper prototyping is a method of creating initial interfaces for testing. It involves creating hand-drawn representations of user interfaces which are quick, cheap, and easy to adjust.',

    link: '',
    showOpts: false,
    artifactPropTree: {},
    status: null,
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
  },
  {
    id: uuidv4(),
    templateId: 'kick-off-meeting.template.json',  
    parent: ParentHeaderEnum.DESIGN_AND_PROTOTYPE,
    icon: '🧩',
    label: 'UI components',
    aside:
      'UI components are the building blocks of a user interface. They provide consistency across different parts of a product, making it easier for users to understand how to interact with it.',

    link: '',
    showOpts: false,
    artifactPropTree: {},
    status: null,
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
  },
  {
    id: uuidv4(),
    templateId: 'kick-off-meeting.template.json',  
    parent: ParentHeaderEnum.DESIGN_AND_PROTOTYPE,
    icon: '🛠️',
    label: 'Design specifications',
    aside:
      'Design specifications provide detailed information about the design of a product, including visual details and interaction behaviors. They are used by developers to implement the design accurately.',

    link: '',
    showOpts: false,
    artifactPropTree: {},
    status: null,
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
  },
  {
    id: uuidv4(),
    templateId: 'kick-off-meeting.template.json',  
    parent: ParentHeaderEnum.DESIGN_AND_PROTOTYPE,
    icon: '🎨',
    label: 'UI design',
    aside:
      'UI design involves creating the visual elements of a product’s interface, including layout, colors, typography, and interactive elements.',

    link: '',
    showOpts: false,
    artifactPropTree: {},
    status: null,
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
  },
  {
    id: uuidv4(),
    templateId: 'kick-off-meeting.template.json',  
    parent: ParentHeaderEnum.DESIGN_AND_PROTOTYPE,
    icon: '🖼️',
    label: 'High-fidelity prototypes',
    aside:
      'High-fidelity prototypes are interactive mockups of the product that simulate the final design and functionality. They’re useful for user testing and getting stakeholder buy-in.',

    link: '',
    showOpts: false,
    artifactPropTree: {},
    status: null,
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
  },
  {
    id: uuidv4(),
    templateId: 'kick-off-meeting.template.json',  
    parent: ParentHeaderEnum.DESIGN_AND_PROTOTYPE,
    icon: '✅',
    label: 'Card sorting',
    aside:
      'Card sorting is a user-centered design method for increasing a system’s findability. Participants organize topics into categories that make sense to them and label these groups.',

    link: '',
    showOpts: false,
    artifactPropTree: {},
    status: null,
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
  },
  {
    id: uuidv4(),
    templateId: 'kick-off-meeting.template.json',  
    parent: ParentHeaderEnum.EVALUATE_AND_MEASURE,
    icon: '🧪',
    label: 'A/B testing',
    aside:
      'A/B testing is a method of comparing two versions of a webpage or other user experience to determine which performs better. It is a way to test changes to your webpage against the current design and determine which one produces better results.',

    link: '',
    showOpts: false,
    artifactPropTree: {},
    status: null,
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
  },
  {
    id: uuidv4(),
    templateId: 'kick-off-meeting.template.json',  
    parent: ParentHeaderEnum.EVALUATE_AND_MEASURE,
    icon: '⚖️',
    label: 'Usability scales',
    aside:
      'Usability scales are methods for assessing a product’s ease of use, satisfaction, and efficiency from the user’s perspective.',

    link: '',
    showOpts: false,
    artifactPropTree: {},
    status: null,
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
  },
  {
    id: uuidv4(),
    templateId: 'kick-off-meeting.template.json',  
    parent: ParentHeaderEnum.EVALUATE_AND_MEASURE,
    icon: '👩‍💻',
    label: 'Usage analytics',
    aside:
      'Usage analytics involves tracking and analyzing how users interact with a product to identify trends and opportunities for improvement.',

    link: '',
    showOpts: false,
    artifactPropTree: {},
    status: null,
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
  },
] as Artifact[];
