import { Injectable } from '@angular/core';

import { messages } from './messages';
import { botReplies, gifsLinks, imageLinks } from './bot-replies';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs';
import { FirebaseAuthService } from 'src/app/@core/services/@fima/auth/auth.service';
import { ProfileService } from 'src/app/@core/services/@fima/profile/profile.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ChatShowcaseService {


  constructor(
    private httpClient: HttpClient,
    private firebaseAuthService: FirebaseAuthService,
    private profileService: ProfileService
  ) {
    
  }


  loadMessages() {
    return [];
  }

  loadBotReplies() {
    return botReplies;
  }

  async reply(message: string) {
    // const botReply: any =  this.loadBotReplies()
    //   .find((reply: any) => message.search(reply.regExp) !== -1);

    // if (botReply.reply.type === 'quote') {
    //   botReply.reply.quote = message;
    // }

    // if (botReply.type === 'gif') {
    //   botReply.reply.files[0].url = gifsLinks[Math.floor(Math.random() * gifsLinks.length)];
    // }

    // if (botReply.type === 'pic') {
    //   botReply.reply.files[0].url = imageLinks[Math.floor(Math.random() * imageLinks.length)];
    // }

    // if (botReply.type === 'group') {
    //   botReply.reply.files[1].url = gifsLinks[Math.floor(Math.random() * gifsLinks.length)];
    //   botReply.reply.files[2].url = imageLinks[Math.floor(Math.random() * imageLinks.length)];
    // }

    // botReply.reply.text = botReply.answerArray[Math.floor(Math.random() * botReply.answerArray.length)];
    // return { ...botReply.reply };


    const result = await this.promptSema(message);

    console.log(result)

    const botAvatar: string = 'https://s3.amazonaws.com/pix.iemoji.com/images/emoji/apple/ios-12/256/robot-face.png';


    const reply ={
      text: result.response,
      reply: false,
      date: new Date(),
      type: 'text',
      // files: [
      //   {
      //     url: '',
      //     type: 'image/gif',
      //   },
      // ],
      user: {
        name: 'Sema',
        avatar: botAvatar,
      },
    }


    return  reply
  }



  /**
   *
   *
   * @param {string} message
   * @return {*}  {Promise<any>}
   * @memberof ChatShowcaseService
   */
  promptSema(message: string): Promise<any> {
    return new Promise(async (resolve, reject) => {

      const firebaseUser = await this.firebaseAuthService.auth.currentUser;
      const user = await this.profileService.getUserProfile(firebaseUser.uid)

      const result = this.httpClient.post(environment.semaApiUrl, {
        user_id: `${user.taigaUserId}`,
        prompt :message
      }).pipe(map(async (project) => {
        return resolve(project)
      })).subscribe({
        next: (v) => { },
        error: (e) => {
          return reject(e)
        },
        complete: () => { }
      })
    })
  }
}