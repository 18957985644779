import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateTaigaProject, TaigaProject } from 'src/app/@core/models/@taiga/project.model';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TaigaProjectService {
  constructor(private http: HttpClient) { }


  /**
   *
   *
   * @param {number} projectId
   * @return {*}  {Promise<TaigaProject>}
   * @memberof TaigaProjectService
   */
  getProjectPriorities(projectId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(
        `${environment.taigaApiUrl}/priorities?project=${projectId}`
      ).subscribe({
        next: project => resolve(project),
        error: err => reject(err)
      });
    });
  }


  /**
   *
   *
   * @param {number} projectId
   * @param {number} userStoryId
   * @return {*}  {Promise<any[]>}
   * @memberof TaigaProjectService
   */
  getUProjectTaskStatuses(projectId: number, userStoryId: number): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.http.get<any[]>(`${environment.taigaApiUrl}/task-statuses?project=${projectId}&user_story=${userStoryId}`)
        .pipe(map(async (userStoryTasks) => {
          return resolve(userStoryTasks)
        })).subscribe({
          next: (v) => { },
          error: (e) => {
            return reject(e)
          },
          complete: () => { }
        })
    });
  }

  /**
   *
   *
   * @param {TaigaProject} taigaProject
   * @return {*}  {Promise<TaigaProject>}
   * @memberof TaigaProjectService
   */
  createTaigaProject(taigaProject: CreateTaigaProject): Promise<TaigaProject> {
    return new Promise(async (resolve, reject) => {


      const KAN_BAN_PROJECT_TEMPLATE = 2;

      taigaProject["creation_template"] = KAN_BAN_PROJECT_TEMPLATE;
      taigaProject["is_backlog_activated"] = true
      taigaProject["is_issues_activated"] = true
      taigaProject["is_kanban_activated"] = true
      taigaProject["is_private"] = true
      taigaProject["is_wiki_activated"] = true

      this.http.post<TaigaProject>(
        `${environment.taigaApiUrl}/projects`,
        taigaProject
      ).pipe(map(async (project) => {
        return resolve(project)
      })).subscribe({
        next: (v) => { },
        error: (e) => {
          return reject(e)
        },
        complete: () => { }
      })

    })
  }

  /**
   *
   *
   * @param {number} projectId
   * @return {*}  {Promise<TaigaProject>}
   * @memberof TaigaProjectService
   */
  getProject(projectId: number): Promise<TaigaProject> {
    return new Promise((resolve, reject) => {
      this.http.get<TaigaProject>(
        `${environment.taigaApiUrl}/projects/${projectId}`
      ).subscribe({
        next: project => resolve(project),
        error: err => reject(err)
      });
    });
  }

  /**
   *
   *
   * @param {*} projectId
   * @param {*} taigaProject
   * @return {*} 
   * @memberof TaigaProjectService
   */
  updateTaigaProject(projectId, taigaProject): Promise<TaigaProject> {
    return new Promise((resolve, reject) => {
      this.http.put<TaigaProject>(
        `${environment.taigaApiUrl}/projects/${projectId}`, taigaProject
      ).subscribe({
        next: project => resolve(project),
        error: err => reject(err)
      });
    });
  }

  /**
   *
   *
   * @param {number} projectId
   * @return {*} 
   * @memberof TaigaProjectService
   */
  deleteTaigaProject(projectId: number) {
    return new Promise((resolve, reject) => {
      this.http.delete<TaigaProject>(
        `${environment.taigaApiUrl}/projects/${projectId}`
      ).subscribe({
        next: project => resolve(project),
        error: err => reject(err)
      });
    });
  }


  /**
   *
   *
   * @param {string} userId
   * @param {number} maxProjects
   * @return {*}  {Promise<any>}
   * @memberof TaigaProjectService
   */
  getTaigaProjects(maxProjects?: number): Promise<TaigaProject[]> {
    return new Promise(async (resolve, reject) => {
      const data = await this.http.get<TaigaProject[]>(`${environment.taigaApiUrl}/projects?is_kanban_activated=true`)
        .pipe(map(async (projects) => {
          return resolve(projects)
        })).subscribe({
          next: (v) => { },
          error: (e) => {
            return reject(e)
          },
          complete: () => { }
        })
    })

  }


}
