import { TaigaProject } from '../@taiga/project.model';
import { Artifact } from './artifact.model';

export interface Project extends TaigaProject {
  id: any;
  createdBy: string; // id of the user that created this team
  ownerCompanyId: string;
  createdAt: Date | number;
  updatedAt: Date | number;
  endDate?: Date | number | null;
  status?: STATUS | STATUS.NOT_STARTED;
  projectThumbnailUrl: string;
  projectName: string;
  projectGoal: string;
  projectIntegrations: any[];
  projectArtifacts: any[];
  ckEditorDataRaw?: string;
  invitedUsers: string[]
  inviteAllCompany?: boolean;
  users: string[];
  // Future implementation
  uid?: string;
}
export enum STATUS {
  NOT_STARTED = '1',
  READY = '2',
  IN_PROGRESS = '3',
  REVIEW = '4',
  DONE = '5',
  ARCHIVED = '6',
}
