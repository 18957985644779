<app-loader *ngIf="loading"></app-loader>

<div class="container d-flex flex-column justify-content-center" style="height: 89vh;">
  <div class="row">
    <div class="col">
      <form [formGroup]="onboardingForm">
        <nb-card>
          <nb-card-body>
            <nb-stepper orientation="horizontal" [disableStepNavigation]="true">
              <nb-step [label]="labelOne">
                <ng-template #labelOne>Welcome</ng-template>
                <div class="mx-5">
                  <div class="d-flex">

                    <div class="">
                      <img src="assets/logo-hires.png" alt="" width="84px" nbButton />
                      <h4 class="mt-4 dp-header-text">Welcome to fima</h4>
                    </div>
                  </div>

                  <p class="dp-sub-header-text text-muted">
                    The ultimate collaboration suite
                  </p>
                  <div class="row mb-4">
                    <div class="col text-left">
                      <label for="companyNameInput" class="dp-input-label">Company name</label>
                      <input data-intro="Please enter a company name, this will be used as your org name inside fima."
                        [ngClass]="{
                          'dp-text-input': true,
                          'input-sm': true,
                          'form-control': true,
                          'is-invalid':
                            onboardingForm.get('companyName')?.errors &&
                            onboardingForm.get('companyName')?.touched,
                          'is-valid':
                            !onboardingForm.get('companyName')?.errors &&
                            onboardingForm.get('companyName')?.touched
                        }" [value]="onboardingForm.get('companyName')?.value" formControlName="companyName" type="text"
                        id="companyNameInput" aria-describedby="companyNameInput" placeholder="" />
                      <ng-container *ngFor="
                          let validation of validationMessages.companyName
                        ">
                        <div class="invalid-feedback" *ngIf="
                            onboardingForm
                              .get('companyName')
                              ?.hasError(validation.type) &&
                            (onboardingForm.get('companyName')?.dirty ||
                              onboardingForm.get('companyName')?.touched)
                          ">
                          {{ validation.message }}
                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <button class="dp-open-fima" nbButton [disabled]="onboardingForm.get('companyName')?.invalid"
                    nbStepperNext (click)="onCreateCompany()">
                    Open fimaAI
                  </button>
                </div>
              </nb-step>
            </nb-stepper>
          </nb-card-body>
        </nb-card>
      </form>
    </div>
  </div>
</div>