<div class="row">
  <div class="col">
    <nb-tabset>
      <nb-tab tabTitle="General" class="dp-side-panel-section">

        <div class="row">
          <div class="col text-center">
            <div class="row my-3">
              <div class="col">
                <div class="row">
                  <div class="col-5 my-auto dp-info-pl pr-3">
                    <div class="row">
                      <div class="col-auto my-auto">
                        <p class="m-0 dp-info-px text-muted" (click)="toggleActionsOverlay(actions.ADD_USERS)">Assignee
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto my-auto px-0" *ngIf="
                  artifact?.assignees && artifact?.assignees?.length > 0;
                  else unAssigned
                ">
                    <div class="ml-3 d-flex">
                      <ng-container *ngFor="let assignee of artifact?.assignees ; index as i">
                        <!-- Next two users stacked -->
                        <nb-user *ngIf="i >= 0 && i < 4" style="margin-left: -12px !important" [size]="'small'"
                          [picture]="''" [name]="(assignee | resolveTaigaUserId | async)?.full_name_display"
                          [nbTooltip]="(assignee | resolveTaigaUserId | async)?.full_name_display" [color]="'#f2cece'"
                          [showName]="false">
                        </nb-user>
                        <!-- Additional users -->
                        <nb-user *ngIf="i >= 5 && i < 6" style="margin-left: -12px !important" [size]="'small'"
                          [picture]="''" [showName]="false" [name]="'+ ' + ( artifact?.assignees.length - 5 )"
                          [color]="'#e0cef2'" [showInitials]="true">
                        </nb-user>
                      </ng-container>
                    </div>
                  </div>
                  <ng-template #unAssigned>
                    <div class="col-auto my-auto px-0">
                      <nb-user [showName]="false" [size]="'small'" [picture]="''" [name]="'Unassigned'">
                      </nb-user>
                    </div>
                    <div class="col my-auto pl-2 text-left">
                      <p class="m-0">Unassigned</p>
                    </div>
                  </ng-template>
                </div>

                <div class="row mt-3">
                  <div class="col-5 my-auto dp-info-pl pr-3">
                    <div class="row">
                      <div class="col-auto my-auto">
                        <p class="m-0 dp-info-px text-muted">Reporter</p>
                      </div>
                      <!-- <div class="col-auto my-auto p-0">
                    <i class="ri-pencil-fill btn btn-clear px-0 py-0"></i>
                  </div> -->
                    </div>
                  </div>
                  <div class="col-auto my-auto px-0">
                    <div class="d-flex">
                      <nb-user [size]="'small'" [picture]="user ? user.profilePictureUrl : ''" [name]="user.name" [showName]="false">
                      </nb-user>
                    </div>
                  </div>

                  <div class="col my-auto pl-2 text-left">
                    <p class="m-0">
                      {{artifact?.owner_extra_info? artifact?.owner_extra_info?.full_name_display: user?.name }}
                    </p>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-5 my-auto dp-info-pl pr-3">
                    <div class="row">
                      <div class="col-auto my-auto">
                        <p class="m-0 dp-info-px text-muted" (click)="toggleActionsOverlay(actions.CHANGE_STATUS)">
                          Status</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto my-auto px-0">
                    <div class="d-flex">
                      <div>
                        <img src="assets/integration-icons/status-in-todo.svg" *ngIf="
                        artifact?.status == statusEnum.NOT_STARTED ||
                        artifact?.status == null
                      " alt="" />
                        <img src="assets/integration-icons/status-in-progress.svg"
                          *ngIf="artifact?.status == statusEnum.IN_PROGRESS" alt="" />
                        <img src="assets/integration-icons/status-done.svg" *ngIf="artifact?.status == statusEnum.DONE"
                          alt="" />
                      </div>
                    </div>
                  </div>

                  <div class="col my-auto pl-3 text-left">
                    <p class="m-0">{{ translateStatusValue(artifact?.status) }}</p>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-5 my-auto dp-info-pl pr-3">
                    <div class="row">
                      <div class="col-auto my-auto">
                        <p class="m-0 dp-info-px text-muted" (click)="toggleActionsOverlay(actions.CHANGE_PRIORITY)">
                          Priority</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto my-auto px-0">
                    <div class="d-flex">
                      <i class="ri-flag-fill dp-info-icons"></i>
                    </div>
                  </div>

                  <div class="col my-auto pl-3 text-left">
                    <p class="m-0">-- No priority --</p>
                  </div>
                </div>

                <div class="row mt-3">
                  <div class="col-5 my-auto dp-info-pl pr-3">
                    <div class="row">
                      <div class="col-auto my-auto">
                        <p class="m-0 dp-info-px text-muted" (click)="toggleActionsOverlay(actions.CHANGE_DUE_DATE)">Due
                          Date</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto my-auto px-0">
                    <div class="d-flex">
                      <i class="ri-time-line dp-info-icons"></i>
                    </div>
                  </div>

                  <div class="col my-auto pl-3 text-left">
                    <p class="m-0">{{artifact?.due_date ? artifact?.due_date : '-- Not set --'}}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <hr>
              </div>
            </div>

            <div class="dp-assign-tool">
              <div class="row">
                <div class="col-auto my-auto">
                  <p class="m-0">Subtasks</p>
                </div>
                <div class="col text-right my-auto">
                  <button class="btn btn-outline-primary rounded-pill btn-sm"
                    (click)="toggleActionsOverlay(actions.ADD_SUB_TASK)">
                    + Add subtask
                  </button>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <hr>
              </div>
            </div>

            <div class="dp-assign-tool">
              <div class="row">
                <div class="col-auto my-auto">
                  <p class="m-0">Recent Activity</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nb-tab>
      <nb-tab tabTitle="Comments" *ngIf="showCommentSection && (artifact && artifact.project)">
        <!-- Add comments UI -->
        <div class="dp-assign-tool dp-comment-container">
          <dp-comment-box [user]="user" [project]="project" [artifact]="artifact"></dp-comment-box>
        </div>

        <!--View comments UI-->
        <div *ngFor="
            let comment of dataTransferService.$comments
              | async
              | filterComments : project : artifact;
            index as i
          " class="dp-assign-tool dp-comment-bubble mb-3">
          <dp-comment-view [comments]="
              dataTransferService.$comments
                | async
                | filterComments : project : artifact
            " [comment]="comment" [commentIndex]="i"></dp-comment-view>
        </div>
      </nb-tab>
    </nb-tabset>
  </div>
</div>

<!-- Actions overlay -->
<app-actions-overlay *ngIf="showActionsOverlay" (onCloseBtnClick)="closeActionsOverlay($event)">
  <ng-container actions-main-area>

    <!-- Invite users action -->
    <div class="row" *ngIf="activatedAction == actions.ADD_USERS">
      <div class="col-12">
        <h4 class="mb-4">Select assigned users</h4>
      </div>
      <div class="col text-center">
        <dp-invite-users></dp-invite-users>
      </div>
      <div class="col-12 dp-actions-btn-container">
        <hr>
        <div class="dflex">
          <button class="btn btn-primary rounded-pill px-5">Save</button>
        </div>
      </div>
    </div>

    <!-- Change status action -->
    <div class="row" *ngIf="activatedAction == actions.CHANGE_STATUS">
      <div class="col-12">
        <div class="row">
          <div class="col-auto my-auto">
            <h4 class="mb-4">Update status</h4>
            <nb-radio-group [(ngModel)]="statusOption">
              <nb-radio
                *ngFor="let option of statusOptions"
                [value]="option.id">
                {{ option.name }}
              </nb-radio>
            </nb-radio-group>        
          </div>
          <div class="col text-center my-auto">

          </div>
        </div>
      </div>
      <div class="col-12 dp-actions-btn-container">
        <hr>
        <div class="dflex">
          <button class="btn btn-primary rounded-pill px-5">Save</button>
        </div>
      </div>
    </div>

    <!-- Change priority -->
    <div class="row" *ngIf="activatedAction == actions.CHANGE_PRIORITY">
      <div class="col-12">
        <div class="row">
          <div class="col-auto my-auto">
            <h4 class="mb-4">Update priority</h4>
            <nb-radio-group [(ngModel)]="priorityOption">
              <nb-radio
                *ngFor="let option of priorityOptions"
                [value]="option.id">
                {{ option.name }}
              </nb-radio>
            </nb-radio-group> 
          </div>
          <div class="col text-center my-auto">

          </div>
        </div>
      </div>
      <div class="col-12 dp-actions-btn-container">
        <hr>
        <div class="dflex">
          <button class="btn btn-primary rounded-pill px-5">Save</button>
        </div>
      </div>
    </div>

    <!-- Change due date action  -->
    <div class="row" *ngIf="activatedAction == actions.CHANGE_DUE_DATE">
      <div class="col-12">
        <div class="row">
          <div class="col-auto my-auto">
            <h4 class="mb-4">Update due date</h4>
            <nb-calendar size="large" [(date)]="date">
            </nb-calendar>
          </div>
          <div class="col text-center my-auto">
            <h4 class="text-muted">You have no other due tasks on this date</h4>
          </div>
        </div>
      </div>
      <div class="col-12 dp-actions-btn-container">
        <hr>
        <div class="dflex">
          <button class="btn btn-primary rounded-pill px-5">Save</button>
        </div>
      </div>
    </div>

    <!-- Add/Edit subtask action  -->
    <div class="row" *ngIf="activatedAction == actions.ADD_SUB_TASK">
      <div class="col">
        <div class="row mb-4">
          <div class="col">
            <h4>Tasks</h4>
          </div>
          <div class="col-auto">
          </div>
        </div>
      
        <div class="row dp-task-row my-4">
          <div class="col my-auto">
           <!-- subtask input -->
           <input placeholder="Type a new task subject" [fullWidth]="true" nbInput>
          </div>
          <div class="col-auto my-auto">
           <!-- Set status -->
           <nb-select placeholder="Set status " [(selected)]="selectedStatus">
              <nb-option *ngFor="let option of statusOptions" [value]="option.id">{{option?.name}}</nb-option>
            </nb-select>
          </div>
          <div class="col-auto my-auto">
            <!-- Assign user -->
            <nb-select placeholder="Assignee" [(selected)]="selectedAssignee">
              <nb-option *ngFor="let option of [
                
              ]" [value]="option.id">

              <nb-user class="p-0 m-0" [size]="'medium'"
              [picture]="''" 
              [name]="option?.name"
              [nbTooltip]="option?.name"
              [showName]="true">
              </nb-user>
              <!-- {{option?.name}} -->
            </nb-option>
            </nb-select>
          </div>

          <div class="col-auto my-auto">
            <!-- <button class="btn btn-clear glow border-0">
              <img src="assets/remix-icons/save-2-fill.svg" alt="" width="18px" style="margin-bottom: 3px;">
          </button> -->
          <button class="btn btn-outline-primary rounded-pill btn-sm px-4">
            + Add
        </button>
          </div>
        </div>

        <div class="row dp-task-row" *ngFor="let task of tasks">
          <div class="col-auto my-auto">
            <button class="btn btn-clear border-0" >
              <img src="assets/remix-icons/draggable.svg" alt="" width="18px" style="margin-bottom: 3px;">
          </button>
          </div>
          <div class="col my-auto">
            <p class="m-0">{{task?.subject}}</p>
          </div>
          <div class="col-auto my-auto">
            <p class="m-0">{{task?.status_extra_info?.name }}</p>
          </div>

          <div class="col-auto my-auto">
            <nb-user class="p-0 m-0" [size]="'medium'"
              [picture]="task?.owner_extra_info?.gravatar_id" 
              [name]="task?.owner_extra_info?.full_name_display"
              [nbTooltip]="task?.owner_extra_info?.full_name_display"
              [showName]="true">
            </nb-user>
          </div>
        </div>
      </div>
      <div class="col-12 dp-actions-btn-container">
        <hr>
        <div class="dflex">
          <button class="btn btn-primary rounded-pill px-5">Save</button>
        </div>
      </div>
    </div>
  </ng-container>
</app-actions-overlay>