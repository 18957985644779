<app-loader *ngIf="loading"></app-loader>

<ng-container *ngIf="hasProjects">
  <div class="container-fluid">
    <div class="dp-header-area">
      <div class="row">
        <div class="col-auto my-auto">
          <h2 class="m-0">
            {{ !project ? "" : project?.projectName }}
          </h2>
        </div>
        <div class="col text-right my-auto">
          <div class="row">
            <div class="col text-right  my-auto">
              <button class="btn btn-outline-primary rounded-pill px-4" (click)="onOpenNewArtifact($event)"
                *ngIf="(groupedProjectArtifacts |  keyvalue)?.length !== 0">
                + Create artifact
              </button>
              <button class="btn btn-primary rounded-pill px-4 border-info" (click)="onOpenNewArtifact($event)"
                *ngIf="(groupedProjectArtifacts |  keyvalue)?.length == 0">
                + Create artifact
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dp-content-area">
      <div class="row">
        <div class="col">

          <!-- TODO: Implement a filter for projects based on integrations -->
          <div class="row mt-4 mb-2">
            <!-- <div class="col-auto ri-icon-col my-auto">
                        <i class="dp-integrated-app-icon">
                          <img
                            src="assets/integration-icons/mural.svg"
                            alt=""
                            class="dp-integration-img"
                          />
                        </i>
                      </div>
                      <div class="col-auto ri-icon-col my-auto">
                        <i class="dp-integrated-app-icon">
                          <img
                            src="assets/integration-icons/figma.svg"
                            alt=""
                            class="dp-integration-img"
                          />
                        </i>
                      </div>
                      <div class="col-auto ri-icon-col my-auto">
                        <i class="dp-integrated-app-icon">
                          <img
                            src="assets/integration-icons/google.svg"
                            alt=""
                            class="dp-integration-img"
                          />
                        </i>
                      </div> -->

            <div class="col text-right pr-0">
              <button class="btn btn-clear glow" (click)="onRefresh()">
                <img src="assets/remix-icons/refresh-line.svg" alt="" width="18px" style="margin-bottom: 3px" />
              </button>
            </div>
            <div class="col-auto text-right dash-step2">
              <div class="d-flex dp-work-view-container">
                <button [ngClass]="{
                              'btn btn-clear dp-work-view-left ': true,
                              'dp-work-view-active': active_view == viewOptions.LIST_VIEW
                            }" (click)="setView(viewOptions.LIST_VIEW)">
                  <img src="assets/create-modal-icons/menu.svg" alt="" width="18px" style="margin-bottom: 3px" />
                  List view
                </button>
                <button [ngClass]="{
                              'btn btn-clear  ': true,
                              'dp-bento-view-active': active_view == viewOptions.BENTO_VIEW
                            }" (click)="setView(viewOptions.BENTO_VIEW)">
                  <img src="assets/create-modal-icons/grid-2.svg" alt="" width="18px" style="margin-bottom: 3px" />
                  Bento view
                </button>
                <button [ngClass]="{
                              'btn btn-clear dp-work-view-right': true,
                              'dp-work-view-active': active_view == viewOptions.BOARD_VIEW
                            }" (click)="setView(viewOptions.BOARD_VIEW)">
                  <img src="assets/create-modal-icons/grid-3.svg" alt="" width="18px" style="margin-bottom: 3px" />
                  Board View
                </button>
              </div>
            </div>
            <div class="col-auto text-right px-0">
              <button class="btn btn-clear glow" [nbPopover]="projectOptsTemplateRef"
                [nbPopoverPlacement]="nbPosition.BOTTOM">
                <img src="assets/remix-icons/more-2-fill.svg" alt="" width="18px" style="margin-bottom: 3px" />
              </button>
            </div>
          </div>


          <div class="row my-1">
            <div class="col">
              <hr class="p-0" />
            </div>
          </div>

          <ng-container *ngIf="loadingProjects">
            <div class="row">
              <div class="col">
                <ngx-skeleton-loader count="6" appearance="line"></ngx-skeleton-loader>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="(projectArtifactsTicketsGroup | keyvalue)?.length > 0 && !loadingProjects">
            <!-- List view -->
            <ng-container *ngIf="active_view == viewOptions.LIST_VIEW">
              <ng-container *ngFor="
                let group of projectArtifactsTicketsGroup
                  | keyvalue : sortObjectByGroupKey;
                index as i
              ">
                <div class="row my-3">
                  <div class="col my-auto">
                    <h6 class="text-muted">{{ group?.key }}</h6>
                  </div>
                  <div class="col my-auto">
                    <h6 class="text-muted">Project</h6>
                  </div>
                  <div class="col-auto my-auto">
                    <h6 class="text-muted">Updated</h6>
                  </div>
                </div>
                <ng-container>
                  <div *ngFor="let todo of group.value; index as i" (click)="onClickArtifact($event, todo, i)"
                    [nbPopover]="templateRef" [nbPopoverPlacement]="nbPosition.START" [ngClass]="{
                    'row my-3 dp-dash-todo-item': true,
                    'dp-todo-active': todo.active
                  }">
                    <div class="col my-auto">
                      <div class="row">
                        <div class="col-auto my-auto">
                          <img src="assets/integration-icons/status-in-todo.svg" *ngIf="
                            todo?.status == statusEnum.NOT_STARTED ||
                            todo?.status == null
                          " alt="" />
                          <img src="assets/integration-icons/status-in-progress.svg"
                            *ngIf="todo?.status == statusEnum.IN_PROGRESS" alt="" />
                          <img src="assets/integration-icons/status-done.svg" *ngIf="todo?.status == statusEnum.DONE"
                            alt="" />
                        </div>
                        <div class="col my-auto pl-0">
                          <p class="m-0">
                            {{ todo.label }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col my-auto">
                      <h6 class="m-0">{{ todo.projectName }}</h6>
                    </div>
                    <div class="col-auto my-auto">
                      <p class="m-0">
                        <small>{{ todo.updatedAt | date }}</small>
                      </p>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
            <!-- Bento view with improved layout and responsiveness -->
            <ng-container *ngIf="active_view == viewOptions.BENTO_VIEW">
              <div class="bento-container">
                <div class="bento-item m-1 c-{{ bento_size_list[i].x }} r-{{
                  bento_size_list[i].y
                }}" *ngFor="let card of bento_list; index as i">
                  <nb-card>
                    <nb-card-header>
                      <div class="d-flex justify-content-between">
                        <nb-user size="small" [showName]="false" [picture]="
                          (card.owner | resolveUserId) !== undefined
                            ? (card.owner | resolveUserId).profilePictureUrl
                            : ''
                        ">
                        </nb-user>

                        <div class="d-flex">
                          <div class="pr-2 my-auto">
                            <img src="assets/integration-icons/status-in-todo.svg" *ngIf="
                              card?.status == statusEnum.NOT_STARTED ||
                              card?.status == null
                            " alt="" />
                            <img src="assets/integration-icons/status-in-progress.svg"
                              *ngIf="card?.status == statusEnum.IN_PROGRESS" alt="" />
                            <img src="assets/integration-icons/status-done.svg" *ngIf="card?.status == statusEnum.DONE"
                              alt="" />
                          </div>
                          <button class="btn btn-clear border-0" [nbPopover]="templateRef"
                            [nbPopoverPlacement]="nbPosition.START" (click)="onClickArtifact($event, card, i)">
                            <i class="bi bi-three-dots"></i>
                          </button>
                        </div>
                      </div>
                    </nb-card-header>
                    <nb-card-body class="mb-0 d-flex flex-wrap" (click)="onOpenArtifact(card, i)">
                      <div>
                        <div class="d-flex">
                          <h6 class="my-auto">{{ card?.label }}</h6>
                        </div>
                        <p class="mb-3">
                          <small>{{ card?.projectName }}</small>
                        </p>
                      </div>
                      <div *ngIf="
                        bento_size_list[i].x > 1 || bento_size_list[i].y > 1
                      ">
                        <!-- <img class="fit-img"
                        src="../../../../../assets/bento-images/{{bento_size_list[i].x}}{{bento_size_list[i].y}}.jpg"
                        alt="bento img"> -->
                        <!-- Update this line to use card.image -->
                        <img class="fit-img card-img" [src]="card.image" alt="bento img" />
                      </div>
                    </nb-card-body>
                    <nb-card-footer class="pt-0">
                      <div class="d-flex mb-2">
                        <p class="m-0 dp-tag-completed mr-2" *ngFor="let tag of card?.tags">
                          {{ tag }}
                        </p>
                      </div>
                      <p>
                        <small class="text-muted">Updated: {{ card?.updatedAt | date }}</small>
                      </p>
                    </nb-card-footer>
                  </nb-card>
                </div>
              </div>
            </ng-container>
            <!-- Board view -->
            <ng-container *ngIf="active_view == viewOptions.BOARD_VIEW">
              <div class="row my-3" cdkDropListGroup>
                <!-- Todo -->
                <div class="col-4" cdkDropList id="todo" [cdkDropListData]="todo" (cdkDropListDropped)="drop($event)">
                  <!-- Header -->
                  <div class="row">
                    <div class="col-auto my-auto pr-0">
                      <p class="m-0 dp-todo-board-header">To do</p>
                    </div>
                    <div class="col my-auto">
                      <img src="assets/integration-icons/status-in-todo.svg" alt="" />
                    </div>
                  </div>
                  <!-- Divider line -->
                  <div class="row">
                    <div class="col">
                      <hr />
                    </div>
                  </div>

                  <!-- Body of tickets -->
                  <div class="row my-3" *ngFor="let item of todo; index as i" cdkDrag>
                    <div class="col">
                      <nb-card (click)="onOpenArtifact(item, i)">
                        <nb-card-header>
                          <div class="row">
                            <div class="col-auto my-auto">
                              <div class="d-flex">
                                <nb-user size="small" [showName]="false" [picture]="
                                  (item.owner | resolveUserId) !== undefined
                                    ? (item.owner | resolveUserId)
                                        .profilePictureUrl
                                    : ''
                                ">
                                </nb-user>
                              </div>
                            </div>
                            <div class="col text-right my-auto"></div>
                          </div>
                        </nb-card-header>
                        <nb-card-body class="pb-0 d-flex flex-column justify-content-between">
                          <h6>{{ item?.label }}</h6>
                          <p>
                            <small>{{ item?.projectName }}</small>
                          </p>
                        </nb-card-body>
                        <nb-card-footer class="pt-0">
                          <div class="d-flex mb-2">
                            <p class="m-0 dp-tag-todo mr-2" *ngFor="let tag of item?.tags">
                              {{ tag }}
                            </p>
                          </div>
                          <p>
                            <small class="text-muted">Updated: {{ item?.updatedAt | date }}</small>
                          </p>
                        </nb-card-footer>
                      </nb-card>
                    </div>
                  </div>
                </div>

                <!-- In-Progress -->
                <div class="col-4" cdkDropList id="inProgress" [cdkDropListData]="inProgress"
                  (cdkDropListDropped)="drop($event)">
                  <!-- Header -->
                  <div class="row">
                    <div class="col-auto my-auto pr-0">
                      <p class="m-0 dp-in-progress-board-header">In Progress</p>
                    </div>
                    <div class="col my-auto">
                      <img src="assets/integration-icons/status-in-progress.svg" alt="" />
                    </div>
                  </div>
                  <!-- Divider line -->
                  <div class="row">
                    <div class="col">
                      <hr />
                    </div>
                  </div>

                  <!-- Body of tickets -->
                  <div class="row my-3" *ngFor="let item of inProgress; index as i" cdkDrag>
                    <div class="col">
                      <nb-card (click)="onOpenArtifact(item, i)">
                        <nb-card-header>
                          <div class="d-flex">
                            <nb-user size="small" [showName]="false" [picture]="
                              (item.owner | resolveUserId) !== undefined
                                ? (item.owner | resolveUserId).profilePictureUrl
                                : ''
                            ">
                            </nb-user>
                          </div>
                        </nb-card-header>
                        <nb-card-body class="pb-0 d-flex flex-column justify-content-between">
                          <h6>{{ item?.label }}</h6>
                          <p>
                            <small>{{ item?.projectName }}</small>
                          </p>
                        </nb-card-body>
                        <nb-card-footer class="pt-0">
                          <div class="d-flex mb-2">
                            <p class="m-0 dp-tag-in-progress mr-2" *ngFor="let tag of item?.tags">
                              {{ tag }}
                            </p>
                          </div>
                          <p>
                            <small class="text-muted">Updated: {{ item?.updatedAt | date }}</small>
                          </p>
                        </nb-card-footer>
                      </nb-card>
                    </div>
                  </div>
                </div>

                <!-- Done -->
                <div class="col-4" cdkDropList id="completed" [cdkDropListData]="completed"
                  (cdkDropListDropped)="drop($event)">
                  <!-- Header -->
                  <div class="row">
                    <div class="col-auto my-auto pr-0">
                      <p class="m-0 dp-completed-board-header">Completed</p>
                    </div>
                    <div class="col my-auto">
                      <img src="assets/integration-icons/status-done.svg" alt="" />
                    </div>
                  </div>
                  <!-- Divider line -->
                  <div class="row">
                    <div class="col">
                      <hr />
                    </div>
                  </div>

                  <!-- Body of tickets -->
                  <div class="row my-3" *ngFor="let item of completed; index as i" cdkDrag>
                    <div class="col">
                      <nb-card (click)="onOpenArtifact(item, i)">
                        <nb-card-header>
                          <div class="d-flex">
                            <nb-user size="small" [showName]="false" [picture]="
                              (item.owner | resolveUserId) !== undefined
                                ? (item.owner | resolveUserId).profilePictureUrl
                                : ''
                            ">
                            </nb-user>
                          </div>
                        </nb-card-header>
                        <nb-card-body class="pb-0 d-flex flex-column justify-content-between">
                          <h6>{{ item?.label }}</h6>
                          <p>
                            <small>{{ item?.projectName }}</small>
                          </p>
                        </nb-card-body>
                        <nb-card-footer class="pt-0">
                          <div class="d-flex mb-2">
                            <p class="m-0 dp-tag-completed mr-2" *ngFor="let tag of item?.tags">
                              {{ tag }}
                            </p>
                          </div>
                          <p>
                            <small class="text-muted">Updated: {{ item?.updatedAt | date }}</small>
                          </p>
                        </nb-card-footer>
                      </nb-card>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- Show when no artifacts -->
<ng-container *ngIf="(groupedProjectArtifacts | keyvalue)?.length == 0">
  <div class="d-flex flex-column align-items-center">
    <div class="dp-welcome-text">
    </div>
    <img src="assets/Project_landing.svg" />
  </div>
</ng-container>

<!-- Popover component -->
<ng-template #templateRef let-data="data">
  <div class="dp-custom-context-menu-container">
    <div class="dp-c-context-menu-option" (click)="onOpenArtifact()" *ngIf="!isArtifactWindow">
      <div class="row">
        <div class="col my-auto">View Artifact</div>
        <div class="col-auto my-auto">
          <i class="ri-external-link-line ml-2"></i>
        </div>
      </div>
    </div>
    <div class="dp-c-context-menu-option" [nbPopover]="templateRefStatusOpts" [nbPopoverPlacement]="nbPosition.END">
      <div class="row">
        <div class="col my-auto">Update status</div>
        <div class="col-auto my-auto">
          <i class="ri-arrow-right-s-line"></i>
        </div>
      </div>
    </div>
    <div class="dp-c-context-menu-option dp-role-delete" (click)="onDeleteArtifact(currentArtifact)">
      <div class="row">
        <div class="col my-auto">Delete artifact</div>
        <div class="col-auto my-auto">
          <i class="ri-delete-bin-7-fill"></i>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Status popover:submenu -->
<ng-template #templateRefStatusOpts let-data="data">
  <div class="dp-custom-context-menu-container">
    <ng-container *ngFor="let status of items; index as i">
      <div class="dp-c-context-menu-option" (click)="
          updateArtefact(currentArtifact, currentTodo?.projectId, status.code)
        ">
        <div class="row">
          <div class="col my-auto">
            {{ status?.title }}
          </div>
          <div class="col-auto my-auto pr-0">
            <img src="assets/integration-icons/status-in-todo.svg" class="dp-status-icon"
              *ngIf="status?.code == statusEnum.NOT_STARTED" alt="" />
            <img src="assets/integration-icons/status-in-progress.svg" class="dp-status-icon"
              *ngIf="status?.code == statusEnum.IN_PROGRESS" alt="" />
            <img src="assets/integration-icons/status-done.svg" class="dp-status-icon"
              *ngIf="status?.code == statusEnum.DONE" alt="" />
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<!-- Artifact Window -->
<app-artifact-window #dialogWindow [showLabel]="false" [showOptsPopOver]="true" [showSidePane]="true"
  [saveBtnText]="saveBtnText" (onClickSaveBtn)="onSaveArtifact()"
  (onClickDeleteBtn)="onDeleteArtifact(currentArtifact)" (onCloseBtn)="isArtifactWindow = false">
  <div dialog-side-panel>
    <app-side-panel [user]="dataTransferService.$user.value" [comments]="dataTransferService.$comments.value"
      [project]="currentTodo | parseArtifactForProject" [artifact]="currentArtifact"></app-side-panel>
  </div>
  <p dialog-date class="m-0">
    Date created on {{ currentArtifact?.createdAt | date }}
  </p>
  <button dialog-pop-over class="btn btn-clear glow-inline  border-0" [nbPopover]="templateRef"
    [nbPopoverPlacement]="nbPosition.BOTTOM" (click)="isArtifactWindow = true" *ngIf="(currentArtifact && currentArtifact.project)">
    <i class="bi bi-three-dots"></i>
  </button>
  <div class="my-3" dialog-main-content-area>
    <div class="row">
      <div class="col">
        <form [formGroup]="artifactForm">
          <div class="form-group mb-3">
            <div class="row">
              <div class="col my-auto">
                <input [ngClass]="{
                    'dp-text-input dp-project-name-inline-input-modal': true,
                    'input-sm': true,
                    'form-control': true,
                    'is-invalid dp-custom-input-error ':
                      artifactForm.get('artifactName')?.errors &&
                      artifactForm.get('artifactName')?.touched
                  }" formControlName="artifactName" type="text" fullWidth fieldSize="giant"
                  placeholder="Artifact name" />

                <ng-container *ngFor="let validation of validationMessages.artifactName">
                  <div class="invalid-feedback" *ngIf="
                      artifactForm
                        .get('artifactName')
                        ?.hasError(validation.type) &&
                      (artifactForm.get('artifactName')?.dirty ||
                        artifactForm.get('artifactName')?.touched)
                    ">
                    {{ validation.message }}
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col my-auto">
                    <textarea [ngClass]="{
                        'dp-text-input dp-project-goal-inline-input-modal': true,
                        'input-sm': true,
                        'form-control': true,
                        'is-invalid dp-custom-input-error ':
                          artifactForm.get('artifactDescription')?.errors &&
                          artifactForm.get('artifactDescription')?.touched
                      }" formControlName="artifactDescription" type="text" fullWidth fieldSize="giant"
                      placeholder="Describe this artifact"></textarea>
                    <ng-container *ngFor="
                        let validation of validationMessages.artifactDescription
                      ">
                      <div class="invalid-feedback" *ngIf="
                          artifactForm
                            .get('artifactDescription')
                            ?.hasError(validation.type) &&
                          (artifactForm.get('artifactDescription')?.dirty ||
                            artifactForm.get('artifactDescription')?.touched)
                        ">
                        {{ validation.message }}
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <app-editor [jsonData]="template?.artifactPropTree" (ckEditorData)="watchEditorDataStream($event)">
        </app-editor>
      </div>
    </div>
  </div>
</app-artifact-window>

<!-- project opts popover -->
<ng-template #projectOptsTemplateRef let-data="data">
  <div class="dp-custom-context-menu-container">
    <!-- <div
      class="dp-c-context-menu-option"
      [routerLink]="['/portal/project-settings']"
      [queryParams]="{ id: project?.id }"
    >
      <div class="row">
        <div class="col my-auto">Project Settings</div>
        <div class="col-auto my-auto">
          <i class="ri-settings-5-line"></i>
        </div>
      </div>
    </div> -->
    <div class="dp-c-context-menu-option dp-role-delete" (click)="deleteProject()">
      <div class="row">
        <div class="col my-auto">Delete project</div>
        <div class="col-auto my-auto">
          <i class="ri-delete-bin-7-fill"></i>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Delete alert template -->
<ng-template #deleteAlertTemplate let-data let-ref="dialogRef">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <nb-card [size]="'small'" class="dp-delete-alert-artifact-window-card m-0">
          <nb-card-body>
            <div class="row">
              <div class="col">
                <p class="mb-2">Are you sure you want to delete this project?</p>
              </div>
            </div>
            <div class="row">
              <div class="col my-auto">
                <div class="d-flex" style="justify-content: center !important">
                  <button class="btn btn-danger mr-1" (click)="onDeleteProject(ref)">
                    Delete project
                  </button>
                  <button class="btn btn-outline-light ml-1" (click)="ref.close()">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </nb-card-body>
        </nb-card>
      </div>
    </div>
  </div>
</ng-template>