import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  window.location.href = 'https://9fqbvamgq9v.typeform.com/to/OaYZ57l9'
  if (window) {
    window.console.log = () => {};
    window.console.warn = () => {};
    window.console.error = () => {};
  }
}


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
