import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ComponentModule } from 'src/app/@theme/components/component.module';
import { GoToDesktopComponent } from './go-to-desktop/go-to-desktop.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { StartComponent } from './start/start.component';
import { InviteComponent } from './invite/invite.component';
import { TruncateModule } from 'ng2-truncate';
  
export const publicRoutingComponents = [
  StartComponent,
  GoToDesktopComponent,
  PageNotFoundComponent,
  InviteComponent
]

@NgModule({
  declarations: publicRoutingComponents,
  imports: [
    ComponentModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TruncateModule
  ],
  exports: publicRoutingComponents
})
export class publicPagesModule { }
