import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { User } from '../../../models/@fima/user.model';
import { AfsService } from '../afs/afs.service';
import { CacheService } from '../cache/cache.service';

const USERS_COLLECTION_NAME = 'Users';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private afsService: AfsService, private store: CacheService) { }

  /**
   * Creates a user in the Users collection in fireStore
   *
   * @param {firebase.User} user
   * @param {CreateUser} createUser
   * @return {*}
   * @memberof ProfileService
   */
  createUserProfile(createUser: User) {
    return new Promise<any>(async (resolve, reject) => {
      try {
        // save logged in user in memory
        const createdUser = await this.afsService.addDocument<User>(
          createUser,
          USERS_COLLECTION_NAME
        );
        return resolve(createdUser);
      } catch (err) {
        console.log('ProfileService >> createUserProfile >> error >> ', err);
        return reject(err);
      }
    });
  }

  /**
   * Returns the user profile
   * First attempts to fetch user profile from cache, else fetch from fireStore;
   *
   * @return {*}
   * @memberof ProfileService
   */
  getUserProfile(userUUid: string, forceServerFetch: boolean = false) {
    return new Promise<User>(async (resolve, reject) => {

      if (!forceServerFetch) {

        let userProfile: User | undefined;
        // limit reads to firebase by fetching from cache
        try {
          userProfile = JSON.parse(
            this.store.retrieveItem(environment.cacheKeys.userProfile)
          );
  
          // Check if user contains taiga token prop 
          // If not force fresh 
  
          if (userProfile && !userProfile.taigaAuthToken) {
            userProfile = undefined
          }
  
          if (userProfile) {
            console.log('FETCH_CACHED_USER_PROFILE', userProfile);
            return resolve(userProfile);
          }
        } catch (err) {
          console.log('FETCH_CACHED_USER_PROFILE_ERR', err);
          userProfile = undefined;
        }
      }

      try {
        console.log(
          'ATTEMPTING_FRESH_FETCH:',
          true,
          'CACHED_UUID:',
          userUUid
        );

        if (!userUUid || userUUid == '') {
          return reject({
            code: 'logged-in-user:no-exists',
            message: 'No user logged in',
          });
        }

        const fireStoreResult = await this.afsService.findDocuments<User>(
          'uid',
          '==',
          userUUid,
          USERS_COLLECTION_NAME,
          1
        );

        const user = fireStoreResult.shift();

        if (!user) {
          return reject({
            code: 'logged-in-user:no-exists',
            message: 'No user logged in | user does not exist',
          });
        }
        this.store.storeItem(
          environment.cacheKeys.userProfile,
          JSON.stringify(user)
        );
        console.log('FETCHED_FRESH_USER', user);
        return resolve(user);
      } catch (err) {
        console.log('ProfileService >> getUserProfile >> error >> ', err);
        return reject(err);
      }
    });
  }

  /**
   *
   *
   * @param {User} userProfile
   * @memberof ProfileService
   */
  updateUserProfile(userProfile: User) {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const updatedUser = await this.afsService.updateDocument<User>(
          userProfile.id as string,
          USERS_COLLECTION_NAME,
          userProfile
        );
        this.store.storeItem(
          environment.cacheKeys.userProfile,
          JSON.stringify(updatedUser)
        );
        return resolve(updatedUser);
      } catch (err) {
        console.log('ProfileService >> updateUserProfile >> error >> ', err);
        return reject(err);
      }
    });
  }

  /**
   *
   *
   * @memberof ProfileService
   */
  uploadProfilePhoto() { }
}
