<div class="note" cdkDrag>
  <header>
    <input type="text" class="dp-note-title-input" [value]="note.title" />
    <button (click)="deleteNote()">
      <i class="ri-close-line"></i>
    </button>
  </header>
  <div class="note-content">
    <textarea
    name=""
    id=""
    rows="10"
    class="dp-note-content-input"
    [value]="note.content"
  >
  </textarea>
  </div>
</div>
