import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import mixpanel from 'mixpanel-browser';
import { Subscription } from 'rxjs';
import { Company } from 'src/app/@core/models/@fima/company.model';
import { FirebaseAuthService } from 'src/app/@core/services/@fima/auth/auth.service';
import { CacheService } from 'src/app/@core/services/@fima/cache/cache.service';
import { CommentService } from 'src/app/@core/services/@fima/comment/comment.service';
import { CompanyService } from 'src/app/@core/services/@fima/company/company.service';
import { DataTransferService } from 'src/app/@core/services/@fima/data-transfer/data-transfer.service';
import { ProfileService } from 'src/app/@core/services/@fima/profile/profile.service';
import { ProjectService } from 'src/app/@core/services/@fima/project/project.service';
import { AlgoliaService } from 'src/app/@core/services/@fima/search/algolia.service';
import { TaigaMembershipService } from 'src/app/@core/services/@taiga/membership/membership.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss']
})
export class PortalComponent {
  /**
   *
   *
   * @type {Subscription}
   * @memberof PortalComponent
   */
  routingEventsSubscription: Subscription;

  /**
   *
   *
   * @memberof PortalComponent
   */
  canShowMenuToggle = false;

  /**
   *
   *
   * @memberof PortalComponent
   */
  canShowSearchBar = false;

  /**
   *
   *
   * @memberof PortalComponent
   */
  canShowSideMenu = false;

  canShowRightSidePanel = false

  canShowLogo = false

  renderSearchResults = false;

  data: any[] = [];

  pageSize = 6;
  currentPage = 1;

  constructor(
    private profileService: ProfileService,
    private authService: FirebaseAuthService,
    private router: Router,
    private companyService: CompanyService,
    private dataTransferService: DataTransferService,
    private cacheService: CacheService,
    private projectService: ProjectService,
    private algoliaService: AlgoliaService,
    private commentService: CommentService,
    private membershipService: TaigaMembershipService

  ) {
    this.handleRoutingEvents();

    this.initMixpanel()


  }

  private async initMixpanel() {
    const user = await this.fetchLoggedInUser();
    // Set this to a unique identifier for the user performing the event.
    mixpanel.identify(user.uid)
  }




  /**
   * This should be the central point for fetching main data entities at boot time
   *
   * @private
   * @memberof PortalComponent
   */
  private async initAppData() {
    try {
      // TODO: After initial  app load , the app should periodically check for most in use company data and fetch it  

      const MAX_RECORDS = 100;

      const user = await this.fetchLoggedInUser();

      const [company, projects, comments] = await Promise.all([

        this.companyService.getCompany(
          user.companyId
        ),
        this.projectService.getProjects(
          user.companyId,
          MAX_RECORDS
        ),
        this.commentService.getCommentsByCompanyId(
          user.companyId,
          MAX_RECORDS
        )
      ]);

      this.dataTransferService.$company.next(company);
      this.dataTransferService.$projects.next(projects);
      this.dataTransferService.$comments.next(comments);

      this.cacheService.storeItem('current:company', company);
      this.cacheService.storeItem('company:comments', comments);
      this.cacheService.storeItem('all:projects', projects);

    } catch (error) {
      console.log('PortalComponent >> initAppData >> error >>  ', error);
    }
  }

  /**
   * @private
   * @memberof PortalComponent
   */
  private handleRoutingEvents() {
    this.routingEventsSubscription = this.router.events.subscribe(
      async (routingEvent) => {
        if (routingEvent instanceof NavigationEnd) {
          const currentRoute = routingEvent.urlAfterRedirects;

          if (currentRoute == '/portal/onboarding') {
            this.hideAllControls();
          } else {
            this.canShowAllControls();

            this.dataTransferService.$onfetchAppData.subscribe((onFetchTrigger) => {
              if (onFetchTrigger) {
                this.initAppData()
              }
            })
          }

          if (currentRoute == '/portal/invite') {
            this.hideAllControls();
            this.canShowLogo = true;
          }

          if (currentRoute == '/portal') {
            await this.onAppStart();
          }

          if (currentRoute == '/portal/dashboard') {
            this.canShowRightSidePanel = true
          }
        }
      }
    );
  }

  public async handleSearchQuery(event: any) {
    this.renderSearchResults = true;
    const result = await this.algoliaService.search(event.term);
    this.data = result.hits;
  }

  public async clearActionClicked() {
    this.renderSearchResults = false;
    this.data = [];
  }

  /**
   *
   *
   * @private
   * @memberof PortalComponent
   */
  private async onAppStart() {
    try {
      const user = await this.fetchLoggedInUser();

      let company = await this.companyService.getCompany(user.companyId);

      if (user && !user.hasSeenOnboarding) {
        let inviteCompanyId;
        try {
          inviteCompanyId = await this.cacheService.retrieveItem(
            environment.cacheKeys.inviteCompanyId
          );
        } catch (error) {
          console.error('Error retrieving inviteCompanyId: ', error);
        }

        if (inviteCompanyId) {
          company = await this.handleInvitation(user, inviteCompanyId, company);
        } else {
          this.router.navigate(['/portal/onboarding'], { replaceUrl: true });
        }
      } else if (
        (user && !user.hasCreatedProject) ||
        (company && company.projectsCount == 0)
      ) {
        this.router.navigate(['/portal/dashboard'], { replaceUrl: true });
      } else {
        this.router.navigate(['/portal/dashboard'], { replaceUrl: true });
      }
    } catch (error) {
      console.error('Error in onAppStart: ', error);
      if (error && error.code === 'logged-in-user:no-exists') {
        await this.authService.signOut();
        console.log('routing back to start');
        this.router.navigate(['/start'], { replaceUrl: true });
      }
    }
  }

  private async fetchLoggedInUser() {
    const firebaseUser = await this.authService.auth.currentUser;
    if (!firebaseUser || !firebaseUser.uid) {
      throw new Error('No Firebase user found');
    }

    this.dataTransferService.$firebaseUser.next(firebaseUser);

    const user = await this.profileService.getUserProfile(firebaseUser.uid);
    if (!user) {
      throw new Error('No user profile found');
    }

    this.dataTransferService.$user.next(user);

    return user;
  }

  /**
   * @private
   * @param {*} user
   * @param {string} inviteCompanyId
   * @param {(Company | null)} [currentCompany]
   * @return {*}  {(Promise<Company | null>)}
   * @memberof PortalComponent
   */
  private async handleInvitation(
    user: any,
    inviteCompanyId: string,
    currentCompany?: Company | null
  ): Promise<Company | null> {
    try {
      // Fetch the company that invited this user
      const invitingCompany = await this.companyService.getCompany(inviteCompanyId);

      // If the company information couldn't be fetched, throw an error
      if (!invitingCompany) {
        throw new Error('Invited company information could not be fetched.');
      }

      // Check if the account the invited user used to login matches the user from the invite
      const isCorrectInviteEmail = invitingCompany.invitedUsers.some(
        (invitedUser) => invitedUser.email === user.email
      );

      if (!isCorrectInviteEmail) {
        // Logout user and navigate back to the start page, display user mismatch error
        await this.authService.signOut();
        this.router.navigate(['/start'], { replaceUrl: true });
        return null; // Return null as the operation wasn't successful
      }

      // Update user with company information [companyId]
      user.companyId = inviteCompanyId;
      user.hasSeenOnboarding = true;
      user.hasAcceptedInvitation = true;


      if (invitingCompany.projectsCount > 0) {
        user.hasCreatedProject = true;
      }

      const updatedUser = await this.profileService.updateUserProfile(user);

      // Update the data transfer service with the updated user information
      this.dataTransferService.$user.next(updatedUser);

      // here we want to invite the user to a taiga project 

      const invitedUser = invitingCompany.invitedUsers.filter(
        (u) => u.email === user.email
      ).shift() as any;

      // here we want to invite the user to a taiga project 

      // This is will be done on the backend

      if (!(invitingCompany as any).pendingUnfulfilledMemberships || (invitingCompany as any).pendingUnfulfilledMemberships.length == 0) {
        invitingCompany['pendingUnfulfilledMemberships'] = [
          invitedUser
        ]
      } else {
        invitingCompany['pendingUnfulfilledMemberships'].push(invitedUser)
      }

      // const companyOwnerUser = invitingCompany.users.filter(
      //   (u) => u.companyId == null
      // ).shift() as any;

      // const membership = await this.membershipService.createMembership(invitedUser.invitationProjectId, invitedUser.role.id, user)

      // trigger fetch projects
      this.dataTransferService.$onfetchAppData.next(true);

      // Remove user from invited user object
      invitingCompany.invitedUsers = invitingCompany.invitedUsers.filter(
        (invitedUser) => invitedUser.email !== user.email
      );

      // Update current company with invited userID
      invitingCompany.users = invitingCompany.users
        ? [...invitingCompany.users, updatedUser]
        : [updatedUser];

      invitingCompany.usersCount = invitingCompany.users.length; // Update company user count

      // Update the company information
      const updatedCompany = (await this.companyService.updateCompany(
        invitingCompany
      )) as Company;

      // Navigate to the dashboard
      this.router.navigate(['/portal/dashboard'], { replaceUrl: true });

      return updatedCompany; // Return the updated company information
    } catch (error) {
      console.error('Error in handleInvitation: ', error);
      return null; // Return null in case of an error
    }
  }

  /**
   * @private
   * @memberof PortalComponent
   */
  private canShowAllControls() {
    this.canShowMenuToggle = true;
    this.canShowSearchBar = true;
    this.canShowSideMenu = true;
    this.canShowRightSidePanel = true;
    this.canShowLogo = true

  }

  /**
   * @private
   * @memberof PortalComponent
   */
  private hideAllControls() {
    this.canShowMenuToggle = false;
    this.canShowSearchBar = false;
    this.canShowSideMenu = false;
    this.canShowRightSidePanel = false;
    this.canShowLogo = false
  }
}
