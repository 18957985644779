import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-actions-overlay',
  templateUrl: './actions-overlay.component.html',
  styleUrls: ['./actions-overlay.component.scss']
})
export class ActionsOverlayComponent {

  @Output() onCloseBtnClick: EventEmitter<any> = new EventEmitter()


  onCloseActionsOverLay() {
    this.onCloseBtnClick.emit({});
  }

}
